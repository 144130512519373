import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react'
import {Auth, Storage } from 'aws-amplify';
import { Grid } from 'semantic-ui-react'
import ImageCard from './ImageCard';
import Swal from 'sweetalert2';
const bucket = "quizlet-app";

// TODO: create SORT and FILTER functionality
const ImageGallery = ({ testId }) => {

    const [images, setImages] = useState([]);

    const PAGE_SIZE = 100;
    let nextToken = undefined;
    let hasNextPage = true;

    useEffect(() => {
        const fetchData = async () => {

            if (hasNextPage) {
                let response = await Storage.list(`${testId}/draft/`, {
                    level: 'private',
                    pageSize: PAGE_SIZE,
                    nextToken: nextToken,
                });
                if (response.hasNextToken) {
                    nextToken = response.nextToken;
                } else {
                    nextToken = undefined;
                    hasNextPage = false;
                }
                // render list items from response.results and sort by lastModified date
                const resultsSorted = response.results.sort((a, b) => (a.lastModified - b.lastModified) * (-1));


                setImages(resultsSorted);

            }

        };

        fetchData();
    }, []);

    const handleOnImageDelete = async (image) => {
        const result = await Storage.remove(image.key, { level: 'private' });
        if (result) {
            Swal.fire({
                position: 'bottom',
                toast: true,
                icon: 'success',
                title: `Image Deleted!`,
                showConfirmButton: false,
                timer: 2000
            })

            setImages((prev) => {
                return prev.filter((img) => img.key !== image.key);
            })


        } else {
            Swal.fire({
                position: 'bottom',
                toast: true,
                icon: 'error',
                title: `Unable to Delete!`,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const copyUrl = async (image) => {
        const { identityId } = await Auth.currentUserCredentials();
        const url = `https://${bucket}.s3.amazonaws.com/private/${identityId}/${image.key}`;

        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Url Copied to Clipboard!`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
    }

    const content = images.map((image) => {
        // console.log(image.key)
        return (
            <Grid.Column key={image.key} width={5} >
                <ImageCard
                    image={image}
                    deleteImage={handleOnImageDelete}
                    copyUrl={copyUrl}
                />
            </Grid.Column>
        );
    })

    return (
        <Container fluid>
            <Grid columns={3}>
                {content}
            </Grid>
        </Container>
    )
}

export default ImageGallery
