import React from 'react';
import { Container, Divider, Grid, Image, Header, Icon, GridColumn } from 'semantic-ui-react';
import bee from '../images/bee_cartoon_cute.jpg';
import QuizPracticeSimple from './QuizPracticeSimple';
import { jsQuizz } from '../constants/QuizPracticeSimpleData';
import Flashcard from './Flashcard';
import FlashcardDeck from './FlashcardDeck';
import { flashcardsDeckSampleData } from '../constants/FlashcardDeckSampledata';
import FlashcardEditor from './FlashcardEditor';
import HeroCallouts from './HeroCallouts';
import FlashcardDeckEditor from './FlashcardDeckEditor';
import FeatureSection from './FeatureSection';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import Footer from './Footer';
import TestCardV3 from './TestCardV3';
import { Link } from 'react-scroll';
import {Link as LinkReactRouter} from 'react-router-dom'
import FlashcardForm from './FlashcardForm';


const Hero = () => {
    return (
        <>
            <Container className='py-14'>

                <Grid columns={2} centered stackable>

                    <Grid.Row>

                        <Grid.Column verticalAlign={'top'} textAlign='left' width={8}>
                            {/* <p className="bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text">
                                QuizBee
                            </p>
                            <p className='text-[#20B486]  py-2 text-2xl font-medium'>QUIZBEE</p> */}
                            {/*<p className='md:leading-[72px] py-2 md:text-6xl text-5xl font-semibold'>QUIZBEE</p>*/}
                            <h1 className='md:leading-[72px] py-2 md:text-6xl text-5xl font-semibold'>

                                Turbocharge Your <span className='bg-gradient-to-r  from-[#6ee7b7] to-[#20B486]  text-transparent bg-clip-text'>Test Prep </span> Journey
                            </h1>
                            <p className='py-2 text-2xl font-medium'>
                                Welcome to our Test Prep marketplace, where <span className='text-[#20B486]'>time</span> is your ally! Whether you’re preparing for <span className='text-[#20B486]'> professional certifications, standardized tests, </span> or academic assessments , we’ve got you covered.
                            </p>

                            <div className="flex justify-start my-10">
                                <Link
                                    to="listingProductSection"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className="bg-gradient-to-r from-[#6ee7b7] to-[#20B486] py-3 px-4 mx-3 rounded-md text-[#ECFDF5] cursor-pointer"
                                >
                                    FREE Practice Tests
                                </Link>
                                <LinkReactRouter
                                    to="/edit"
                                    className="py-3 px-4 mx-3 rounded-md border"
                                >
                                    Create Test
                                </LinkReactRouter>
                            </div>

                        </Grid.Column>

                        <Grid.Column textAlign='center' width={8}>
                            <Image size={'medium'} src={bee} verticalAlign='middle' className='shrink' />
                        </Grid.Column>


                    </Grid.Row>
{/* 
                    <Grid.Row>
                        <FlashcardDeck flashcards={flashcardsDeckSampleData} autoPlay={false} />
                    </Grid.Row>

                    <Grid.Row>
                        <FlashcardForm/>
                    </Grid.Row> */}

                </Grid>

            </Container >
        </>

    )
}

export default Hero