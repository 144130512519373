import React, { useMemo } from 'react'
import { Container, Divider, Grid, GridColumn, Header } from 'semantic-ui-react'


const DigitBox = ({ digit, color }) => {
    return (
        <div
            className={`w-8 h-8 flex items-center justify-center rounded-lg text-base ${color}`}
        >
            {digit}
        </div>
    );
};

function compareArraysOfObjects(arr1, arr2) {

    // check if arrays
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
    }

    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // sort on Value property
    const sortedArray1 = [...arr1].sort((a, b) => a.value.localeCompare(b.value));
    const sortedArray2 = [...arr2].sort((a, b) => a.value.localeCompare(b.value));



    // console.log('arr1', sortedArray1)
    // console.log('arr2', sortedArray2)


    // Compare each object in the arrays
    for (let i = 0; i < sortedArray1.length; i++) {
        const obj1 = sortedArray1[i];
        const obj2 = sortedArray2[i];

        // check value property of each object
        // console.log('obj1', obj1.value)
        // console.log('obj2', obj2.value)

        if (obj1.value !== obj2.value) {
            return false;
        }
    }

    return true;
}





const SessionProgressTracker = ({ session: { questions, answers }, questionIndex }) => {

    // count number of correct and incorrect questions
    const count = questions.reduce((accum, question) => {

        if (answers?.[question?.question_id].length === 0) return accum;

        if (compareArraysOfObjects(question?.answer?.values, answers?.[question?.question_id])) {
            return {
                ...accum,
                correct: accum.correct + 1,
            }
        } else {
            return {
                ...accum,
                incorrect: accum.incorrect + 1,
            }
        }
    }, { correct: 0, incorrect: 0 })


    return (
        <Container className={'border border-grey-400 rounded-2xl py-3 px-6'}>
            <Header as='h4'>Your Progress</Header>

            <Grid stackable stretched>
                <GridColumn width={13}>
                    <div className='flex h-auto flex-wrap gap-1 items-start'>
                        {questions.map((question, index) => {

                            let color = '';
                            if (answers?.[question?.question_id]?.length === 0) {
                                color = color.concat('bg-gray-100 text-gray-500 ')
                            } else {
                                color = color.concat(compareArraysOfObjects(question?.answer?.values, answers?.[question?.question_id]) ? ' bg-green-500 text-white ' : 'bg-red-400 text-white ')
                            }

                            if (index === questionIndex) {
                                color = color.concat('border border-slate-600')
                            }

                            return (
                                <DigitBox
                                    key={index}
                                    digit={index + 1}
                                    color={color}
                                />
                            )
                        })}
                    </div>
                </GridColumn>
                <GridColumn width={3}>
                    <div class="p-0 items-start">
                        <p><span className={'text-green-400 text-sm'}>●</span> {count?.correct} Correct</p>
                        <p><span className={'text-red-400 text-sm'}>●</span> {count?.incorrect} Incorrect</p>
                    </div>
                </GridColumn>
            </Grid>


        </Container>
    )
}

export default SessionProgressTracker