import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Container, Menu, Modal, Card, Button, Icon, Segment, Checkbox } from 'semantic-ui-react'
import { Amplify, Auth, Storage } from 'aws-amplify';
import { Grid, Image } from 'semantic-ui-react'
import PlaceholderComponent from './PlaceholderComponent'
import ImageCardSimple from './ImageCardSimple';
import { isFulfilled } from '@reduxjs/toolkit';
import ImageUploader from './ImageUploader';

const bucket = "quizlet-app";


// TODO: create SORT and FILTER functionality
// TODO: Pagination
const ImageGalleryModal = ({
    testId = '',
    dispatch = null,
    modalState,
    setModalState,
    setValue = null
}) => {

    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState('')
    const [uploaderVisible, setUploaderVisible] = useState(false);

    const PAGE_SIZE = 100;
    let nextToken = undefined;
    let hasNextPage = true;

    useEffect(() => {
        const fetchData = async () => {

            if (hasNextPage) {
                let response = await Storage.list(`${testId}/draft`, {
                    level: 'private',
                    pageSize: PAGE_SIZE,
                    nextToken: nextToken,
                });
                if (response.hasNextToken) {
                    nextToken = response.nextToken;
                } else {
                    nextToken = undefined;
                    hasNextPage = false;
                }
                // render list items from response.results
                // console.log(response)

                const resultsSorted = response.results.sort((a, b) => (a.lastModified - b.lastModified) * (-1));
                setImages(resultsSorted);

            }

        };

        fetchData();
    }, [uploaderVisible]);


    const handleOnSubmit = async () => {
        const { identityId } = await Auth.currentUserCredentials();
        const url = `https://${bucket}.s3.amazonaws.com/private/${identityId}/${selectedImage}`;

         // using react hook form
        if (setValue) {
            setValue(modalState?.['field'], url);
            setModalState({
                isOpen: false,
                field: null,
            })
            return;
        }

        // NOT using react hook form

        if (modalState.dispatchProps.type === 'updateArray') {

            const clone = structuredClone(modalState.dispatchProps);
            clone.value['option_image'] = url;

            dispatch(clone);
        } else {
            dispatch({
                ...modalState.dispatchProps,
                value: url,
            })
        }


        setModalState({
            isOpen: false,
            dispatchProps: {},
        })
    }

    const copyUrl = async (image) => {
        const { identityId } = await Auth.currentUserCredentials();
        const url = `https://${bucket}.s3.amazonaws.com/private/${identityId}/${image.key}`;

        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Url Copied to Clipboard!`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
    }

    const selectImage = (image) => {

        if (image.key === selectedImage) {
            setSelectedImage((prev) => '');
        } else {
            setSelectedImage(image.key);
            Swal.fire({
                position: 'bottom',
                toast: true,
                icon: 'success',
                title: `Image Selected!`,
                showConfirmButton: false,
                timer: 2000
            })
        }


    }

    const deleteImage = async (image) => {
        if (image.key === selectedImage) {
            setSelectedImage((prev) => '');
        } else {
            // setSelectedImage(image.key);
            const result = await Storage.remove(image.key, { level: 'private' });
            if (result) {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Image Deleted!`,
                    showConfirmButton: false,
                    timer: 2000
                })

                setImages((prev) => {
                    return prev.filter((img) => img.key !== image.key);
                })


            } else {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Unable to Delete!`,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }
    }



    const content = images.map((image) => {
        // console.log(image.key)
        return (
            <Grid.Column key={image.key} width={5}>
                <ImageCardSimple
                    image={image}
                    selectedImage={selectedImage}
                    copyUrl={copyUrl}
                    selectImage={selectImage}
                    deleteImage={deleteImage}
                />
            </Grid.Column>
        );
    })

    return (

        <Modal
            centered={false}
            open={modalState.isOpen}
            onClose={() => setModalState({ isOpen: false, dispatchProps: {} })}
            scrolling
            size={'fullscreen'}
            closeOnEscape={false}
        >
            <Modal.Header tex>
                Image Gallery
            </Modal.Header>
            <Modal.Content
                image
                scrolling
            >

                {
                    uploaderVisible
                        ?
                        <Container fluid>
                            <ImageUploader testId={testId} />
                        </Container>
                        :
                        <Container fluid>
                            <Grid columns={5}>
                                {content}
                            </Grid>
                        </Container>
                }


            </Modal.Content>

            <Modal.Actions>


                <Checkbox

                    name={'uploaderVisible'}
                    color='green'
                    toggle
                    label={uploaderVisible ? 'Upload Images' : 'Back to Image Gallery'}
                    onChange={(e, data) => setUploaderVisible(data.checked)}
                    checked={uploaderVisible}
                />

                <Button
                    color='grey'
                    onClick={() => setModalState({ isOpen: false, dispatchProps: {} })}
                >
                    Close <Icon name='close right' />
                </Button>
                <Button
                    disabled={!selectedImage}
                    primary
                    onClick={() => handleOnSubmit()}
                >
                    Select Image <Icon name='chevron right' />
                </Button>

            </Modal.Actions>

        </Modal>
    )
}

export default ImageGalleryModal
