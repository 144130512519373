import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Form, Header, Icon, Table } from 'semantic-ui-react';
import React from "react";
import HookFormControlledField from './HookFormControlledField';
import PlaceholderAddElement from './PlaceholderAddElement';


const HookFormControlledTable = ({
    label,
    name,
    control,
    columns,
    headers,
    types,
    striped = false,
    // type = 'text',
    // placeholder = '',
    disabled = false,
    required = false,
}) => {

    const { register } = useFormContext()

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: name, // unique name for your Field Array
    });


    const handleOnAddOption = (e) => {
        e.preventDefault();
        // console.log('event: ', e)

        const obj = headers.reduce((accum, curr, index) => {
            if (types[index] === 'number') {
                return Object.assign(accum, {
                    [curr]: 0,
                })
            } else if (types[index] === 'text') {
                return Object.assign(accum, {
                    [curr]: '',
                })
            }
        }, {})

        append(obj);
    }

    return (
        <>
            <Header>{label}</Header>
            <Table columns={columns + 1} striped={striped} stackable>
                <Table.Header>
                    <Table.Row>
                        {headers.map((header, index) => {
                            return (
                                <Table.HeaderCell key={index}>
                                    <Header size={'h3'}>
                                        {header}
                                    </Header>
                                </Table.HeaderCell>
                            )
                        })}
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        fields
                            .map((field, index) => {
                                // console.log(`${field} - ${index}`)
                                // console.log('field', field)
                                return (
                                    <Table.Row key={field.id}>
                                        {
                                            headers.map((header, idx) => {
                                                return (
                                                    <Table.Cell>
                                                        <input
                                                            key={field.id} // important to include key with field's id
                                                            {...register(`${name}.${index}.${header}`)}
                                                            type={types[idx]}
                                                        />
                                                    </Table.Cell>
                                                );
                                            })
                                        }
                                        <Table.Cell width={2}>
                                            <Button onClick={() => remove(index)} fluid basic color={'red'}>
                                                <Icon name='remove circle' />
                                                Remove Row
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })
                    }

                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell selectable positive colSpan={columns}>
                            <Button onClick={handleOnAddOption} fluid primary>
                                <Icon name='add circle' />
                                Add Row
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>

    );
};

export default HookFormControlledTable;