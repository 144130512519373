// Flashcard.js
import React, { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip'; // Import the library
// import './flashcard.scss'; // Import the CSS file for styling
import AnswerTimer from './AnswerTimer';
import { Icon, Label } from 'semantic-ui-react';

const Flashcard = ({
    front,
    back,
    index = 0,
    isMarked = false,
    onMark = null,
    label = null,
    timerDuration = 0,
    next = null }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleClick = () => {
        setIsFlipped(!isFlipped); // Toggle the flip state
    };

    const onMarkClick = (e) => {
        e.stopPropagation();
        if (onMark) {
            onMark(index);
            // console.log('Favorite button clicked!');
        }
    }

    // turn the card to be front in index changes
    useEffect(() => {
        setIsFlipped(false);
    }, [index]);


    return (
        <div
            //  className={'w-full h-full'}
            //  className={'w-full h-full bg-white px-[30px] py-[30px] rounded-[8px] shadow-[0_2px_4px_rgba(0,0,0,0.1)] box-border border-[1px] text-center mx-auto relative flex items-center justify-center'}
            className={'flashcard-container bg-white shadow-[0_2px_4px_rgba(0,0,0,0.1)] text-center mx-auto relative flex'}

        >
            <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical" flipSpeedBackToFront={0.6} flipSpeedFrontToBack={0.6}
                // className={'flashcard text-lg md:text-xl lg:text-2xl h-96'}
                // className={'w-full h-full bg-white px-[30px] py-[30px] rounded-[8px] shadow-[0_2px_4px_rgba(0,0,0,0.1)] box-border border-[1px] text-center mx-auto relative flex items-center justify-center'}
                // className={'w-full h-56 sm:h-80 md:h-96 lg:h-96 text-lg sm:text-xl md:text-2xl lg:text-2xl'}
                // className={'w-max'}
            >
                {/* Front of the card */}
                <div
                    className='w-[90vw] h-[280px] sm:w-[400px] sm:h-[320x] md:w-[600px] md:h-[360px] lg:w-[800px] lg:h-[420px] bg-white py-[30px] px-[20px] text-center mx-auto relative flex items-center justify-center rounded-lg box-border border border-gray-200'
                    onClick={handleClick}>
                    {label && <Label attached='bottom left'>{label}</Label>}
                    {timerDuration > 0 && <AnswerTimer duration={timerDuration} onTimeUp={handleClick} />}
                    <Icon name={'star'} color={isMarked ? 'yellow' : 'grey'} className={'absolute top-8 right-10 cursor-pointer'} onClick={onMarkClick} />
                    <div className="w-full h-full flex items-center justify-center text-lg sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl">{front}</div>
                </div>

                {/* Back of the card */}
                <div
                    className='w-[90vw] h-[280px] sm:w-[400px] sm:h-[320x] md:w-[600px] md:h-[360px] lg:w-[800px] lg:h-[420px] bg-white py-[30px] px-[20px] text-center mx-auto relative flex items-center justify-center rounded-lg box-border border border-gray-200'
                    onClick={handleClick}>
                    {isFlipped && timerDuration > 0 && <AnswerTimer duration={timerDuration} onTimeUp={next} />}
                    <Icon name={'star'} color={isMarked ? 'yellow' : 'grey'} className={'absolute top-8 right-10 cursor-pointer'} onClick={onMarkClick} />
                    <div className="w-full h-full flex items-center justify-center text-lg sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl">{back}</div>
                </div>
            </ReactCardFlip>
        </div>
    );
};

export default Flashcard;
