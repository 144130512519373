import { Menu, Input, Container, Button, Label, Segment, Icon, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LoginFormModal from './LoginFormModal';
import { useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { navItems } from '../constants/navItems';
import BeeImage from '../images/bee_cartoon_cute_no_background_square_100px_100px.png'

const Navbar = ({ showLoginInit = false }) => {

    const [showLogin, setShowLogin] = useState(showLoginInit);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);

    const toggleLogin = () => {
        setShowLogin((prev) => !prev);
    }

    const toggleNavbar = () => {
        setMobileDrawerOpen((prev) => !prev);
    }

    // console.log('user', user)
    const isAdmin = (user?.signInUserSession?.idToken?.payload['cognito:groups'] &&
        user?.signInUserSession?.idToken?.payload['cognito:groups'].includes('admin'))
        ?? null;

    return (
        <>
            <nav className="sticky top-0 z-50 py-3 backdrop-blur-lg border-b border-neutral-100/80">
                <div className="container px-4 mx-auto relative lg:text-sm">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center flex-shrink-0">
                            <img className="h-10 w-10 mr-2" src={BeeImage} alt="Logo" />
                            <span className="text-lg font-bold tracking-tight text-emerald-500">QuizBee</span>
                            <span className={`text-sm font-bold text-violet-500 superscript mb-3`}>
                                beta
                            </span>
                        </div>
                        <ul className="hidden md:flex ml-14 space-x-12">
                            <Menu secondary>

                                {navItems.map((item, index) => {
                                    return (
                                        <li key={index} >
                                            <Menu.Item as={Link} to={item?.href} className='h-12'>
                                                {item?.photo && <span className='mr-2 w-8 h-8 rounded-full overflow-hidden' > <Image src={item?.photo} circular centered size={'small'} />  </span>}
                                                {item?.label}
                                            </Menu.Item>
                                        </li>
                                    )
                                }
                                )}
                            </Menu>
                        </ul>
                        <div className="hidden md:flex justify-center space-x-12 items-center">
                            {
                                (authStatus === 'authenticated')
                                    ? (
                                        <Menu secondary >

                                            {/* <Menu.Item>
                                                <Input icon='search' placeholder='Search...' />
                                            </Menu.Item> */}
                                            <Menu.Item as={Link} to="/edit">
                                                Create
                                            </Menu.Item>

                                            {isAdmin && (
                                                <Menu.Item as={Link} to="/admin">
                                                    Admin
                                                </Menu.Item>
                                            )}
                                            <Menu.Item>
                                                <Button
                                                    style={{ color: '#20B486', backgroundColor: '#fff', border: '1px solid #20B486', fontWeight: 'normal' }}
                                                    onClick={() => signOut()}
                                                >
                                                    Logout
                                                </Button>
                                            </Menu.Item>
                                        </Menu>
                                    )
                                    : (
                                        <>
                                            <div className={'flex flex-horizontal'}>
                                                <Button
                                                    onClick={toggleLogin}
                                                    style={{ background: 'linear-gradient(to right, #6ee7b7, #20B486)', color: '#ECFDF5', fontWeight: 'normal' }}
                                                >
                                                    Login | Sign Up
                                                </Button>
                                            </div>
                                        </>

                                    )
                            }
                        </div>

                        <div className={'md:hidden sm:flex flex-col justify-end z-24'}>
                            <button onClick={toggleNavbar} style={{ color: '#20B486' }}>
                                {mobileDrawerOpen ? <Icon size={'big'} name={'close'} /> : <Icon size='big' name={'bars'} />}
                            </button>
                        </div>
                    </div>
                    {mobileDrawerOpen && (
                        <div className="fixed right-0 z-20 bg-neutral-50 w-full p-12 flex flex-col justify-center items-center md:hidden">
                            <Menu secondary vertical>
                                {navItems.map((item, index) => (
                                    <Menu.Item key={index} as={Link} to={item.href} className={'py-4 text-lg'}>
                                        {item.label}
                                    </Menu.Item>

                                ))}

                                {
                                    (authStatus === 'authenticated')
                                        ? (
                                            <>
                                                {/* <Menu.Item>
                                                <Input icon='search' placeholder='Search...' />
                                            </Menu.Item> */}
                                                <Menu.Item as={Link} to="/edit" className='py-4 text-lg'>
                                                    Create
                                                </Menu.Item>

                                                {isAdmin && (

                                                    <Menu.Item as={Link} to="/admin" className='py-4 text-lg'>
                                                        Admin
                                                    </Menu.Item>
                                                )}

                                                <Menu.Item>
                                                    <Button
                                                        style={{ color: '#20B486', backgroundColor: '#fff', border: '1px solid #20B486', fontWeight: 'normal' }}
                                                        onClick={() => signOut()}
                                                    >
                                                        Logout
                                                    </Button>
                                                </Menu.Item>
                                            </>
                                        )
                                        : (
                                            <>
                                                <div className={'flex flex-horizontal'}>
                                                    <Button
                                                        onClick={toggleLogin}
                                                        style={{ background: 'linear-gradient(to right, #6ee7b7, #20B486)', color: '#ECFDF5', fontWeight: 'normal' }}
                                                    >
                                                        Login | Sign Up
                                                    </Button>
                                                </div>
                                            </>

                                        )
                                }
                            </Menu>

                        </div>
                    )}


                </div>
            </nav>

            {showLogin && <LoginFormModal open={showLogin} setOpen={setShowLogin} initialState={'signIn'} />}
        </>
    )
}

export default Navbar
