import React, { useState } from 'react'
import SubmitTabs from './SubmitTabs';
import { Container, Divider, Segment, Message, Grid, Icon } from 'semantic-ui-react';
import { TERMS_HTML } from '../constants/terms';
import ButtonSave from './ButtonSave';
import { useEditTestMutation } from '../store/testsSlice';
import Swal from 'sweetalert2';

const Submit = ({ testId }) => {

    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestMutation();

    const handleOnSubmit = () => {

        const body = {
            testId,
            scope: 'submit'
        }

        // console.log('body:', body)

        editTest(body).unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Submission Success`,
                    showConfirmButton: false,
                    timer: 3000
                })
                setStep(2)
            })
            .catch((err) => {
                console.log(err)
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Submission Error: ${err}`,
                    showConfirmButton: false,
                    timer: 3000
                })
            });
    }

    const [step, setStep] = useState(0);

    let content;
    switch (step) {
        case 0:
            content = (
                <Container>
                    <Segment.Group horizontal>
                        <Segment basic textAlign={'left'}>

                            <Message icon>
                                <Icon name='check circle outline' />
                                <Message.Content>
                                    <Message.Header>
                                        Accept Terms & Conditions
                                    </Message.Header>
                                    <p>Accept Terms & Conditions to Proceed</p>
                                </Message.Content>
                            </Message>

                            <ButtonSave
                                type={'button'}
                                color={'green'}
                                isDisabled={false}
                                isLoading={false}
                                onClick={() => {
                                    setStep(1);
                                }}
                                label={'Accept'}
                                isError={false}
                                error={null}
                                icon={'check circle outline'}
                            />
                        </Segment>
                    </Segment.Group>
                    <Segment >
                        <Container fluid text dangerouslySetInnerHTML={{ __html: TERMS_HTML }} />
                    </Segment>
                </Container>
            );
            break;
        case 1:
            content = (
                <Container>
                    <Segment.Group horizontal>
                        <Segment basic textAlign={'left'}>
                            <Message icon
                            >
                                <Icon name='lock' />

                                <Message.Content>
                                    <Message.Header>
                                        Submit Test Draft
                                    </Message.Header>
                                    <p>Submit the Test Draft for Review & Publishing</p>
                                </Message.Content>
                            </Message>

                            <ButtonSave
                                type={'button'}
                                color={'green'}
                                isDisabled={false}
                                isLoading={false}
                                onClick={() => {
                                    handleOnSubmit();
                                }}
                                label={'Submit'}
                                isError={false}
                                error={null}
                                icon={'check circle outline'}
                            />

                        </Segment>
                    </Segment.Group>
                </Container>
            );
            break;

        case 2:
            content = (
                <Container>
                    <Segment.Group horizontal>
                        <Segment basic textAlign={'left'}>
                            <Message icon
                            >
                                <Icon name='thumbs up outline' />

                                <Message.Content>
                                    <Message.Header>
                                        Success
                                    </Message.Header>
                                    <p>Thank you for Submitting Your Test for Review and Publishing</p>
                                </Message.Content>
                            </Message>
                        </Segment>
                    </Segment.Group>
                </Container>
            );
            break;
        default:
            setStep(0);
            console.log(`Error: ${step} is not a recognized step`)
    }


    return (
        <Container fluid>
            <SubmitTabs step={step} />
            <Divider />
            {content}

        </Container>
    )
}

export default Submit
