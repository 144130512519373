import React, {createRef, useState} from 'react'
import {Button, Segment, Container, Checkbox, Sticky} from 'semantic-ui-react';
import {useCreateSessionMutation} from '../store/testsSlice';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';

const UserTestView = ({DisplayComponent, testId}) => {

    const [createSession, {
        data: createSessionData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useCreateSessionMutation();

    // console.log(props)
    const [isExam, setIsExam] = useState(false);
    const [isTimed, setIsTimed] = useState(false);

    const navigate = useNavigate();

    const content = <DisplayComponent/>

    const handleOnStartTest = () => {

        const body = {
            testId,
            isExam,
            isTimed,
        }

        createSession(body)
            .unwrap()
            .then((fulfilled => {
                // console.log((fulfilled));

                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Starting Test`,
                    showConfirmButton: false,
                    timer: 3000
                })

                navigate(`/sessions/${fulfilled['sessionId']}`)

            }))
            .catch((rejected) => {
                console.log(rejected);
                // reverse optimistic update if it fails

                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Error Starting Test, Please Try Again`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })

    }

    return (
            <Container>
                <Segment.Group horizontal>
                        <Segment basic>
                            <Button
                                color={'black'}
                                size={'medium'}
                                // Add toggle for Retake vs Start
                                // label={'Start Test'}
                                className={'mx-5 w-56'}
                                onClick={handleOnStartTest}
                            >
                                Start Test
                            </Button>

                        </Segment>

                        <Segment basic className={'items-center content-center'}>
                            <div className={'container-flex-row fluid items-center'}>


                                <div className={'basis-4 inline flex-1 align-middle items-center'}>
                                    <Checkbox
                                        name={'isPractice'}
                                        color='green'
                                        toggle
                                        label={isExam ? 'Exam Mode' : 'Practice Mode'}
                                        checked={isExam}
                                        onChange={(e, data) => {
                                            e.preventDefault();
                                            setIsExam((prev) => data.checked)
                                        }}
                                        // disabled={scope === ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value
                                        //     || scope === ADMIN_EDIT_TEST_SECTIONS.APPROVE.value
                                        //     || scope === ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value
                                        // }
                                    />
                                </div>

                                <div className={'basis-4 inline flex-1 align-middle items-center'}>

                                    <Checkbox
                                        name={'isTimed'}
                                        align='right'
                                        color='green'
                                        toggle
                                        label={isTimed ? 'Timed' : 'Not Timed'}
                                        checked={isTimed}
                                        onChange={(e, data) => {
                                            e.preventDefault();
                                            setIsTimed((prev) => data.checked)
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>

                </Segment.Group>
                <Segment basic>

                    {content}

                </Segment>
            </Container>
    )
}

export default UserTestView
