import './AnswerTimer.scss';
import { useEffect, useState, useRef } from 'react';

const AnswerTimer = ({ duration, onTimeUp }) => {

    // 10x duration because counter increments at 0.1s intervals
    duration = duration * 10;

    const [counter, setCounter] = useState(0);
    const [progressLoaded, setProgressLoaded] = useState(0);

    const intervalRef = useRef();

    useEffect(() => {

        intervalRef.current = setInterval(() => {
            setCounter((curr) => curr + 1)
        }, 100)


        return () => clearInterval(intervalRef.current);

    }, [])

    useEffect(() => {
        setProgressLoaded(100 * (counter / duration));

        if (counter >= duration) {
            clearInterval(intervalRef.current);

            setTimeout(() => {
                if (onTimeUp) {
                    onTimeUp();
                }
            }, 100)
        }

    }, [counter])


    return <div className='answer-timer-container'>
        <div
            className='progress-bar'
            style={{
                width: `${progressLoaded}%`,
                backgroundColor: `${progressLoaded < 50
                    ? 'lightgreen'
                    : progressLoaded < 70
                        ? 'orange'
                        : 'red'
                    }`
            }}
        ></div>
    </div>
}

export default AnswerTimer;