import React from 'react'
import {Container, Input, Form} from 'semantic-ui-react';
import {Controller} from "react-hook-form";


const HookFormControlledImagePicker = ({
                                           name,
                                           setModalState,
                                           control,
                                           required = false,
                                           label = 'Image Url',
                                           placeholder = 'Image Url',
                                       }) => {
    return (
        <Controller
            shouldUnregister={true}
            name={name}
            control={control}
            rules={{
                required: {
                    value: required,
                    message: "Missing ImageUrl"
                }
            }}
            defaultValue={""}
            render={({
                         field: {onChange, onBlur, value, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                     }) =>
                <Form.Input
                    action={{
                        color: 'blue',
                        labelPosition: 'left',
                        icon: 'browser',
                        content: 'Browse',
                        onClick: (e) => {
                            e.preventDefault();
                            setModalState({
                                isOpen: true,
                                field: name,
                            })
                        },
                    }}
                    width={16}
                    label={label}
                    value={value}
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched
                    inputRef={ref} // wire up the input ref
                    placeholder={placeholder}
                    error={error ? {
                        content: error?.message,
                        pointing: 'above',
                    } : false}
                    focus
                />
            }
        />
    )
}

export default HookFormControlledImagePicker
