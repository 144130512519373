import React from 'react'
import { useGetTestsByAuthorIdQuery } from '../store/testsSlice';
import { Container, Header } from 'semantic-ui-react';
import PlaceholderComponent from './PlaceholderComponent';
import AuthorTestCard from './AuthorTestCard';
import TestCard from './TestCard';

const AuthorTests = () => {
    const { data: tests, isFetching: isTestsFetching, isLoading: isTestsLoading, isError: isTestsError, error: testsError, isSuccess: isTestsSuccess, refetch: testsRefetch } = useGetTestsByAuthorIdQuery();

    let content;
    if (isTestsFetching) {
        content = <PlaceholderComponent />;
    } else if (isTestsError) {
        content = <Header as={'h1'}>{JSON.stringify(testsError)}</Header>;
    } else if (isTestsSuccess) {

        content = tests.map((test, index) => {
            return (
                <AuthorTestCard test={test} key={`${test?.id}-${test?.version}`}/>
            );
        })


    } else {
        //isUninitialized
        content = <PlaceholderComponent />;
    }

    return (
        <Container className='py-24'>
            {content}
        </Container>
    )
}

export default AuthorTests
