import React, {useReducer, useEffect, useState} from 'react'
import {
    Button,
    Container,
    Icon,
    Image,
    Item,
    Label,
    List,
    Rating,
    Loader,
    Divider,
    Form,
    Grid,
    Input,
    Segment,
    Header
} from 'semantic-ui-react';
import {useEditTestMutation, useGetFullTestQuery} from '../store/testsSlice';
import PlaceholderComponent from './PlaceholderComponent';
import {useForm, Controller, useFieldArray} from "react-hook-form";
import ImageGalleryModal from './ImageGalleryModal';
import HookFormControlledImagePicker from './HookFormControlledImagePicker';
import Swal from "sweetalert2";


const CardEditor = ({testId}) => {

    const {
        data: test,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);
    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestMutation();
    const [modalState, setModalState] = useState({isOpen: false, dispatchProps: {}});

    const {
        handleSubmit, getValues,
        control, watch, setValue,
        formState: {isDirty}
    } = useForm({
        values: structuredClone(test?.['product_card']),
        resetOptions: {
            keepDirtyValues: false, // keep dirty fields unchanged, but update defaultValues
        },
    })

    const {fields, update} = useFieldArray({
        control,
        // values: structuredClone(test?.['product_card']?.['description_bullet_points']),
        name: 'description_bullet_points',
        shouldUnregister: true,
        rules: {minLength: 4, maxLength: 4},
    });

    const watchImage = watch("image")

    const handleOnFormSubmit = (data) => {

        const body = {
            card: {...data},
            testId,
            scope: 'card',
        }
        // console.log(body)
        editTest(body)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Update saved`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => console.log(err))
    }


    return (
        <Container>

            {modalState?.isOpen &&
                <ImageGalleryModal
                    testId={testId}
                    modalState={modalState}
                    // dispatch={dispatch}
                    setModalState={setModalState}
                    setValue={(field, value) => setValue(field, value)}
                />}


            <Form
                onSubmit={handleSubmit(handleOnFormSubmit)}
                loading={isTestLoading}
            >

                <Grid columns={2} divided relaxed verticalAlign={'middle'}>
                    <Grid.Column>
                        <Grid.Row stretched>
                            <Image size={'large'} src={watchImage}/>
                            <Divider hidden/>
                            <Form.Field>
                                <HookFormControlledImagePicker
                                    name={'image'}
                                    setModalState={setModalState}
                                    control={control}
                                    required={true}
                                    label={'Image Url'}
                                    placeholder={'Image Url'}
                                />
                            </Form.Field>
                        </Grid.Row>
                    </Grid.Column>

                    <Grid.Column>
                        <Grid.Row stretched>

                            <Form.Group widths={16}>
                                <Container>
                                    <Form.Field>

                                        <Controller
                                            name={'header'}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Missing Header Text"
                                                }
                                            }}
                                            defaultValue={""}
                                            render={({
                                                         field: {onChange, onBlur, value, ref},
                                                         fieldState: {invalid, isTouched, isDirty, error},
                                                     }) =>
                                                <Input
                                                    label={'Header'}
                                                    value={value}
                                                    onChange={onChange} // send value to hook form
                                                    onBlur={onBlur} // notify when input is touched
                                                    inputRef={ref} // wire up the input ref
                                                    placeholder={'Enter Header Test'}
                                                    error={error ? {
                                                        content: error?.message,
                                                        pointing: 'above',
                                                    } : false}
                                                    focus
                                                />
                                            }
                                        />
                                    </Form.Field>

                                    {/* TODO: implement author class */}
                                    <Image avatar src='https://react.semantic-ui.com/images/avatar/small/rachel.png'/>
                                    <span className='author'
                                          style={{color: 'blue'}}>Author Name Placeholder | Title</span>

                                    <Form.Field>

                                        <Controller
                                            name={'description'}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Missing Description Text"
                                                }
                                            }}
                                            defaultValue={""}
                                            render={({
                                                         field: {onChange, onBlur, value, ref},
                                                         fieldState: {invalid, isTouched, isDirty, error},
                                                     }) =>
                                                <Input
                                                    label={'Description'}
                                                    value={value}
                                                    onChange={onChange} // send value to hook form
                                                    onBlur={onBlur} // notify when input is touched
                                                    inputRef={ref} // wire up the input ref
                                                    placeholder={'Enter Subheader / Description'}
                                                    error={error ? {
                                                        content: error?.message,
                                                        pointing: 'above',
                                                    } : false}
                                                    focus
                                                />
                                            }
                                        />
                                    </Form.Field>

                                    <List>
                                        {
                                            fields.map((field, index) => {

                                                if (index === 0) {
                                                    return (
                                                        <List.Item key={field.id}>
                                                            <Form.Field>
                                                                <Controller
                                                                    name={`description_bullet_points.${index}.text`}
                                                                    control={control}
                                                                    rules={{
                                                                        required: {
                                                                            value: true,
                                                                            message: "Missing Description Summary"
                                                                        }
                                                                    }}
                                                                    defaultValue={""}
                                                                    render={({
                                                                                 field: {onChange, onBlur, value, ref},
                                                                                 fieldState: {
                                                                                     invalid,
                                                                                     isTouched,
                                                                                     isDirty,
                                                                                     error
                                                                                 },
                                                                             }) =>
                                                                        <Input
                                                                            label={'text'}
                                                                            value={value}
                                                                            onChange={onChange} // send value to hook form
                                                                            onBlur={onBlur} // notify when input is touched
                                                                            inputRef={ref} // wire up the input ref
                                                                            placeholder={'Enter Description Summary'}
                                                                            error={error ? {
                                                                                content: error?.message,
                                                                                pointing: 'above',
                                                                            } : false}
                                                                            focus
                                                                        />
                                                                    }
                                                                />

                                                            </Form.Field>
                                                        </List.Item>
                                                    )
                                                } else {

                                                    return (
                                                        <List.Item key={field.id}>
                                                            <Form.Field>

                                                                <Controller
                                                                    name={`description_bullet_points.${index}.text`}
                                                                    control={control}
                                                                    rules={{
                                                                        required: {
                                                                            value: true,
                                                                            message: "Missing Bullet Point Text"
                                                                        }
                                                                    }}
                                                                    defaultValue={""}
                                                                    render={({
                                                                                 field: {onChange, onBlur, value, ref},
                                                                                 fieldState: {
                                                                                     invalid,
                                                                                     isTouched,
                                                                                     isDirty,
                                                                                     error
                                                                                 },
                                                                             }) =>
                                                                        <Input
                                                                            label={{icon: 'check'}}
                                                                            value={value}
                                                                            onChange={onChange} // send value to hook form
                                                                            onBlur={onBlur} // notify when input is touched
                                                                            inputRef={ref} // wire up the input ref
                                                                            placeholder={'Enter Bullet Point Text'}
                                                                            error={error ? {
                                                                                content: error?.message,
                                                                                pointing: 'above',
                                                                            } : false}
                                                                            focus
                                                                        />
                                                                    }
                                                                />

                                                            </Form.Field>
                                                        </List.Item>
                                                    )
                                                }
                                            })

                                        }
                                    </List>

                                    <List>
                                        <List.Item>
                                            <List.Content>
                                                <span
                                                    style={{
                                                        color: 'orange',
                                                        'fontWeight': 'bold',
                                                        'fontSize': '1.2rem'
                                                    }}
                                                >
                                                    {(Math.round((Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])) * 100) / 100).toFixed(1)}
                                                </span>
                                                <Rating
                                                    disabled icon='star'
                                                    defaultRating={Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])}
                                                    maxRating={5}
                                                />
                                                <span>
                                                    ({Number(test?.['stats']?.['ratings'])})
                                                </span>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Form.Field>


                                                    <Controller
                                                        name={'price'}
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Missing Price"
                                                            }
                                                        }}
                                                        defaultValue={""}
                                                        render={({
                                                                     field: {onChange, onBlur, value, ref},
                                                                     fieldState: {invalid, isTouched, isDirty, error},
                                                                 }) =>
                                                            <Input
                                                                label={'Price'}
                                                                value={value}
                                                                onChange={onChange} // send value to hook form
                                                                onBlur={onBlur} // notify when input is touched
                                                                inputRef={ref} // wire up the input ref
                                                                placeholder={'Enter Price'}
                                                                error={error ? {
                                                                    content: error?.message,
                                                                    pointing: 'above',
                                                                } : false}
                                                                focus
                                                                type={'number'}
                                                            />
                                                        }
                                                    />

                                                </Form.Field>

                                            </List.Content>
                                        </List.Item>
                                    </List>

                                    <br/>
                                    {
                                        Object.entries(getValues("tags"))

                                            .map(([key, val], index) => {
                                                if (key === 'sale') {
                                                    return (
                                                        <Label key={index} tag color='red'>{val}</Label>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Field key={index}>

                                                            <Controller
                                                                name={`tags[${key}]`}
                                                                control={control}
                                                                rules={{
                                                                    required: {
                                                                        value: true,
                                                                        message: "Missing Tag"
                                                                    }
                                                                }}
                                                                defaultValue={""}
                                                                render={({
                                                                             field: {onChange, onBlur, value, ref},
                                                                             fieldState: {
                                                                                 invalid,
                                                                                 isTouched,
                                                                                 isDirty,
                                                                                 error
                                                                             },
                                                                         }) =>
                                                                    <Input
                                                                        label={key}
                                                                        value={value}
                                                                        onChange={onChange} // send value to hook form
                                                                        onBlur={onBlur} // notify when input is touched
                                                                        inputRef={ref} // wire up the input ref
                                                                        placeholder={`Enter ${key}`}
                                                                        error={error ? {
                                                                            content: error?.message,
                                                                            pointing: 'above',
                                                                        } : false}
                                                                        focus
                                                                    />
                                                                }
                                                            />
                                                        </Form.Field>
                                                    );
                                                }
                                            })
                                    }
                                    {/* </Label.Group> */}
                                    {/* </Item.Extra> */}
                                </Container>

                            </Form.Group>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>


                <Button
                    type='submit'
                    color='green'
                    disabled={editTestIsLoading}
                >
                    <>
                        {!editTestIsLoading ? <Icon name='save outline left'/> : <Loader inline active size={'mini'}/>}
                        Save Card Changes
                    </>
                </Button>

                {editTestIsError &&
                    <Segment><Header as={'h3'}>{JSON.stringify(editTestError)}</Header></Segment>}

            </Form>

        </Container>
    )

}

export default CardEditor
