export const VERSION_OPTIONS = [
    {
        key: 'draft',
        text: 'Draft',
        value: 'draft'
    },  {
        key: 'any',
        text: 'Any',
        value: null
    },
];


