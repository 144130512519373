import {Controller} from 'react-hook-form';
import {Form, Rating} from 'semantic-ui-react';


const HookFormControlledRating = ({
                                      label,
                                      name,
                                      control,
                                      disabled = false,
                                      required = false,
                                      size = 'massive',
                                      maxRating = 5,
                                      icon = 'star',
                                      defaultRating = 5,
                                  }) => {
    return (

        <Form.Field>
            <label htmlFor={name}>{label}</label>

            <Controller
                shouldUnregister={true}
                control={control}
                name={name}
                rules={{
                    required: {
                        value: required,
                        message: `Missing ${name}`
                    }
                }}
                render={({
                             field: {onChange, onBlur, value, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                         }) => (

                    <Rating
                        maxRating={maxRating}
                        rating={value}
                        size={size}
                        onRate={(e, data) => {
                            e.preventDefault();
                            onChange(data.rating);
                        }}
                        disabled={disabled}
                        icon={icon}
                        defaultRating={defaultRating}
                    />
                )}
            />
        </Form.Field>
    )
        ;
};

export default HookFormControlledRating;