import {useState} from 'react'
import {Button, Container, Header, Image, Modal} from 'semantic-ui-react'
import AuthenticatorAmplify from './AuthenticatorAmplify'
import {useLocation} from "react-router-dom";

const LoginFormModal = ({open, setOpen, initialState = 'signIn'}) => {
    // const [open, setOpen] = useState(open)

    const redirectTo = new URLSearchParams(useLocation().search).get('redirectTo');
    // console.log('redirectTo:', redirectTo)

    return (
        <Modal
            closeIcon
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size={'tiny'}
            // trigger={<Button>Show Modal</Button>}
        >
            <Modal.Header>
                Login / Sign-up
            </Modal.Header>
            <Modal.Content>
                <AuthenticatorAmplify setModal={setOpen} redirectTo={redirectTo} initialState={initialState}/>
            </Modal.Content>
        </Modal>
    )
}

export default LoginFormModal