import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../store/apiSlice'


Amplify.configure(awsExports);


const AuthenticatorAmplify = ({ setModal = null, redirectTo = null, initialState = 'signIn' }) => {

    const redirectToInner = new URLSearchParams(useLocation().search).get('redirectTo');

    redirectTo = redirectTo || redirectToInner;

    return (
        <Authenticator
            initialState={initialState}
            onAuthStateChange={(nextAuthState, authData) => {
                // console.log('authData', authData)
                // setAuthState(nextAuthState);
                // setUser(authData);
                // console.log('authData', authData)
                // console.log('nextAuthState', nextAuthState)
            }}
        >
            {
                ({ signOut, user }) => {

                    if (user) {

                        setModal && setModal(false);

                        // Get the user ID from the authenticated user object
                        // const userId = user.attributes.sub;

                        // toast({
                        //     title: 'Login Successful',
                        //     description: 'You have successfully logged in.',
                        //     status: 'success',
                        //     duration: 5000,
                        //     isClosable: true,
                        //     variant: 'solid',
                        //     containerStyle: {
                        //         backgroundColor: 'green.400',
                        //         color: 'white',
                        //     },
                        // });

                        return (
                            <Navigate to={redirectTo || '/'} replace />
                        )
                    }

                    return null;
                }
            }
        </Authenticator>
    );
}

export default AuthenticatorAmplify;