// HeroSection.js
import React, { useState } from 'react';

function FlashcardForm() {
  const [flashcards, setFlashcards] = useState([{ id: 1 }]);

  const addFlashcard = () => {
    setFlashcards([...flashcards, { id: flashcards.length + 1 }]);
  };

  const removeFlashcard = (id) => {
    setFlashcards(flashcards.filter(flashcard => flashcard.id !== id));
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center mb-6">Create Flashcards</h2>
      <form>
        {flashcards.map(flashcard => (
          <div key={flashcard.id} className="flashcard flex items-center mb-4">
            <div className="input-group flex-1 mr-4">
              <label htmlFor={`term${flashcard.id}`} className="block text-sm font-medium text-gray-700">
                Enter term
              </label>
              <input
                type="text"
                id={`term${flashcard.id}`}
                name={`term${flashcard.id}`}
                placeholder="TERM"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="input-group flex-1 mr-4">
              <label htmlFor={`definition${flashcard.id}`} className="block text-sm font-medium text-gray-700">
                Enter definition
              </label>
              <input
                type="text"
                id={`definition${flashcard.id}`}
                name={`definition${flashcard.id}`}
                placeholder="DEFINITION"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="image-upload flex flex-col items-center mr-4">
              <input type="file" id={`image${flashcard.id}`} name={`image${flashcard.id}`} accept="image/*" className="hidden" />
              <label
                htmlFor={`image${flashcard.id}`}
                className="block bg-gray-200 text-gray-700 py-2 px-4 border border-dashed border-gray-400 rounded cursor-pointer"
              >
                IMAGE
              </label>
            </div>
            <button
              type="button"
              onClick={() => removeFlashcard(flashcard.id)}
              className="text-red-500 text-lg"
            >
              🗑️
            </button>
          </div>
        ))}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={addFlashcard}
            className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600"
          >
            Add Flashcard
          </button>
          <button
            type="submit"
            className="bg-teal-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-teal-600"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
}

export default FlashcardForm;
