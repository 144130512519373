import React, { useState, useEffect, createRef, useRef } from 'react';
import { useEditSessionMutation, useGetSessionQuery } from "../store/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, Button, Divider, Container, Segment, Header, Sticky, Breadcrumb } from 'semantic-ui-react';
import Question from './Question';
import Swal from 'sweetalert2';
import InstructionsViewSession from './InstructionsViewSession';
import QuestionViewer from './QuestionViewer';
import Timer from './Timer';
import SessionViewer from './SessionViewer';

const Session = () => {
    // TODO Fix navigation or delete

    const { sessionId } = useParams();
    const {
        data: session,
        refetch: sessionRefetch,
        isSuccess: sessionIsSuccess,
        isError: sessionIsError
    }
        = useGetSessionQuery(sessionId);

    const [editSession,
        {
            data: sessionCompleteData,
            error: sessionCompleteError,
            isLoading: sessionCompleteIsLoading,
            isSuccess: sessionCompleteIsSuccess,
            isError: sessionCompleteIsError
        }] = useEditSessionMutation();

    console.log('session', session)

    // const [questionCount, setQuestionCount] = useState(0);
    // const [questionIndex, setQuestionIndex] = useState(-1);

    // Set Practice/Test and Timed/Untimed mode
    // const [isExamMode, setExamMode] = useState()
    // const [isTimedMode, setTimedMode] = useState()

    const myRefTimer = useRef(null);
    const timer =
        <Timer start={session?.startTime || session?.createTime} limit={session?.config?.time_limit} showTimer={session?.isTimed} />


    // const navigate = useNavigate();

    // useEffect(() => {

    //     if (sessionIsSuccess) {
    //         setQuestionCount(session?.questions?.length);
    //     }

    //     // console.log('session', session)

    //     // check of Session still in Started state and setQuestionIndex to ResumeIndex
    //     // if (session?.['session_status'] === 'Started' && session?.['resume_question_index']) {
    //     //     setQuestionIndex(Number(session?.['resume_question_index']))
    //     // }

    // }, [session, sessionIsSuccess]);


    // const handleNextClickButton = () => {
    //     setQuestionIndex((prev) => Math.min(prev + 1, questionCount - 1));
    // }

    // const handleStartClickButton = () => {
    //     handleNextClickButton();
    //     editSession({
    //         action: 'Started',
    //         sessionId: sessionId,
    //     })
    // }

    // const handlePreviousClickButton = () => {
    //     setQuestionIndex((prev) => Math.max(prev - 1, -1));
    // }

    // const handleCancelClickButton = () => {
    //     editSession({
    //         action: 'Cancelled',
    //         sessionId: sessionId,
    //     }).unwrap()
    //         .then(fulfilled => {


    //             Swal.fire({
    //                 position: 'bottom',
    //                 toast: true,
    //                 icon: 'success',
    //                 title: `Test Cancelled`,
    //                 showConfirmButton: false,
    //                 timer: 3000
    //             })

    //             // navigate(`/sessions/${sessionId}/results`);

    //         })
    //         .catch(rejected => {
    //             console.error(rejected)


    //             Swal.fire({
    //                 position: 'bottom',
    //                 toast: true,
    //                 icon: 'error',
    //                 title: `Error Cancelling Test, Please Try Again`,
    //                 showConfirmButton: false,
    //                 timer: 3000
    //             })
    //         })
    // }

    // const handleCompleteClickButton = () => {
    //     // setQuestionIndex((prev) => prev + 1);

    //     if (session?.['session_status'] !== 'Started') {
    //         return;
    //     }

    //     editSession({
    //         action: 'Completed',
    //         sessionId: sessionId,
    //     })
    //         .unwrap()
    //         .then(fulfilled => {


    //             Swal.fire({
    //                 position: 'bottom',
    //                 toast: true,
    //                 icon: 'success',
    //                 title: `Test Completed`,
    //                 showConfirmButton: false,
    //                 timer: 3000
    //             })

    //             navigate(`/sessions/${sessionId}/results`);

    //         })
    //         .catch(rejected => {
    //             console.error(rejected)


    //             Swal.fire({
    //                 position: 'bottom',
    //                 toast: true,
    //                 icon: 'error',
    //                 title: `Error Completing Test, Please Try Again`,
    //                 showConfirmButton: false,
    //                 timer: 3000
    //             })
    //         })


    // }

    // const Navigation = () => {
    //     // instructions
    //     if (questionIndex < 0) {
    //         return (
    //             <Segment.Group horizontal>
    //                 <Segment basic>
    //                     <Button
    //                         icon
    //                         labelPosition='left'
    //                         color='black'
    //                         onClick={handleCompleteClickButton}
    //                         disabled={(session?.['session_status'] !== 'Started')}
    //                     >
    //                         Complete
    //                         <Icon name='flag checkered' />
    //                     </Button>
    //                     <Button icon labelPosition='left' onClick={handleCancelClickButton}>
    //                         <Icon name='cancel' />
    //                         Cancel
    //                     </Button>
    //                     {myRefTimer.current}
    //                     {/* <Timer start={session?.startTime || session?.createTime} limit={session?.config?.time_limit} showTimer={session?.isTimed} /> */}
    //                 </Segment>
    //                 <Segment basic className={'flex items-center justify-end justify-items-end'}>
    //                     <Button disabled icon labelPosition='left' onClick={handlePreviousClickButton}>
    //                         <Icon name='left arrow' />
    //                         Previous
    //                     </Button>
    //                     <Button icon labelPosition='right' color='black' onClick={handleStartClickButton}>
    //                         Start
    //                         <Icon name='right arrow' />
    //                     </Button>
    //                 </Segment>

    //             </Segment.Group>
    //         )
    //         // normal question
    //     } else {
    //         return (
    //             <Segment.Group horizontal>
    //                 <Segment basic>
    //                     <Button
    //                         icon
    //                         labelPosition='left'
    //                         color='black'
    //                         onClick={handleCompleteClickButton}
    //                         disabled={(session?.['session_status'] !== 'Started')}
    //                     >
    //                         Complete
    //                         <Icon name='flag checkered' />
    //                     </Button>
    //                     <Button icon labelPosition='left' onClick={handleCancelClickButton}>
    //                         <Icon name='cancel' />
    //                         Cancel
    //                     </Button>
    //                     {myRefTimer.current}
    //                     {/* <Timer start={session?.startTime || session?.createTime} limit={session?.config?.time_limit} showTimer={session?.isTimed} /> */}
    //                 </Segment>
    //                 <Segment basic className={'flex items-center justify-end justify-items-end'}>
    //                     <Button icon labelPosition='left' onClick={handlePreviousClickButton}>
    //                         <Icon name='left arrow' />
    //                         Previous
    //                     </Button>
    //                     <Button disabled={questionIndex >= questionCount - 1} icon labelPosition='right' color='black'
    //                         onClick={handleNextClickButton}>
    //                         Next
    //                         <Icon name='right arrow' />
    //                     </Button>
    //                 </Segment>
    //             </Segment.Group>
    //         )
    //     }
    // }

    return (
        // <div ref={contextRef}>
        <Container>
            {sessionIsSuccess
                &&
                <SessionViewer session={session} editSession={editSession} timer={timer} />
            }
        </Container>
    )

}

export default Session;
