import { Container, Header, Divider, Table, Icon, Image, Button } from 'semantic-ui-react';
import { useGetFullTestQuery, useGetTestQuery } from '../store/testsSlice';
import PlaceholderComponent from './PlaceholderComponent';
import DOMPurify from "dompurify";
import testType, { TEST_TYPE_MAP } from "../constants/testType";

const InstructionsViewSession = ({ session }) => {


    const sanitizedData = (htmlData) => ({
        __html: DOMPurify.sanitize(htmlData)
    })

    return (
        <Container fluid>
            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard' />
                    Instructions
                </Header>
            </Divider>

            <Table definition>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>Test Category</Table.Cell>
                        <Table.Cell>{session?.config?.category}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>Number of Questions</Table.Cell>
                        <Table.Cell>{session?.questions?.length}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Recommended time limit</Table.Cell>
                        <Table.Cell>{`${session?.config?.time_limit} mins`}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Container text>
                <div
                    dangerouslySetInnerHTML={sanitizedData(session?.config?.instructions)}
                />
                <Image src={session?.config?.instructions_image} size={'big'} centered />
            </Container>
        </Container>
    )
}

export default InstructionsViewSession
