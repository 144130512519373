import { Link, Outlet, useLocation } from 'react-router-dom';
import React, { createRef, RefObject } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

interface LayoutProps {
  excludeFooter?: boolean;
  excludeNavbar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ excludeFooter = false, excludeNavbar = false }: LayoutProps) => {
  const excludeLayout: string[] = ['/beebot','beebot','/beebot/','beebot/'];

  
  const contextRef: RefObject<HTMLDivElement> = createRef();
  const location = useLocation();

  // console.log('location', location.pathname);

  const shouldExcludeFooter = excludeLayout.includes(location.pathname) || excludeFooter;
  const shouldExcludeNavbar = excludeLayout.includes(location.pathname) || excludeNavbar;

  return (
    <>
      {!shouldExcludeNavbar && <Navbar />}
      <main id="detail">
        <Outlet context={contextRef} />
      </main>
      {!shouldExcludeFooter && <Footer />}
    </>
  );
};

export default Layout;
