import React, { useState } from 'react'
import { Button, Checkbox, Container, Form, Menu, MenuItem } from 'semantic-ui-react'
import TEST_TYPE, { FLASHCARD_DECK_TYPE } from '../constants/testType';
import { useCreateTestMutation } from '../store/testsSlice';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import HookFormControlledField from './HookFormControlledField';
import HookFormControlledDropdown from './HookFormControlledDropdown';
import ButtonSave from './ButtonSave';


const TestCreateNew = () => {

    const [itemToCreate, setItemToCreate] = useState('test');

    const methods = useForm({
        defaultValues: {},
        shouldUnregister: true,
    })

    const {
        handleSubmit,
        register,
        reset,
        getValues,
        control,
        watch,
        setValue,
        formState: { isDirty, isValid, errors }
    } = methods;

    const [createTest, { data, error, isLoading, isSuccess, isError }] = useCreateTestMutation();

    const handleOnFormSubmit = (data) => {

        const body = {
            ...data,
            item: itemToCreate,
        }

        console.log(body)

        createTest(body)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Test Created`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => console.log(err))
    }

    const formFields = itemToCreate === 'test' ?
        (
            <>
                <HookFormControlledField
                    name={'title'}
                    control={control}
                    label={`Enter Test Title`}
                    required={true}
                />


                <HookFormControlledDropdown
                    name={'category'}
                    control={control}
                    label={'Select Test Category'}
                    options={TEST_TYPE}
                    required={true}
                />
            </>
        ) :
        itemToCreate === 'flashcards' ?
            (
                <>
                    <HookFormControlledField
                        name={'title'}
                        control={control}
                        label={`Enter Flashcard Deck Title`}
                        required={true}
                    />


                    <HookFormControlledDropdown
                        name={'category'}
                        control={control}
                        label={'Select Flashcard Category'}
                        defaultValues={FLASHCARD_DECK_TYPE[0]}
                        options={FLASHCARD_DECK_TYPE}
                        required={true}
                    />
                </>
            ) : (<></>)

    return (
        <Container className='py-24'>


            <Menu>
                <MenuItem
                    name='test'
                    active={itemToCreate === 'test'}
                    onClick={() => setItemToCreate('test')}
                >
                    Create Test
                </MenuItem>

                <MenuItem
                    name='flashcards'
                    active={itemToCreate === 'flashcards'}
                    onClick={() => setItemToCreate('flashcards')}
                >
                    Create Flashcard Deck
                </MenuItem>

            </Menu>

            <Form
                onSubmit={handleSubmit(handleOnFormSubmit)}
                widths={'equal'}
            >

                {formFields}

                <ButtonSave
                    type={'submit'}
                    color={'green'}
                    isDisabled={!isDirty}
                    label={'Create New'}
                    isError={isError || Object.keys(errors).length !== 0}
                    error={{ ...error, ...errors }}
                />

            </Form>
        </Container>
    )
}

export default TestCreateNew
