import React from 'react'
import { Button, Container, Icon, Image, Item, Label, List, Rating, Loader, Divider } from 'semantic-ui-react';
import { redirect, useNavigate, useLocation, useNavigation } from "react-router-dom";
import { useGetFullTestQuery } from '../store/testsSlice';
import TestCard from './TestCard';
import { STATUS_OPTIONS_COLOR, STATUS_OPTIONS_LOOKUP } from '../constants/statusOptions';

const AuthorTestCard = ({ test }) => {

    // const { data: test, isLoading: isTestLoading, isError: isTestError, error: testError, isSuccess: isTestSuccess, refetch: testRefetch } = useGetFullTestQuery(testId);
    const navigate = useNavigate();

    // console.log('test', test)

    const item = test?.config?.item;

    return (
        <Container
            className='p-2 cursor-pointer'
            onClick={() => {
                navigate(item === 'flashcards' ? `/editcards/${test.id}` : `${test.id}`);
            }}
        >
            <TestCard authorTestCard={true} test={test} modal={{ mountModal: null, setContent: null }} />
        </Container>
    )
}

export default AuthorTestCard
