const TEST_TYPE = [
    {
        key: 'point_score',
        text: 'Point & Percentage score',
        value: 'point_score'
    },
    {
        key: 'pass_fail',
        text: 'Pass/Fail Score',
        value: 'pass_fail'
    },
    {
        key: 'point_score_percentile_distribution',
        text: 'Point Score % Distribution',
        value: 'point_score_percentile_distribution'
    },
    {
        key: 'iq_point_score',
        text: 'IQ Point Score',
        value: 'iq_point_score'
    },
];

export const FLASHCARD_DECK_TYPE =  [
    {
        key: 'flashcard_deck',
        text: 'Flashcard Deck',
        value: 'flashcard_deck'
    },
];

export const TEST_TYPE_MAP = {
    point_score: {
        key: 'point_score',
        text: 'Point & Percentage score',
        value: 'point_score'
    },
    pass_fail: {
        key: 'pass_fail',
        text: 'Pass/Fail Score',
        value: 'pass_fail'
    },
    point_score_percentile_distribution: {
        key: 'point_score_percentile_distribution',
        text: 'Point Score % Distribution',
        value: 'point_score_percentile_distribution'
    },
    iq_point_score: {
        key: 'iq_point_score',
        text: 'IQ Point Score',
        value: 'iq_point_score'
    },
};

export default TEST_TYPE;