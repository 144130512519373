import React, { useEffect, useState } from 'react';
import { Card, Icon, Image, Button } from 'semantic-ui-react';
import { Amplify, Auth, Storage } from 'aws-amplify';



const ImageCard = ({ image, deleteImage, copyUrl }) => {

    const [signedUrl, setSignedUrl] = useState('');

    useEffect(() => {
        const fetchData = async () => {

            const signedUrl = await Storage.get(image.key, {
                level: 'private', // defaults to `public`
                // identityId?: string, // id of another user, if `level: protected`
                // download?: boolean, // defaults to false
                expires: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes)
                contentType: 'image/*', // set return content type, eg "text/html"
                validateObjectExistence: true, // defaults to false
                // cacheControl?: string, // Specifies caching behavior along the request/reply chain
            });

            // console.log(signedUrl)
            setSignedUrl((prev) => signedUrl);
        };

        fetchData();
    }, []);

    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" };

    const truncateString = (str, num = 10) => {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str.length <= num) {
            return str
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, num) + '...'
    }



    return (
        <Card>
            <Image src={signedUrl} wrapped ui={false} />
            <Card.Content>
                <Card.Description padded>
                    {`File: ${image.key.split('/')[2].substring(0, 27)}`}
                </Card.Description>
                <Card.Description>
                    {`Last Modified: ${image.lastModified.toLocaleDateString("en-US", optionsDate)} - ${image.lastModified.toLocaleTimeString("en-US", optionsTime)}`}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <div className='ui two buttons'>
                    <Button
                        basic
                        color={'grey'}
                        onClick={() => copyUrl(image)}
                    >
                        <Icon name='copy' />
                        Copy Url
                    </Button>

                    <Button
                        basic
                        color={'red'}
                        onClick={() => deleteImage(image)}
                    >
                        <Icon name='remove circle left' />
                        Delete
                    </Button>
                </div>
            </Card.Content>
        </Card>
    )
}

export default ImageCard