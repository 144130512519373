import PlaceholderComponent from './PlaceholderComponent'
import React, {useEffect, useRef, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {Button, Container, Divider, Icon, Segment, Form, Loader, Header, Image} from 'semantic-ui-react';
import {useEditTestMutation, useGetFullTestQuery} from '../store/testsSlice';
import {Amplify, Auth, Storage} from 'aws-amplify';
import TEST_TYPE from '../constants/testType';
import {useForm} from 'react-hook-form';
import ImageGalleryModal from './ImageGalleryModal';
import HookFormControlledDropdown from './HookFormControlledDropdown';
import HookFormControlledField from './HookFormControlledField';
import HookFormControlledDatalist from './HookFormControlledDatalist';
import TAGS from '../constants/tags';
import HookFormControlledImagePicker from './HookFormControlledImagePicker';
import HookFormControlledHtmlEditor from './HookFormControlledHtmlEditor';
import ButtonSave from './ButtonSave';
import Swal from "sweetalert2";

const InstructionsEditor = ({testId}) => {

    const {
        data: test,
        isLoading: isTestLoading,
        isFetching: isTestFetching,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);

    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestMutation();
    const [modalState, setModalState] = useState({isOpen: false, dispatchProps: {}});

    const {
        handleSubmit, getValues,
        control, watch, setValue,
        formState: {isDirty, errors, isValid}
    } = useForm({
        defaultValues: structuredClone(test?.['config']),
        shouldUnregister: true,
    })

    const watchImage = watch("instructions_image")

    const handleOnFormSubmit = ({questions, ...data}) => {


        const body = {
            config: data,
            testId,
            scope: 'config'
        }

        // console.log('body:', body)

        editTest(body).unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Update saved`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => console.log(err));
    }


    return (

        <Container>

            {modalState?.isOpen &&
                <ImageGalleryModal
                    testId={testId}
                    modalState={modalState}
                    setModalState={setModalState}
                    setValue={(field, value) => setValue(field, value)}
                />}


            <Form
                onSubmit={handleSubmit(handleOnFormSubmit)}
                loading={isTestFetching}
                widths={'equal'}
            >

                <HookFormControlledDropdown
                    name={'category'}
                    options={TEST_TYPE}
                    label={'Select Test Category'}
                    control={control}
                />

                <Form.Field>
                    <label>{'Number of Questions (Edit in Questions Tab)'}</label>
                    <Form.Input
                        value={test?.questions?.length}
                        disabled={true}
                    />
                </Form.Field>

                <HookFormControlledDatalist
                    name={'tags'}
                    control={control}
                    label={'Select Tags (Multi-select allowed)'}
                    options={TAGS}
                />

                <HookFormControlledField
                    name={'time_limit'}
                    control={control}
                    label={'Recommended Time Limit (in minutes)'}
                    type={'number'}
                />

                <HookFormControlledHtmlEditor
                    label={'Test Instructions'}
                    name={'instructions'}
                    control={control}
                />

                {watchImage &&
                    <Image
                        wrapped
                        ui={false}
                        size={'large'} src={watchImage}
                    />
                }

                <HookFormControlledImagePicker
                    name={'instructions_image'}
                    setModalState={setModalState}
                    control={control}
                />

                <Divider hidden/>

                <ButtonSave
                    type={'submit'}
                    color={'green'}
                    isDisabled={editTestIsLoading || !isDirty}
                    isLoading={editTestIsLoading}
                    label={'Save Answer Explanation Changes'}
                    isError={editTestIsError || Object.keys(errors).length !== 0}
                    error={{...editTestError, ...errors}}
                />
            </Form>

        </Container>


    )
}

export default InstructionsEditor
