// src/components/Flashcard.js
import React, { useState, useEffect } from 'react';
import Flashcard from './Flashcard';
import { Container, Icon } from 'semantic-ui-react';
import shuffle, { randomize } from '../utils/shuffle';

const FlashcardDeck = ({ flashcards = [{ front: 'Error: No Flashcard', back: 'Error: No Flashcard' }], autoPlay = false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [flashcardsArray, setFlashcardsArray] = useState(flashcards);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcardsArray.length);
      }
    }, 1000); // Auto-play interval (adjust as needed)

    return () => clearInterval(interval);
  }, [isPlaying, flashcardsArray]);

  const toggleAutoPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const shuffleFlashcards = () => {
    // Implement shuffle logic here (e.g., using Fisher-Yates algorithm)
    // Set the shuffled flashcards to state
    const shuffledFlashcards = shuffle(flashcardsArray);
    setFlashcardsArray(shuffledFlashcards);
  };

  const markFavorite = (index) => {
    // Implement marking favorites logic (i.e., add a property to the flashcard)
    // Favorite state only maintained for the duration of the session
    // Update the flashcards array with 'isMarked' property
    const newFlashcardsArray = flashcardsArray.map((flashcard, idx) => {
      if (index === idx) {

        return {
          ...flashcard,
          isMarked: flashcard?.isMarked ? false : true,
        }
      } else {
        return flashcard;
      }

    })

    setFlashcardsArray(newFlashcardsArray);
  };

  const moveForward = (index) => {
    if (index + 1 < flashcardsArray.length) {
      setCurrentIndex((prev) => prev + 1);
    }
  }

  const moveBackward = (index) => {
    if (index > 0) {

      setCurrentIndex((prev) => {
        if (prev - 1 >= flashcardsArray.length) {
          return flashcardsArray.length - 1;
        } else {
          return prev - 1;
        }
      })
    }
  }

  return (
    <div
      // className="flex flex-col justify-center items-center container mx-auto w-full my-4 lg:w-[714px] lg:max-w-3xl md:w-[600px] md:max-w-xl sm:w-[210px] sm:max-w-md"
      className="flashcarddeck flex flex-col justify-center items-center mx-auto w-max h-full my-4"
    >
      <div
      //  className="w-full h-full px-2"
      //  className='w-max'
      //  sm:h-80 md:h-96 lg:h-96 sm:text-xl md:text-2xl lg:text-2xl'
      >
        <Flashcard
          {...flashcardsArray[currentIndex]}
          index={currentIndex}
          onMark={markFavorite}
          timerDuration={0}
          next={null}
        />
      </div>
      <div className="w-full flex justify-between my-4">

        <div className="flex justify-start">
          <button onClick={toggleAutoPlay} className={'mx-2'} disabled>
            {isPlaying ? <Icon name={'pause'} size={'large'} /> : <Icon disabled name={'play'} size={'large'} />}
          </button>
          <button onClick={shuffleFlashcards} className={'mx-2'}>
            <Icon name={'shuffle'} size={'large'} />
          </button>
          <button onClick={() => markFavorite(currentIndex)} className={'mx-2'}>
            <Icon name={'star'} color={flashcardsArray?.[currentIndex]?.isMarked ? 'yellow' : 'black'} size={'large'} />
          </button>
        </div>

        <div className="flex justify-center">
          <button onClick={() => moveBackward(currentIndex)} disabled={currentIndex <= 0}>
            <Icon name={'chevron circle left'}
              disabled={currentIndex <= 0}
              size={'big'} />
          </button>

          <span className='mx-2 text-lg'>
            {currentIndex + 1} / {flashcards.length}
          </span>

          <button onClick={() => moveForward(currentIndex)}  disabled={currentIndex + 1 >= flashcardsArray.length}>
            <Icon name={'chevron circle right'}
              disabled={currentIndex + 1 >= flashcardsArray.length}
              size={'big'} />
          </button>
        </div>

        <div className="flex justify-end">

          <button onClick={() => console.log('Not yet implemented')} className={'mx-2'}>
            <Icon disabled={true} name={'setting'} size={'large'} />
          </button>
          <button onClick={() => console.log('Not yet implemented')} className={'mx-2'}>
            <Icon disabled={true} name={'expand'} size={'large'} />
          </button>
        </div>

      </div>
    </div >
  );
};

export default FlashcardDeck;
