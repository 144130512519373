import React from 'react'
import {NavLink, Navigate, Outlet, useLocation} from 'react-router-dom';
import {Container, Menu, Header, Icon} from 'semantic-ui-react';
import {USER_TEST_SECTIONS} from "../constants/userTestSections";

export const UserTestLayout = () => {

    const location = useLocation()
    // console.log('location', location)

    return (
        <Container style={{marginTop: '2rem', marginBottom: '2rem'}}>

            <Header as='h2'>
                <NavLink as={NavLink} to={'.'}>
                    <Icon color={'black'} name='settings'/>
                </NavLink>
                <Header.Content>
                    Take the Test
                    <Header.Subheader>
                        Start the test in practice or test mode, and submit to see the results
                    </Header.Subheader>
                </Header.Content>
            </Header>

            <Container>

                <Menu pointing stackable>

                    {Object.entries(USER_TEST_SECTIONS)
                        .map(([key, value]) => {
                            return (
                                <Menu.Item
                                    as={NavLink}
                                    name={value.text}
                                    to={value.value}
                                    key={key}
                                />
                            );
                        })}

                </Menu>
            </Container>

            <Outlet/>

        </Container>
    )
}

export default UserTestLayout
