import React, {useReducer, useEffect} from 'react';
import {Container,} from 'semantic-ui-react';
import {useParams} from "react-router-dom";
import Overview from './Overview'
import OverviewEditor from './OverviewEditor';
import Card from './Card';
import CardEditor from './CardEditor'
import InstructionsEditor from './InstructionsEditor';
import Scoring from './Scoring';
import ScoringEditor from './ScoringEditor';
import QuestionsViewer from './QuestionsViewer';
import ImageGallery from './ImageGallery';
import ImageUploader from './ImageUploader';
import InstructionsView from './InstructionsView';
import QuestionsDragAndDrop from './QuestionsDragAndDrop';
import Submit from "./Submit";
import {ADMIN_EDIT_TEST_SECTIONS} from '../constants/adminEditTestSections';
import AdminApprove from './AdminApprove';
import AdminEditable from './AdminEditable';
import AdminPublish from "./AdminPublish";


const reducer = (state, action) => {
    // console.log(action)

    switch (action.type) {
        case ADMIN_EDIT_TEST_SECTIONS.OVERVIEW.value: {
            // TODO add test data
            const DisplayComponent = (props) => <Overview  {...props} testId={action.payload}/>
            const EditComponent = (props) => <  OverviewEditor {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                EditComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.CARD.value: {
            const DisplayComponent = (props) => <Card  {...props} testId={action.payload}/>
            const EditComponent = (props) => <  CardEditor {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                EditComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.INSTRUCTIONS.value: {
            const DisplayComponent = (props) => <InstructionsView  {...props} testId={action.payload}/>
            const EditComponent = (props) => <InstructionsEditor {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                EditComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.SCORING.value: {
            const DisplayComponent = (props) => <Scoring  {...props} testId={action.payload}/>
            const EditComponent = (props) => <  ScoringEditor {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                EditComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.QUESTIONS.value: {
            const DisplayComponent = (props) => <QuestionsViewer  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value: {
            const DisplayComponent = (props) => <QuestionsDragAndDrop  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.IMAGES.value: {
            const DisplayComponent = (props) => <ImageGallery  {...props} testId={action.payload}/>
            const EditComponent = (props) => <ImageUploader {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                EditComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.APPROVE.value: {
            const DisplayComponent = (props) => <AdminApprove  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value: {
            const DisplayComponent = (props) => <AdminPublish  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
    }
    throw Error('Unknown action: ' + action.type);

}


const AdminTestEditForm = ({section}) => {
    const {testId} = useParams();

    const [editableContent, editableContentDispatch] = useReducer(
        reducer,
        // initialArg
        {type: section, payload: testId},
        // Initilizer Function, gets called with initialArg
        (initialState) => reducer(null, initialState)
    );

    const handleItemClick = (e, {name}) => {

        switch (name) {
            case ADMIN_EDIT_TEST_SECTIONS.OVERVIEW.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.OVERVIEW.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.OVERVIEW.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.CARD.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.CARD.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.CARD.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.INSTRUCTIONS.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.INSTRUCTIONS.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.INSTRUCTIONS.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.SCORING.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.SCORING.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.SCORING.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.QUESTIONS.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.QUESTIONS.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.QUESTIONS.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.IMAGES.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.IMAGES.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.IMAGES.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.APPROVE.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.APPROVE.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.SUBMIT.value);
                break;
            case ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value:
                editableContentDispatch(({type: ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value, payload: testId}))
                // setActiveItem(EDIT_TEST_SECTIONS.SUBMIT.value);
                break;
            default:
                console.log(`Error : '${name}' menu item not recognized`)
                return;
        }

    }
    useEffect(() => {
        handleItemClick(null, {name: section})
    }, [section]);

    return (
        <Container style={{marginTop: '2rem', marginBottom: '2rem'}}>

            <AdminEditable {...editableContent} />

        </Container>
    );
}

export default AdminTestEditForm
