import React, {useState} from 'react'
import HookFormControlledDropdown from "./HookFormControlledDropdown";
import {STATUS_OPTIONS} from "../constants/statusOptions";
import {useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Form} from "semantic-ui-react";
import ButtonSave from "./ButtonSave";
import {VERSION_OPTIONS} from "../constants/versionOptions";
import HookFormControlledDatalist from "./HookFormControlledDatalist";


const AdminSearchForm = ({isLoading = false, setSearchParams}) => {
    const navigate = useNavigate();

    const {
        handleSubmit, getValues,
        control, watch, setValue,
        formState: {isDirty}
    } = useForm({
        defaultValues: {
            status: '',
        }
    });

    const handleOnFormSubmit = (data) => {
        // console.log('data', data)
        const obj = {};
        for (const key in data) {
            if (data[key]) {
                obj[key] = data[key];
            }
        }
        const searchQuery = new URLSearchParams({...obj}).toString();
        setSearchParams(searchQuery)
        navigate(`?${searchQuery}`);
    }

    return (
        <Form
            onSubmit={handleSubmit(handleOnFormSubmit)}
        >
            <HookFormControlledDropdown
                options={STATUS_OPTIONS}
                label={'Select Test Status'}
                name={'publish_status'}
                control={control}
                disabled={false}
            />

            <HookFormControlledDropdown
                options={VERSION_OPTIONS}
                label={'Select Version'}
                name={'version'}
                control={control}
                // type={'text'}
                disabled={false}
            />

            <ButtonSave
                type={'submit'}
                color={'blue'}
                isDisabled={false}
                isLoading={isLoading}
                label={'Search'}
                isError={false}
                error={''}
                icon='search'
            />

        </Form>
    );

}

export default AdminSearchForm;