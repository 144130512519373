export const STATUS_OPTIONS = [
    {
        key: 'draft',
        text: 'Draft',
        value: 'draft'
    },
    {
        key: 'in-review',
        text: 'In-Review',
        value: 'in-review'
    },
    {
        key: 'approved',
        text: 'Approved',
        value: 'approved'
    },
    {
        key: 'rejected',
        text: 'Rejected',
        value: 'rejected'
    },
    {
        key: 'published',
        text: 'Published',
        value: 'published'
    },
    {
        key: 'archived',
        text: 'Archived',
        value: 'archived'
    },
];

const [DRAFT, INREVIEW, APPROVED, REJECTED, PUBLISHED, ARCHIVED] =
    ['draft', 'in-review', 'approved', 'rejected', 'published', 'archived'];

export const STATUS_OPTIONS_MAP = {
    DRAFT: {
        key: DRAFT,
        text: 'Draft',
        value: DRAFT,
    },
    INREVIEW: {
        key: INREVIEW,
        text: 'In-Review',
        value: INREVIEW
    },
    APPROVED: {
        key: APPROVED,
        text: 'Approved',
        value: APPROVED,
    },
    REJECTED: {
        key: REJECTED,
        text: 'Rejected',
        value: REJECTED,
    },
    PUBLISHED: {
        key: PUBLISHED,
        text: 'Published',
        value: PUBLISHED,
    },
    ARCHIVED: {
        key: ARCHIVED,
        text: 'Archived',
        value: ARCHIVED,
    },
};
export const STATUS_OPTIONS_LOOKUP = {
    draft: 'Draft',
    'in-review': 'In-Review',
    approved: 'Approved',
    rejected: 'Rejected',
    published: 'Published',
    archived: 'Archived'
};

export const STATUS_OPTIONS_COLOR = {
    draft: 'grey',
    'in-review': 'violet',
    approved: 'blue',
    rejected: 'red',
    published: 'green',
    archived: 'black',
};

export const SESSION_STATUS_OPTIONS = {
    CREATED: 'Created',
    STARTED: 'Started',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    PAUSED: 'Paused',
    UNPAUSED: 'Unpaused',
}