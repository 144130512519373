import React, {useEffect, useRef, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {
    Button,
    Input,
    Container,
    Divider,
    Icon,
    Segment,
    Form,
    Loader,
    Header,
    Image,
    Grid,
    Table
} from 'semantic-ui-react';
import {useEditTestMutation, useGetFullTestQuery} from '../store/testsSlice';
import Swal from "sweetalert2";
import HookFormControlledHtmlEditor from "./HookFormControlledHtmlEditor";
import HookFormControlledField from "./HookFormControlledField";
import ButtonSave from "./ButtonSave";
import HookFormControlledImagePicker from "./HookFormControlledImagePicker";
import ImageGalleryModal from "./ImageGalleryModal";
import {LAYOUT_VALUE as LV} from "../constants/layouts";
import QuestionOptionEditorUncontrolled from "./QuestionOptionEditorUncontrolled";


const OverviewEditor = ({testId, setEditMode}) => {

    // TODO introduce state aattribute to disable Save button if nothing has yet changed
    const {
        data: test,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);

    const [editTestTitles, {
        data: editTestDataTitles,
        error: editTestErrorTitles,
        isLoading: editTestIsLoadingTitles,
        isSuccess: editTestIsSuccessTitles,
        isError: editTestIsErrorTitles
    }] = useEditTestMutation();

    const [editTestOverview, {
        data: editTestDataOverview,
        error: editTestErrorOverview,
        isLoading: editTestIsLoadingOverview,
        isSuccess: editTestIsSuccessOverview,
        isError: editTestIsErrorOverview
    }] = useEditTestMutation();


    const {
        register, handleSubmit, control, setValue,
        watch, formState: {isDirty, isValid, errors}
    } =
        useForm({
                values: {...structuredClone(test?.['product_summary'])},
                shouldUnregister: true,
            }
        )

    const {fields, append, remove, move} = useFieldArray({
        control,
        name: "sections",
        shouldUnregister: true,
    });

    const [modalState, setModalState] = useState({isOpen: false, dispatchProps: {}});

    const watchImage = watch("video_placeholder")
    const watchImage2 = watch("image")

    const onError = (errors, e) => console.log(errors, e);
    const editorRef = useRef(null);


    const handleOnFormSubmit = (data) => {

        const body = {
            product_summary: {...data},
            testId,
            scope: 'productSummary',
        }

        editTestTitles(body)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Update saved`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => console.log(err))
    }

    return (
        <Container>

            {modalState?.isOpen &&
                <ImageGalleryModal
                    testId={testId}
                    modalState={modalState}
                    setModalState={setModalState}
                    setValue={(field, value) => setValue(field, value)}
                />}

            <Form
                onSubmit={handleSubmit(handleOnFormSubmit, onError)}
                loading={isTestLoading}
                widths={'equal'}
            >

                <HookFormControlledField
                    name={'title'}
                    control={control}
                    label={'Title (Required)'}
                    placeholder={'Enter Test Title'}
                    required={true}
                />

                <HookFormControlledField
                    name={'subtitle'}
                    control={control}
                    label={'Subtitle (Required)'}
                    placeholder={'Enter Test Subtitle'}
                    required={true}
                />

                <Image size={'small'} src={watchImage2}/>

                <HookFormControlledImagePicker
                    name={'image'}
                    setModalState={setModalState}
                    control={control}
                    required={false}
                    label={'Description Image (Optional)'}
                />

                <HookFormControlledField
                    name={'video'}
                    control={control}
                    label={'Video Link (Optional)'}
                    placeholder={'Enter Test Video Link (i.e. YouTube)'}
                    required={false}
                />

                <Image size={'small'} src={watchImage}/>

                <HookFormControlledImagePicker
                    name={'video_placeholder'}
                    setModalState={setModalState}
                    control={control}
                    required={false}
                    label={'Video Placeholder Image (Optional)'}
                />

                <HookFormControlledField
                    name={'question_count'}
                    control={control}
                    label={'Question Count | Duration (mins)'}
                    placeholder={'Enter Question Count | Duration (mins)'}
                    required={true}
                />

                <div>
                    {
                        // fields.length > 0 &&
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Section</Table.HeaderCell>
                                    <Table.HeaderCell>Question Count</Table.HeaderCell>
                                    <Table.HeaderCell>Delete</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    fields.map((section, index) => {
                                        return (
                                            <Table.Row
                                                key={section.id}
                                            >
                                                <Table.Cell>
                                                    <input {...register(`sections.${index}.title`)} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <input {...register(`sections.${index}.question_count`)} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            remove(index)
                                                        }}>Delete</Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    }

                    <Button
                        secondary
                        onClick={(e) => {
                            e.preventDefault();
                            append({title: '', question_count: ''});
                        }}
                    >
                        Add Section
                    </Button>
                </div>


                <Divider/>

                <ButtonSave
                    type={'submit'}
                    color={'green'}
                    isDisabled={editTestIsLoadingOverview
                        // || !isDirty
                    }
                    isLoading={editTestIsLoadingOverview}
                    label={'Save Overview Changes'}
                    isError={editTestIsErrorOverview || Object.keys(errors).length !== 0}
                    error={{...editTestErrorOverview, ...errors}}
                />

                <Divider/>

                <HookFormControlledHtmlEditor
                    label={'Overview'}
                    name={'overview'}
                    control={control}
                />


                <Divider/>

                <ButtonSave
                    type={'submit'}
                    color={'green'}
                    isDisabled={editTestIsLoadingOverview
                        // || !isDirty
                    }
                    isLoading={editTestIsLoadingOverview}
                    label={'Save Overview Changes'}
                    isError={editTestIsErrorOverview || Object.keys(errors).length !== 0}
                    error={{...editTestErrorOverview, ...errors}}
                />

            </Form>

        </Container>

    )
        ;
}

export default OverviewEditor
