import React, {useState, useEffect} from 'react';
import {
    Container,
    Header,
    Divider,
    Table,
    Icon,
    Image,
    Button,
    Progress,
    Grid,
    Segment,
    Label,
    Card
} from 'semantic-ui-react'
import {alphabet} from '../constants'
import {LAYOUT_VALUE as LV, QUESTION_FORMAT as QF} from '../constants/layouts';
import DOMPurify from "dompurify";

// Controlled Component
const QuestionViewer = ({question, questionCount, questionIndex}) => {


    const answerHtml = question?.answer?.answer_text ?? '';
    const safeAnswerHtml = DOMPurify.sanitize(answerHtml);

    const questionHtml = question?.question_text ?? '';
    // const safeQuestionHtml = DOMPurify.sanitize(questionHtml);
    const safeQuestionHtml = questionHtml;

    const lengthH2Limit = 60;
    const lengthH3Limit = 120;
    const formatSafeQuestionHtml = safeQuestionHtml.length <= lengthH2Limit
        ? QF.H2
        : safeQuestionHtml.length <= lengthH3Limit
            ? QF.H3 :
            QF.HTML;


    return (
        <Container>
            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard'/>
                    Question Metadata
                </Header>
            </Divider>

            <Table definition>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>Question Id</Table.Cell>
                        <Table.Cell>{question?.question_id}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Questions Label</Table.Cell>
                        <Table.Cell>{question?.label}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Question Type</Table.Cell>
                        <Table.Cell>{question?.type}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Question Difficulty</Table.Cell>
                        <Table.Cell>{question?.difficulty}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Question Score</Table.Cell>
                        <Table.Cell>{question?.score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Layout</Table.Cell>
                        <Table.Cell>{question?.layout}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Divider horizontal>
                <Header as='h4'>
                    <Segment.Inline>
                        <Icon name='check circle' color='black'/>
                        Question {questionIndex + 1} / {questionCount} ({Math.max(100, ((questionIndex + 1) / (questionCount) * 100).toFixed(0))}%)
                    </Segment.Inline>
                </Header>
            </Divider>

            <Container className='border-dotted border-2 border-grey-600 py-8 px-4 '>

                <Container fluid>

                    <Grid
                        columns={LV[question?.layout]?.['grid_cols'] ?? LV['compact']?.['grid_cols']}
                        stackable
                    >
                        <Grid.Column
                            key={'abc1'}
                            width={LV[question?.layout]?.['question_col'] ?? LV['compact']?.['question_col']}
                        >

                            {
                                formatSafeQuestionHtml === QF.H2
                                    ? (
                                        <Header as={'h2'}>
                                            <Container dangerouslySetInnerHTML={{__html: safeQuestionHtml}}></Container>
                                        </Header>
                                    )
                                    : formatSafeQuestionHtml === QF.H3
                                        ? (
                                            <Header as={'h3'}>
                                                <Container dangerouslySetInnerHTML={{__html: safeQuestionHtml}}></Container>
                                            </Header>
                                        )
                                        : <Container dangerouslySetInnerHTML={{__html: safeQuestionHtml}}/>
                            }

                            <Image src={question?.question_image}/>
                        </Grid.Column>

                        <Grid.Column
                            key={'abc2'}
                            width={LV[question?.layout]?.['answers_col'] ?? LV['compact']?.['answers_col']}
                        >
                            {question?.difficulty && <Label attached='bottom right'>{question.difficulty}</Label>}
                            <Grid
                                columns={LV[question?.layout]?.['cols_per_answer_col'] ?? LV['compact']?.['cols_per_answer_col']}
                                stackable
                            >
                                {/*TODO: Instead of options, add a list of <input> components to capture text or number input for text_input type of questions*/}
                                {/*TODO: Add number of choices to be made and validate*/}
                                {question?.options?.map(({
                                                             option_id,
                                                             option_text,
                                                             option_image,
                                                             option_header
                                                         }, index) => {
                                    return (
                                        <Grid.Column
                                            key={option_id}
                                            width={LV[question?.layout]?.['answer_subCol'] ?? LV['compact']?.['answer_subCol']}
                                        >
                                            <Card
                                                fluid
                                                key={option_id}
                                                // raised
                                                style={
                                                    // (
                                                    //     question?.answer?.answer_id === option_id ||
                                                    (
                                                        question?.answer?.values &&
                                                        question?.answer?.values?.some((elem) => elem.value === option_id)
                                                    )
                                                        // )
                                                        ?
                                                        {
                                                            cursor: 'pointer',
                                                            outlineColor: '#4caf50',
                                                            outlineStyle: 'solid',
                                                            outlineRadius: '6px',
                                                            outlineWidth: 'thick',
                                                            boxShadow: '0 0 13px 10px #a5d6a7'
                                                        }
                                                        : {
                                                            cursor: 'pointer'
                                                        }
                                                }
                                            >

                                                <Card.Content>
                                                    <Card.Header>
                                                        {`${alphabet(index)}. `}
                                                        {option_header && `${option_header}`}
                                                    </Card.Header>

                                                    {
                                                        option_text &&
                                                        <Card.Description>
                                                            {option_text}
                                                        </Card.Description>
                                                    }


                                                </Card.Content>

                                                {option_image &&
                                                    <Image
                                                        wrapped ui={false}
                                                        size={'large'} src={option_image}
                                                    />
                                                }

                                            </Card>

                                        </Grid.Column>
                                    )
                                })}

                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Container>


            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard'/>
                    Answer Explanation
                </Header>
            </Divider>


            <Container className='border-dotted border-2 border-grey-600 py-8'>

                {
                    safeAnswerHtml &&
                    <Container
                        style={{marginTop: '2rem', marginBottom: '2rem'}}
                        text
                        dangerouslySetInnerHTML={{__html: safeAnswerHtml}}
                    >

                    </Container>
                }
                {
                    question?.answer?.answer_image &&
                    <Container textAlign={'center'}>
                        <Image size={'massive'} src={question?.answer?.answer_image} verticalAlign='middle' bordered
                               className='py-8 px-4'/>
                    </Container>

                }
            </Container>


        </Container>

    )
}

export default QuestionViewer
