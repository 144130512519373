export const QUESTION_TYPE = {
  multiple_choice: {
    key: 'multiple_choice',
    text: 'Multiple Choice',
    value: 'multiple_choice',
    length: 1,
  },
  // Deprecated
  multiple: {
    key: 'multiple',
    text: 'Multiple Choice',
    value: 'multiple',
    length: 1,
  },
  multiple_answer_1: {
    key: 'multiple',
    text: '1-Answer Question',
    value: 'multiple',
    length: 1,
  },

  multiple_answer_2: {
    key: 'multiple_answer_2',
    text: '2-Answer Question',
    value: 'multiple_answer_2',
    length: 2,
  },

  multiple_answer_3: {
    key: 'multiple_answer_3',
    text: '3-Answer Question',
    value: 'multiple_answer_3',
    length: 3,
  },
  multiple_answer_4: {
    key: 'multiple_answer_4',
    text: '4-Answer Question',
    value: 'multiple_answer_4',
    length: 4,
  },
  multiple_answer_5: {
    key: 'multiple_answer_5',
    text: '5-Answer Question',
    value: 'multiple_answer_5',
    length: 5,
  },
  // NUMBER_INPUT_1: {
  //   key: 'number_input_1',
  //   text: '1-Number Input',
  //   value: 'number_input_1',
  //   length: 1,
  // },
  // NUMBER_INPUT_2: {
  //   key: 'number_input_2',
  //   text: '2-Number Input',
  //   value: 'number_input_2',
  //   length: 2,
  // },
  // NUMBER_INPUT_3: {
  //   key: 'number_input_3',
  //   text: '3-Number Input',
  //   value: 'number_input_3',
  //   length: 3,
  // },
  // NUMBER_INPUT_4: {
  //   key: 'number_input_4',
  //   text: '4-Number Input',
  //   value: 'number_input_4',
  //   length: 4,
  // },
  // NUMBER_INPUT_5: {
  //   key: 'number_input_5',
  //   text: '5-Number Input',
  //   value: 'number_input_5',
  //   length: 5,
  // },
  // TEXT_INPUT_1: {
  //   key: 'text_input_1',
  //   text: '1-Text Input (Case Insensitive)',
  //   value: 'text_input_1',
  //   length: 1,
  // },
  // TEXT_INPUT_2: {
  //   key: 'text_input_2',
  //   text: '2-Text Input (Case Insensitive)',
  //   value: 'text_input_2',
  //   length: 2,
  // },
  // TEXT_INPUT_3: {
  //   key: 'text_input_3',
  //   text: '3-Text Input (Case Insensitive)',
  //   value: 'text_input_3',
  //   length: 3,
  // },
  // TEXT_INPUT_4: {
  //   key: 'text_input_4',
  //   text: '4-Text Input (Case Insensitive)',
  //   value: 'text_input_4',
  //   length: 4,
  // },
  // TEXT_INPUT_5: {
  //   key: 'text_input_5',
  //   text: '5-Text Input (Case Insensitive)',
  //   value: 'text_input_5',
  //   length: 5,
  // },
  // ORDER: {
  //   key: 'order',
  //   text: 'Correct Order',
  //   value: 'order',
  // },
  // TRUE_FALSE: {
  //   key: 'boolean',
  //   text: 'True / False',
  //   value: 'boolean',
  // }
}


export const QUESTIONS_TYPE_LIST = [
  {
    key: 'multiple_choice',
    text: 'Multiple Choice',
    value: 'multiple_choice',
    length: 1,
  },
  {
    key: 'multiple_answer_1',
    text: '1-Answer Question',
    value: 'multiple_answer_1',
    length: 1,
  },
  {
    key: 'multiple_answer_2',
    text: '2-Answer Question',
    value: 'multiple_answer_2',
    length: 2,
  },
  {
    key: 'multiple_answer_3',
    text: '3-Answer Question',
    value: 'multiple_answer_3',
    length: 3,
  },
  {
    key: 'multiple_answer_4',
    text: '4-Answer Question',
    value: 'multiple_answer_4',
    length: 4,
  },
  {
    key: 'multiple_answer_5',
    text: '5-Answer Question',
    value: 'multiple_answer_5',
    length: 5,
  },
  // {
  //   key: 'number_input_1',
  //   text: '1-Number Input',
  //   value: 'number_input_1',
  //   length: 1,
  // },
  // {
  //   key: 'number_input_2',
  //   text: '2-Number Input',
  //   value: 'number_input_2',
  //   length: 2,
  // },
  // {
  //   key: 'number_input_3',
  //   text: '3-Number Input',
  //   value: 'number_input_3',
  //   length: 3,
  // },
  // {
  //   key: 'number_input_4',
  //   text: '4-Number Input',
  //   value: 'number_input_4',
  //   length: 4,
  // },
  // {
  //   key: 'number_input_5',
  //   text: '5-Number Input',
  //   value: 'number_input_5',
  //   length: 5,
  // },
  // {
  //   key: 'text_input_1',
  //   text: '1-Text Input (Case Insensitive)',
  //   value: 'text_input_1',
  //   length: 1,
  // },
  // {
  //   key: 'text_input_2',
  //   text: '2-Text Input (Case Insensitive)',
  //   value: 'text_input_2',
  //   length: 2,
  // },
  // {
  //   key: 'text_input_3',
  //   text: '3-Text Input (Case Insensitive)',
  //   value: 'text_input_3',
  //   length: 3,
  // },
  // {
  //   key: 'text_input_4',
  //   text: '4-Text Input (Case Insensitive)',
  //   value: 'text_input_4',
  //   length: 4,
  // },
  // {
  //   key: 'text_input_5',
  //   text: '5-Text Input (Case Insensitive)',
  //   value: 'text_input_5',
  //   length: 5,
  // },
  // {
  //   key: 'order',
  //   text: 'Correct Order',
  //   value: 'order',
  // },
  //
  // {
  //   key: 'boolean',
  //   text: 'True / False',
  //   value: 'boolean',
  //   length: 1,
  // }
];


// const QUESTIONS_TYPE = [
//   {
//     key: 'multiple_choice',
//     text: 'Multiple Choice',
//     value: 'multiple_choice',
//   },
//   {
//     key: 'multiple_answer_1',
//     text: '1-Answer Question',
//     value: 'multiple_answer_1',
//   },
//   {
//     key: 'multiple_answer_2',
//     text: '2-Answer Question',
//     value: 'multiple_answer_2',
//   },
//   {
//     key: 'multiple_answer_3',
//     text: '3-Answer Question',
//     value: 'multiple_answer_3',
//   },
//   {
//     key: 'multiple_answer_4',
//     text: '4-Answer Question',
//     value: 'multiple_answer_4',
//   },
//   {
//     key: 'multiple_answer_5',
//     text: '5-Answer Question',
//     value: 'multiple_answer_5',
//   },
//   {
//     key: 'number_input_1',
//     text: '1-Number Input',
//     value: 'number_input_1',
//   },
//   {
//     key: 'number_input_2',
//     text: '2-Number Input',
//     value: 'number_input_2',
//   },
//   {
//     key: 'number_input_3',
//     text: '3-Number Input',
//     value: 'number_input_3',
//   },
//   {
//     key: 'number_input_4',
//     text: '4-Number Input',
//     value: 'number_input_4',
//   },
//   {
//     key: 'number_input_5',
//     text: '5-Number Input',
//     value: 'number_input_5',
//   },
//   {
//     key: 'text_input_1',
//     text: '1-Text Input (Case Insensitive)',
//     value: 'text_input_1',
//   },
//   {
//     key: 'text_input_2',
//     text: '2-Text Input (Case Insensitive)',
//     value: 'text_input_2',
//   },
//   {
//     key: 'text_input_3',
//     text: '3-Text Input (Case Insensitive)',
//     value: 'text_input_3',
//   },
//   {
//     key: 'text_input_4',
//     text: '4-Text Input (Case Insensitive)',
//     value: 'text_input_4',
//   },
//   {
//     key: 'text_input_5',
//     text: '5-Text Input (Case Insensitive)',
//     value: 'text_input_5',
//   },
//   {
//     key: 'order',
//     text: 'Correct Order',
//     value: 'order',
//   },
//
//   {
//     key: 'boolean',
//     text: 'True / False',
//     value: 'boolean',
//   }
// ];
//
// export default QUESTIONS_TYPE;
