const CATEGORIES = [
    {
        key: 'aws',
        text: 'AWS Certification',
        value: 'aws'
    }, {
        key: 'iq',
        text: 'IQ General',
        value: 'iq'
    },
    // {
    //   key: 9,
    //   text: 'General Knowledge',
    //   value: 9
    // },
    // {
    //   key: 10,
    //   text: 'Entertainment: Books',
    //   value: 10
    // },
    // {
    //   key: 11,
    //   text: 'Entertainment: Films',
    //   value: 11
    // },
    // {
    //   key: 12,
    //   text: 'Entertainment: Music',
    //   value: 12
    // },
    // {
    //   key: 13,
    //   text: 'Entertainment: Musicals & Theatres',
    //   value: 13
    // },
    // {
    //   key: 14,
    //   text: 'Entertainment: Television',
    //   value: 14
    // },
    // {
    //   key: 15,
    //   text: 'Entertainment: Video Games',
    //   value: 15
    // },
    // {
    //   key: 16,
    //   text: 'Entertainment: Board Games',
    //   value: 16
    // },
    // {
    //   key: 17,
    //   text: 'Science & Nature',
    //   value: 17
    // },
    // {
    //   key: 18,
    //   text: 'Science: Computers',
    //   value: 18
    // },
    // {
    //   key: 19,
    //   text: 'Science: Mathematics',
    //   value: 19
    // },
    // {
    //   key: 20,
    //   text: 'Mythology',
    //   value: 20
    // },
    // {
    //   key: 21,
    //   text: 'Sports',
    //   value: 21
    // },
    // {
    //   key: 22,
    //   text: 'Geography',
    //   value: 22
    // },
    // {
    //   key: 23,
    //   text: 'History',
    //   value: 23
    // },
    // {
    //   key: 24,
    //   text: 'Politics',
    //   value: 24
    // },
    // {
    //   key: 25,
    //   text: 'Arts',
    //   value: 25
    // },
    // {
    //   key: 26,
    //   text: 'Celebrities',
    //   value: 26
    // },
    // {
    //   key: 27,
    //   text: 'Animals',
    //   value: 27
    // },
    // {
    //   key: 28,
    //   text: 'Vehicles',
    //   value: 28
    // },
    // {
    //   key: 29,
    //   text: 'Entertainment: Comics',
    //   value: 29
    // },
    // {
    //   key: 30,
    //   text: 'Science: Gadgets',
    //   value: 30
    // },
    // {
    //   key: 31,
    //   text: 'Entertainment: Japanese Anime & Manga',
    //   value: 31
    // },
    // {
    //   key: 32,
    //   text: 'Entertainment: Cartoon & Animations',
    //   value: 32
    // }
];

export default CATEGORIES;
