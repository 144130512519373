import React, { useEffect } from 'react';
import { useGetTestsQuery } from "../store/store";
import Hero from "./Hero";
import ListingProducts from "./ListingProducts";
import Loader from "./Loader";
import { Hub } from 'aws-amplify';
import ReactGA from "react-ga4";
import FeatureSection from "./FeatureSection";
import { Container, Divider, Header } from 'semantic-ui-react';
import ChatBotMessage from './ChatBotMessage';
import message from './Archived/message';

ReactGA.initialize("G-5XVHY8M7JK");

// import { ThemeProvider  } from '@aws-amplify/ui-react';
//TODO rerun the getTests query when user logs in - COMPLETED

const App = () => {

    const {
        data: tests = [],
        refetch: testsRefetch,
        isLoading: isTestsLoading,
        isFetching: isTestsFetching,
        isSuccess: isTestsSuccess,
        isError: isTestsError,
        error: testsError
    } = useGetTestsQuery();

    useEffect(() => {
        const listener = (data) => {
            // Refetch the data when the authentication status changes
            // console.log('Hub data', data)
            testsRefetch();
        };

        ReactGA.send({
            hitType: "pageview", page: window.location.pathname + window.location.search,
            title: "App"
        });

        // Subscribe to authentication events from AWS Amplify
        Hub.listen('auth', listener);

        // Cleanup function to unsubscribe from the events
        return () => {
            Hub.remove('auth', listener);
        };
    }, [testsRefetch])

    return (
        <>
            <Hero />

            <Container className='mt-8 mb-16'>
                <div className='flex flex-row items-center justify-center'>
                    <span className="bg-neutral-100 text-emerald-500 rounded-full h-6 text-base font-medium px-2 py-1 uppercase flex items-center">
                        Featured
                    </span>
                </div>
            </Container>

            {(isTestsFetching || isTestsLoading) && <Loader />}
            {isTestsSuccess && <ListingProducts tests={tests} />}

            <FeatureSection />
        </>
    );
};

export default App;
