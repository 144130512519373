import React, {useEffect, useState} from 'react';
import PlaceholderComponent from './PlaceholderComponent'
import {Container, Form, Header, Divider, Icon, Image, Grid, Segment, Label, List} from 'semantic-ui-react'
import {DIFFICULTY} from '../constants'
import {useEditTestMutation, useGetFullTestQuery} from '../store/testsSlice'
import ImageGalleryModal from './ImageGalleryModal';
import {LAYOUT_VALUE as LV, LAYOUTS} from '../constants/layouts';
import PlaceholderAddElement from './PlaceholderAddElement';
import {useForm, useFieldArray, FormProvider, Controller, get} from 'react-hook-form';
import HookFormControlledField from './HookFormControlledField';
import HookFormControlledDropdown from './HookFormControlledDropdown';
import ButtonSave from './ButtonSave';
import HookFormControlledImagePicker from './HookFormControlledImagePicker';
import QuestionAnswerEditor from './QuestionAnswerEditor';
import Swal from 'sweetalert2';
import {v4 as uuidv4} from 'uuid';
import QuestionOptionEditorUncontrolled from './QuestionOptionEditorUncontrolled';
import QuestionTextEditor from './QuestionTextEditor';
import {QUESTION_TYPE, QUESTIONS_TYPE_LIST} from "../constants/questionsType";
import alphabet from "../constants/alphabet";

const QuestionEditor = ({testId, question, questionCount, questionIndex}) => {
    // console.log('QuestionEdit re-render')

    // const { data: test, isFetching: isTestFetching, isError: isTestError, error: testError, isSuccess: isTestSuccess, refetch: testRefetch } = useGetFullTestQuery(testId);
    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestMutation();

    

    let methods = useForm({
        // INFO: defaultValues persist from question to question, do NOT use
        // defaultValues: structuredClone(question),
        // INFO: Values refresh the form content BUT create infinite loop with Controller under QuestionOptionEditor
        values: structuredClone(question),
        shouldUnregister: true,
    })


    const {
        handleSubmit,
        register,
        reset,
        getValues,
        control,
        watch,
        setValue,
        formState: {isDirty, isValid, errors}
    } = methods;

    const watchImage = watch('question_image');
    const watchDifficulty = watch('difficulty') || '';
    const watchQuestionType = watch('type');

    const {fields, append, remove, move} = useFieldArray({
        control,
        name: "options",
        shouldUnregister: true,
    });


    // console.log('options', getValues('options'))
    // console.log('fields', fields)

    // TODO: add validation rule on answer_id length === questionType.length - COMPLETED
    const {
        fields: fieldsAnswerIds, append: appendAnswerIds,
        prepend: prependAnswerIds, remove: removeAnswerIds, move: moveAnswerIds
    } = useFieldArray({
        control,
        name: `answer.values`,
        shouldUnregister: true,
        rules: {
            validate: (values) => values?.length === QUESTION_TYPE[watchQuestionType]?.length
                || `Array must have ${QUESTION_TYPE[watchQuestionType]?.length}  items`,
            message: `Array must have ${QUESTION_TYPE[watchQuestionType]?.length} questions`,
        }
    });


    useEffect(() => {
        reset();
        return (
            () => reset()
        );
    }, [questionIndex])


    const [modalState, setModalState] = useState({isOpen: false, field: null, dispatchProps: {}});

    // console.log('formValues', getValues())

    const handleOnFormSubmit = (data) => {

        const body = {
            question: {
                ...data,
            },
            questionIndex,
            testId,
            scope: 'questionEdit',
        }

        // console.log('formBody:', data)

        editTest(body)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Update saved`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => console.log(err))
    }

    const handleOnAddOption = (e) => {
        e.preventDefault();
        // console.log('event: ', e)
        append({
            option_id: uuidv4(),
            option_image: '',
            option_text: '',
        });
    }

    const handleOnMoveOption = (e, {from, to}) => {
        e.preventDefault();

        // validate from and to indices
        if (from < 0 || to < 0 || from >= fields.length || to >= fields.length) return;

        move(from, to);

    }

    // INFO: previous version, non-array
    // const handleOnDeleteOption = (e, {index, value}) => {
    //     e.preventDefault()
    //
    //
    //
    //     if (value.option_id === getValues('answer.answer_id')) {
    //         setValue('answer.answer_id', '', {
    //             shouldValidate: true,
    //             shouldDirty: true,
    //             shouldTouch: true,
    //         });
    //     }
    //
    //     remove(index)
    //
    // }

    // INFO: previous version, simple array, rather than react-hook-form array
    // const handleOnDeleteOption = (e, {index, value}) => {
    //     e.preventDefault()
    //
    //     const arr = getValues('answer.answer_id');
    //     if (arr.includes(value.option_id)) {
    //         // remove elem
    //         const idx = arr.indexOf(value.option_id);
    //         arr.splice(idx, 1)
    //     }
    //
    //     remove(index)
    //
    // }

    // TODO: Implement as react-hook-form array - COMPLETED
    const handleOnDeleteOption = (e, {index, value}) => {
        e.preventDefault()
        const isIncludedIndex = fieldsAnswerIds.findIndex(o => o.value === value.option_id);

        if (isIncludedIndex >= 0) {
            // remove elem
            removeAnswerIds(isIncludedIndex);
        }

        remove(index)

    }


    // INFO: previous version, non-array
    // const handleOnMarkCorrectOption = (e, {value}) => {
    //     e.preventDefault()
    //
    //     if (value.option_id === getValues('answer.answer_id')) {
    //         setValue('answer.answer_id', '', {
    //             shouldValidate: true,
    //             shouldDirty: true,
    //             shouldTouch: true,
    //         });
    //     } else {
    //         setValue('answer.answer_id', value.option_id, {
    //             shouldValidate: true,
    //             shouldDirty: true,
    //             shouldTouch: true,
    //         });
    //     }
    // }

    // INFO: previous version, simple array, rather than react-hook-form array
    // const handleOnMarkCorrectOption = (e, {value}) => {
    //     e.preventDefault()
    //
    //     const arr = getValues('answer.answer_id');
    //     if (arr.includes(value.option_id)) {
    //         // remove elem
    //         const idx = arr.indexOf(value.option_id);
    //         arr.splice(idx, 1)
    //         setValue('answer.answer_id', getValues('answer.answer_id'), {
    //             shouldValidate: true,
    //             shouldDirty: true,
    //             shouldTouch: true,
    //         });
    //     } else {
    //         // add elem
    //         arr.push(value.option_id);
    //         setValue('answer.answer_id', getValues('answer.answer_id'), {
    //             shouldValidate: true,
    //             shouldDirty: true,
    //             shouldTouch: true,
    //         });
    //     }
    // }

    // TODO: implement as react-hook-form array - COMPLETED
    const handleOnMarkCorrectOption = (e, {value, index = null, type}) => {
        e.preventDefault()

        // console.log('value', value)

        const isIncludedIndex = fieldsAnswerIds.findIndex(o => o.value === value.option_id);

        if (isIncludedIndex >= 0) {
            // remove elem
            removeAnswerIds(isIncludedIndex);
        } else {
            // add elem
            const obj = {};
            if (type === 'option_id') {
                obj.type = type;
                obj.value = value.option_id;
                obj.index = index;
            }
            appendAnswerIds(obj);
        }

    }

    let content;

    content = (
        <Container>

            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard'/>
                    Question Metadata
                </Header>
            </Divider>


            <Form
                onSubmit={handleSubmit(handleOnFormSubmit)}
                // loading={isTestFetching}
                widths={'equal'}
            >

                <HookFormControlledField
                    name={'question_id'}
                    control={control}
                    label={'Question Id (Not Editable)'}
                    disabled={true}
                />

                <HookFormControlledField
                    name={'label'}
                    control={control}
                    label={'Enter Question Label (e.g. numerical, verbal etc.)'}
                />


                <HookFormControlledDropdown
                    name={'type'}
                    options={QUESTIONS_TYPE_LIST}
                    label={'Select Question Type'}
                    control={control}
                    required={true}
                />


                <HookFormControlledDropdown
                    name={'difficulty'}
                    options={DIFFICULTY}
                    label={'Select Question Difficulty'}
                    control={control}
                />

                <HookFormControlledField
                    name={'score'}
                    control={control}
                    label={'Enter Question Point Score'}
                    type={'number'}
                />

                <HookFormControlledDropdown
                    name={'layout'}
                    control={control}
                    label={'Select Question Layout'}
                    options={LAYOUTS}
                />

                <ButtonSave
                    type={'submit'}
                    color={'green'}
                    isDisabled={editTestIsLoading || !isDirty}
                    isLoading={editTestIsLoading}
                    label={'Save Metadata Changes'}
                    isError={editTestIsError || Object.keys(errors).length !== 0}
                    error={{...editTestError, ...errors}}
                />

            </Form>


            <Divider horizontal>
                <Header as='h4'>
                    <Segment.Inline>
                        <Icon name='check circle' color='black'/>
                        Question {questionIndex + 1} / {questionCount} ({Math.max(100, ((questionIndex + 1) / (questionCount) * 100).toFixed(0))}%)
                    </Segment.Inline>
                </Header>
            </Divider>


            <FormProvider {...methods}>
                <Form
                    onSubmit={handleSubmit(handleOnFormSubmit)}
                    widths={'equal'}
                >

                    <QuestionTextEditor
                        control={control}
                        name={'question_text'}
                        label={'Question Text'}
                    />

                    <Container
                        fluid
                    >

                        <Grid
                            columns={LV[watch('layout')]?.['grid_cols'] ?? LV['compact']?.['grid_cols']}
                            stackable
                        >

                            <Grid.Column
                                width={LV[watch('layout')]?.['question_col'] ?? LV['compact']?.['question_col']}
                            >

                                <Image size={'large'} src={watchImage}/>

                                <Divider hidden/>
                                <Form.Field>
                                    <HookFormControlledImagePicker
                                        name={'question_image'}
                                        setModalState={setModalState}
                                        control={control}
                                    />
                                </Form.Field>
                                <Divider hidden/>

                                {watchQuestionType &&
                                    <Header size={'small'}
                                            color={'black'}>Select <span
                                        color={'blue'}>{QUESTION_TYPE[watchQuestionType].length}</span> correct
                                        answer(s)</Header>
                                }
                                <Header size={'small'}>Correct Answer(s):</Header>
                                <List>
                                    {fieldsAnswerIds
                                        .map((elem, index) => {
                                            return (
                                                <List.Item>
                                                    {elem?.index >= 0 &&
                                                        <Label circular>
                                                            {alphabet(elem?.index)}
                                                        </Label>
                                                    }
                                                    <Label icon={'angle right'}>
                                                        {elem?.value}
                                                    </Label>
                                                </List.Item>
                                            );
                                        })}
                                </List>

                            </Grid.Column>


                            <Grid.Column
                                width={LV[watch('layout')]?.['answers_col'] ?? LV['compact']?.['answers_col']}
                            >

                                {watchDifficulty && <Label attached='bottom right'>{watchDifficulty}</Label>}


                                <Grid
                                    columns={LV[watch('layout')]?.['cols_per_answer_col'] ?? 2}
                                    stackable
                                >

                                    {fields.map((option, index) => {
                                        return (

                                            <Grid.Column
                                                key={option.id}
                                                width={LV[watch('layout')]?.['answer_subCol'] ?? LV['compact']?.['answer_subCol']}
                                            >
                                                <QuestionOptionEditorUncontrolled
                                                    name={`options.${index}`}
                                                    index={index}
                                                    setModalState={setModalState}
                                                    onDelete={handleOnDeleteOption}
                                                    onMove={handleOnMoveOption}
                                                    onMarkCorrect={handleOnMarkCorrectOption}
                                                    // fields={fields}
                                                />
                                            </Grid.Column>
                                        )
                                    })}


                                    <Grid.Column
                                        width={watch('layout') ? LV[watch('layout')]['answer_subCol'] : LV['compact']['answer_subCol']}
                                    >

                                        <PlaceholderAddElement
                                            text={'Add Option'}
                                            buttonText={'Add'}
                                            onClick={handleOnAddOption}/>

                                    </Grid.Column>

                                </Grid>


                            </Grid.Column>
                        </Grid>

                    </Container>


                    <ButtonSave
                        type='submit'
                        color='green'
                        isDisabled={editTestIsLoading || !isDirty}
                        isLoading={editTestIsLoading}
                        label={'Save Question Changes'}
                        isError={editTestIsError || Object.keys(errors).length !== 0}
                        error={{...editTestError, ...errors}}
                    />

                </Form>
            </FormProvider>


            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard'/>
                    Answer Explanation
                </Header>
            </Divider>

            <Form
                onSubmit={handleSubmit(handleOnFormSubmit)}
                widths={'equal'}
            >
                <QuestionAnswerEditor
                    label={'Answer'}
                    name={'answer'}
                    setModalState={setModalState}
                    control={control}
                    watch={watch}
                />

                <Divider hidden/>

                <ButtonSave
                    type={'submit'}
                    color={'green'}
                    isDisabled={editTestIsLoading || !isDirty}
                    isLoading={editTestIsLoading}
                    label={'Save Answer Explanation Changes'}
                    isError={editTestIsError || Object.keys(errors).length !== 0}
                    error={{...editTestError, ...errors}}
                />
            </Form>

        </Container>
    )
    ;


    return (
        <>
            {modalState?.isOpen &&
                <ImageGalleryModal
                    testId={testId}
                    modalState={modalState}
                    setModalState={setModalState}
                    setValue={(field, value) => setValue(field, value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                    })}
                />}
            {content}
        </>

    )
}

export default QuestionEditor
