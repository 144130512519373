import {Navigate, useLocation} from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';
import useUserStatus from "../../utils/useUserStatus";
import { useEffect, useState } from "react";

// TODO: no longer needed, replaced by PrivateWrapper component
const ProtectedRoute = ({ children }) => {

    const [state, setState] = useState(null);
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setState(children)
            }).catch((err) => {
                setState(<Navigate to={`/login?redirectTo=${pathname}`} replace />)
            })

    },[location]);

    return state;
};

export default ProtectedRoute;