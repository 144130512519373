import React, { useState } from 'react';
import { Grid, Header, Icon, Segment, Button } from "semantic-ui-react";
import { convertTimeStampDifferenceToHoursAndMinutes, convertTimeStampToDate } from "../utils/convertTimeStampToDate";
import { useNavigate } from "react-router-dom";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const SessionResultCard = ({ session }) => {

    // const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const results = session?.results?.summary?.results;
    const test = session?.results?.summary?.test;
    const session_status = session?.session_status;

    // console.log('results', test)

    let data = null;
    let options = null;

    if (test) {
        const { questions_available, questions_correct, questions_answered } = test;
        const correct = questions_correct;
        const incorrect = questions_answered - questions_correct;
        const skipped = questions_available - questions_answered;

        // console.log('skipped', skipped)

        data = {
            labels: [
                'Correct',
                'Incorrect',
                'Skipped'
            ],
            datasets: [{
                label: 'Test Result Summary',
                data: [correct, incorrect, skipped],
                backgroundColor: [
                    '#4ade80',
                    '#f87171',
                    '#9ca3af'
                ],
                hoverOffset: 2
            }]
        };

        options = {
            plugins: {
                title: {
                    display: false,
                    text: 'Test Result',
                },
                legend: {
                    position: 'right',
                    labels: {
                        boxWidth: 6,
                        padding: 2,
                    },
                    display: true,
                },
                tooltip: {
                    enabled: false,
                },
                datalabels: {
                    formatter: (value, context) => {
                        if (value > 0) {
                            // const dataset = context.dataset;
                            // const total = dataset.data.reduce((a, b) => a + b, 0);
                            // const percentage = ((value / total) * 100).toFixed(0) + '%';
                            // console.log('context', context)
                            // return `${value}|${percentage}`; // Display the value instead of percentage
                            return value; // Display the value instead of percentage
                        }
                        return '';
                    },
                    color: '#020617',
                    font: {
                        weight: 'base',
                        size: 7,
                    },
                },
            },
            cutout: '45%'
        };

    }

    const outcome = session_status === 'Completed'
        ? session?.config?.category === 'iq'
            ? `IQ: ${results?.score}`
            : session?.config?.category === 'aws'
                ? `Result: ${results?.result}`
                : ''
        : `Status: ${session_status}`;

    const percentCorrect = (Number(test?.questions_correct) && Number(test?.questions_available))
        ? (Number(test?.questions_correct) / Number(test?.questions_available) * 100).toFixed(0) + '%'
        : '';

    const duration = (session?.startTime && session?.endTime)
        ? convertTimeStampDifferenceToHoursAndMinutes(session?.endTime, session?.startTime)
        : '';

    const date = (session?.createTime)
        ? convertTimeStampToDate(session?.createTime)
        : '';

    const handleOnClick = () => {
        navigate(`/sessions/${session.id}`);
    }

    return (
        // <div>
        //     {
        //         isOpen
        //             ? ('')
        //             : (
        <div className={'py-2'}>

            <Segment>
                <Grid columns='equal' stackable>
                    <Grid.Column>
                        {/* <Segment basic> */}
                        <div className='my-0 max-w-[120px]'>
                            {test && <Doughnut data={data} options={options} />}
                        </div>
                        {/* </Segment> */}
                    </Grid.Column>
                    <Grid.Column>
                        {/* <Segment basic> */}
                        <div className='font-extrabold text-base my-3'>
                            {outcome}

                        </div>
                        {/* </Segment> */}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {/* <Segment basic> */}
                        <div className={'font-bold text-base my-3'}>
                            {percentCorrect}
                        </div>
                        {/* </Segment> */}
                    </Grid.Column>
                    <Grid.Column>
                        {/* <Segment basic> */}
                        <div className={'font-bold text-base my-3'}>
                            {duration}
                        </div>
                        {/* </Segment> */}
                    </Grid.Column>
                    <Grid.Column>
                        {/* <Segment basic> */}
                        <div className={'font-base my-3'}>
                            {date}
                        </div>
                        {/* </Segment> */}
                    </Grid.Column>
                    <Grid.Column width={2} className={'cursor-pointer'}>
                        {/* <Segment basic onClick={handleOnClick}> */}
                        <div className={'font-base my-3'}>
                            <Button icon circular color='black' onClick={handleOnClick}>
                                <Icon name='angle right' />
                            </Button>
                            {/* <Icon name={'angle right'} /> */}
                        </div>

                        {/* </Segment> */}
                    </Grid.Column>
                </Grid>
            </Segment>
        </div>
        //             )

        //     }
        // </div>
    );
};

export default SessionResultCard;
