import React, { useEffect, useState } from 'react';
import { useGetTestQuery } from "../store/store";
import { useParams } from "react-router-dom";
import TestProductPageHeaderCard from "./TestProductPageHeaderCard";
import { Container, Divider } from "semantic-ui-react";
import TestProductPageIncluded from "./TestProductPageIncluded";
import TestProductPageDescription from "./TestProductPageDescription";
import PlacehoderLoader from "./PlaceholderLoader";
import ReactGA from "react-ga4";
import { useAuthenticator } from "@aws-amplify/ui-react";
import PurchaseModal from "./PurchaseModal";
import FlashcardDeck from "./FlashcardDeck";
import { useGetFlashcardDeckQuery } from "../store/store";

const FlashcardProductPage = () => {

    const { flashcardId } = useParams();

    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [isModalMounted, setIsModalMounted] = useState(false);
    const [modalContent, setModalContent] = useState({});

    // console.log('props', props)

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview", page: window.location.pathname + window.location.search,
            title: "ProductPage"
        });

    }, []);

    const {
        data: flashcardDeck,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    }
        = useGetFlashcardDeckQuery(flashcardId);

    // console.log('flashcardDeck', flashcardDeck)

    // TODO: Add author page, and Review page
    return (

        isTestSuccess
            ? (
                <Container fluid className={'pb-48'}>
                    {/* Purchase Model to execute transaction, managed by isModalMounted */}
                    {/* {isModalMounted && <PurchaseModal
                        open={isModalMounted}
                        mountModal={setIsModalMounted}
                        test={modalContent}
                        setContent={setModalContent}
                    />} */}
                    <FlashcardDeck flashcards={flashcardDeck?.questions} autoPlay={false} />
{/* 
                    <TestProductPageHeaderCard
                        test={test}
                        modal={{
                            mountModal: setIsModalMounted,
                            setContent: setModalContent
                        }}
                        isPurchaseDisabled={authStatus !== 'authenticated'}
                    />
                    <TestProductPageIncluded test={test} />
                    <TestProductPageDescription test={test} /> */}
                </Container>
            )
            : (
                <PlacehoderLoader size={'medium'} />
            )
    );
}


export default FlashcardProductPage;
