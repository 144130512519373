import React from 'react';
import {Container, Divider, Header} from "semantic-ui-react";
import {useGetSessionsQuery, useGetTestQuery, useGetTestsQuery} from "../store/store";
import Loader from "./Loader";
import ListingProducts from "./ListingProducts";
import SessionResultsListing from "./SessionResultsListing";

const TestResultsPage = ({testId}) => {

        const searchQuery = new URLSearchParams({testId}).toString();

        const {
            data: sessions = [],
            refetch: sessionsRefetch,
            isLoading: isSessionsLoading,
            isFetching: isSessionsFetching,
            isSuccess: isSessionsSuccess,
            isError: isSessionsError,
            error: sessionsError
        } = useGetSessionsQuery(searchQuery);

        const {
            data: test,
            refetch: testRefetch,
            isLoading: isTestLoading,
            isFetching: isTestFetching,
            isSuccess: isTestSuccess,
            isError: isTestError,
            error: testError
        } = useGetTestQuery(testId);

        return (
            <Container>
                {(isTestSuccess) &&
                    < Header size={"large"}> {`${test?.['product_summary']?.['title']} - Results`}</ Header>}

                <Divider/>
                {(isSessionsFetching || isTestFetching) && <Loader/>}
                {isSessionsSuccess && <SessionResultsListing sessions={sessions}/>}
            </Container>
        );
    }
;

export default TestResultsPage;
