import {PayPalButtons} from "@paypal/react-paypal-js";
import {useState} from "react";
import {useCapturePaymentMutation, useCreateOrderMutation} from "../store/store";
import ReactGA from "react-ga4";

const handleApprove = () => {

}

// Renders errors or successfull transactions on the screen.
function Message({content}) {
    return <p>{content}</p>;
}


const PaypalCheckoutButton = ({test, setTransactionSuccess}) => {
    const [message, setMessage] = useState('');

    const [createOrder, {
        data: createOrderData,
        error: createOrderError,
        isLoading: createOrderIsLoading,
        isSuccess: createOrderIsSuccess,
        isError: createOrderIsError
    }] = useCreateOrderMutation();

    const [capturePayment, {
        data: capturePaymentData,
        error: capturePaymentError,
        isLoading: capturePaymentIsLoading,
        isSuccess: capturePaymentIsSuccess,
        isError: capturePaymentIsError
    }] = useCapturePaymentMutation();

    return (
        <>
            <PayPalButtons
                style={{
                    shape: 'rect',
                    //color:'blue' change the default color of the buttons
                    layout: 'vertical', //default value. Can be changed to horizontal
                }}

                createOrder={async (data, actions) => {

                    try {
                        const {data: {orderData}} = await createOrder({
                            product: {
                                id: test?.id,
                                quantity: 1,
                                title: test?.['product_card']?.['header'],
                                price: test?.['product_card']?.['price'],
                            }
                        })

                        console.log('orderData - createOrder', orderData)

                        if (orderData.id) {
                            return orderData.id;

                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }
                    } catch
                        (error) {
                        console.error(error);
                        setMessage(
                            `Could not initiate PayPal Checkout...${error}`,
                        );
                    }
                }
                }


                onApprove={async (data, actions) => {
                    try {

                        const {data: {orderData}} = await capturePayment({
                            orderId: data.orderID,
                            testId: test.id,
                            header: test?.['product_card']?.['header'],
                            price: test?.['product_card']?.['price'],
                        })

                        // Three cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message
                        //   (3) Successful transaction -> Show confirmation or thank you message

                        // console.log('orderData - capturePayment', orderData)

                        const errorDetail = orderData?.details?.[0];

                        if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                            // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                            return actions.restart();
                        } else if (errorDetail) {
                            // (2) Other non-recoverable errors -> Show a failure message
                            throw new Error(
                                `${errorDetail.description} (${orderData.debug_id})`,
                            );
                        } else {
                            // (3) Successful transaction -> Show confirmation or thank you message
                            // Or go to another URL:  actions.redirect('thank_you.html');
                            const transaction =
                                orderData.purchase_units[0].payments.captures[0];
                            setMessage(
                                `Transaction ${transaction.status}: ${transaction.id}.`,
                            );
                            setTransactionSuccess(transaction);
                            // console.log(
                            //     'Capture result',
                            //     orderData,
                            //     JSON.stringify(orderData, null, 2),
                            // );
                            ReactGA.event({
                                category: "purchase",
                                action: "purchase",
                                label: test?.id, // optional
                                nonInteraction: false, // optional, true/false
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        setMessage(
                            `Sorry, your transaction could not be processed...${error}`,
                        );
                    }
                }}
            />
            <Message content={message}/>
        </>
    );
};

export default PaypalCheckoutButton;