import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Form, Input, Button, List, Segment, Label, Loader } from 'semantic-ui-react';
import { usePostAICreateThreadMutation, usePostAIGenerateFollowUpMutation, usePostAISendMessageMutation } from '../store/store'
import { addMessage, setThreadId } from '../store/chatSlice';
import { CategoryScale } from 'chart.js';
import ReactMarkdown from 'react-markdown';
import ChatBotMessage from './ChatBotMessage';

const questionObject = {
    "difficulty": "easy",
    "layout": "optionsOnly",
    "question_text": "Which cloud deployment model best describes AWS?",
    "score": 1,
    "answer": {
        "answer_image": "",
        "answer_text": "<p>AWS is primarily a public cloud deployment model where a user can provision resources in the public cloud. Resources are provisioned for open use by the general public, business, academic, government organizations, or some combination of them. Resources exist on the premises of the cloud provider.</p>",
        "values": [
            {
                "type": "option_id",
                "index": 0,
                "value": "2d59aae0-0148-4ef3-8181-33b31bb5c8f8"
            }
        ]
    },
    "label": "Cloud Concepts",
    "type": "multiple_answer_1",
    "question_id": "4275112b-dbab-4f6f-ac29-4dc48f250d4c",
    "answer_image": "null",
    "question_image": "null",
    "options": [
        {
            "option_id": "2d59aae0-0148-4ef3-8181-33b31bb5c8f8",
            "option_header": "Public Cloud",
            "option_text": "",
            "option_image": "null"
        },
        {
            "option_id": "958a9f13-2d6b-4334-ad6c-685fbf35a251",
            "option_header": "Private Cloud",
            "option_text": "",
            "option_image": "null"
        },
        {
            "option_id": "062a754e-3c24-45c8-bd0c-d44a9f2f0c76",
            "option_header": "Hybrid Cloud",
            "option_text": "",
            "option_image": "null"
        },
        {
            "option_id": "cf3cd67f-6880-4e76-b375-ca8adedfe349",
            "option_header": "Community Cloud",
            "option_text": "",
            "option_image": "null"
        }
    ]
}

const ChatBot = ({ sessionId = null, question = null, category = null }) => {
    const [userInput, setUserInput] = useState('');
    const [followUps, setFollowUps] = useState([]);
    const [triggerHandleSendMessage, setTriggerHandleSendMessage] = useState(false);
    const [createThread, { isLoading: isLoadingCreateThread, isError: isErrorCreateThread,
        isSuccess: isThreadIdSuccess, error: errorCreateThread,
        data: threadIdData }] = usePostAICreateThreadMutation();
    const [sendMessage, {
        isLoading: isLoadingSendMessage, isError: isErrorSendMessage,
        isSuccess: isMessageSuccess, error: errorSendMessage, data: messageData }] = usePostAISendMessageMutation();

    const [
        generateFollowUp, {
            isLoading: isLoadingGenerateFollowUp, isError: isErrorGenerateFollowUp,
            isSuccess: isFollowUpSuccess, error: errorGenerateFollowUp,
            data: followUpData
        }
    ] = usePostAIGenerateFollowUpMutation();

    // const handleSendMessage = async (e) => {
    //   e.preventDefault();
    //   if (userInput.trim() !== '') {
    //     const threadId = await createThread().unwrap();
    //     await sendMessage({ message: userInput, threadId }).unwrap();
    //     setUserInput('');
    //   }
    // };

    // const [threadId, setThreadId] = useState('');
    const dispatch = useDispatch();
    const messages = useSelector((state) => state?.chat?.sessions?.[sessionId]?.messages);
    const threadId = useSelector((state) => state.chat?.sessions?.[sessionId]?.threadId);
    // const [postAIMessage, { isLoading, error }] = usePostAIMessageMutation();
    // const [postAICreateThread, { isLoading: isLoadingPostAICreateThread, error: errorPostAICreateThread, isSuccess: isThreadIdSuccess, data: threadIdData }] = usePostAICreateThreadMutation();

    // console.log('messages:', messages)

    useEffect(() => {
        if (isThreadIdSuccess && threadIdData) {
            // console.log('threadIdData:', threadIdData.threadId)
            // console.log('threadIdData:', threadIdData)
            dispatch(setThreadId({
                sessionId,
                threadId: threadIdData.threadId
            }))

            dispatch(addMessage({
                sessionId,
                message: threadIdData.response,
                role: 'assistant',
            }));

            // set followUps
            setFollowUps(threadIdData.followUps ? [...threadIdData.followUps] : []);

            // setThreadId(threadIdData.threadId);
            // dispatch(addBotMessage(threadIdData.response));
            // dispatch(setThreadId(threadIdData.thread_id));
        }
    }, [isThreadIdSuccess, threadIdData, dispatch]);

    useEffect(() => {
        if (!threadId) {
            // clean followUps array if creating new ThreadId
            setFollowUps([]);

            createThread({
                action: 'chat-new',
                question: questionObject,
                testCategory: category
            });
        }
    }, []);

    const handleSendMessage = async (e) => {
        if (e) {
            e.preventDefault();
        }
        // console.log('userInput', userInput)

        if (userInput.trim() === '') return;


        // add user message to the Redux store
        dispatch(addMessage({
            sessionId,
            message: userInput,
            role: 'user',
        }));

        // clean up userInput and followUps
        setUserInput('');
        setFollowUps([]);

        try {
            const result = await sendMessage({
                action: 'chat-existing',
                testCategory: category,
                message: userInput,
                threadId
            }).unwrap();
            // console.log('result:', result)
            // add message to the Redux store
            dispatch(addMessage({
                sessionId,
                message: result.response,
                role: 'assistant',
            }));
        } catch (err) {
            dispatch(addMessage({
                sessionId,
                // message: err?.data?.message,
                message: JSON.stringify(err),
                role: 'assistant',
            }));
        }
    };

    // useEffect to trigger API call if followUp was pressed and changed userInput state and triggerHnadleSendMessage state
    useEffect(() => {
        handleSendMessage(null);
    }, [triggerHandleSendMessage])


    const handleOnFollowUpClick = async (event, data) => {
        event.preventDefault();
        if (data.children.trim()) {
            // console.log('children', data.children.trim())
            setUserInput(data.children.trim());
            setTriggerHandleSendMessage(!triggerHandleSendMessage);
        }
    }

    // trigger generateFollowUp API
    useEffect(() => {

        // message length > 2 messages
        // last message must be bot
        // preultimate message must be user

        if (messages?.length > 2 && messages[-1]?.role === 'assistant') {
            generateFollowUp({
                action: 'generate-follow-up',
                messages: messages.slice(-4),
            });
        }

    }, [messages])

    // setFollowUps in the state if API call is successful
    useEffect(() => {
        if (isFollowUpSuccess && JSON.parse(followUpData.response).length > 0) {
            setFollowUps([...JSON.parse(followUpData.response)])
        }
    }, [isFollowUpSuccess, followUpData])

    // add smooth scroll to the end of messages to keep them in view
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Container className="h-[90vh] overflow-y-auto overflow-x-hidden">
            <Header as="h3" attached="top" block>
                AI Tutor
            </Header>
            <Segment attached>
                <List>
                    {messages?.map((msg, index) => (
                        <List.Item key={index} style={{ textAlign: msg.sender === 'user' ? 'right' : 'left' }}>
                            <Segment color={msg.sender === 'user' ? 'blue' : 'green'}>
                                {msg.sender === 'bot' ? (
                                    <ChatBotMessage message={msg.text} />
                                ) : (
                                    msg.text
                                )}
                            </Segment>
                        </List.Item>
                    ))}
                </List>
            </Segment>
            <Segment attached="bottom">
                <div className='my-[1px]'>
                    {followUps.map((followUp, index) => (<Label color='blue' key={index} className='cursor-pointer' basic onClick={handleOnFollowUpClick}>{followUp}</Label>))}
                </div>
                <Form onSubmit={handleSendMessage} isLoading={isLoadingCreateThread}>
                    <Input
                        fluid
                        placeholder="Type a message..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        action
                    >
                        <input />
                        <Button type="submit" primary disabled={isLoadingSendMessage}>
                            Send
                        </Button>
                    </Input>
                </Form>
                {isLoadingSendMessage && <p>
                    <Loader active inline size='tiny' color={'blue'} />
                    <span className='ml-1'> Generating your answer...</span>
                </p>}
                {errorSendMessage && <p style={{ color: 'red' }}>Error: {errorSendMessage?.data?.message}</p>}
            </Segment>
            <div ref={messagesEndRef} />
        </Container>
    );
};

export default ChatBot;
