export const SCORING_OPTIONS = [
    {
        key: '0',
        text: 'Normal Distribution',
        value: 'normal_distribution',
    },
    {
        key: '1',
        text: 'Pass/Fail',
        value: 'pass_fail',
    },
    {
        key: '2',
        text: 'Pass/Fail Normally Distribution',
        value: 'pass_fail_normal_distribution',
    },
    {
        key: '3',
        text: 'Scoring Table Normally Distribution',
        value: 'scoring_table_normal_distribution',
    }
];

// allowed values: table, number, text

export const SCORING_OPTION_FIELDS = {
    'normal_distribution':
        [
            {
                key: 'mean', label: 'Mean (Number)', type: 'number'
            },
            {
                key: 'sd', label: 'Standard Deviation (Number)', type: 'number',
            },
        ],
    'pass_fail':
        [
            {
                key: 'pass_score', label: 'Pass Score (Number)', type: 'number',
            },
        ],
    'pass_fail_normal_distribution':
        [
            {
                key: 'pass_score', label: 'Pass Score (Number)', type: 'number',
            },
            {
                key: 'mean', label: 'Mean (Number)', type: 'number',
            },
            {
                key: 'sd', label: 'Standard Deviation (Number)', type: 'number',
            },
        ],
    'scoring_table_normal_distribution':
        [
            {
                key: 'scoring_table', label: 'Scoring Table', type: 'table',
            },
            {
                key: 'mean', label: 'Mean (Number)', type: 'number',
            },
            {
                key: 'sd', label: 'Standard Deviation (Number)', type: 'number',
            },
        ],
};