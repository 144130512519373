import {useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useEditTestMutation, useEditTestReorderQuestionsMutation, useGetFullTestQuery} from '../store/testsSlice';
import {
    Icon,
    Button,
    Divider,
    Container,
    Segment,
    Header,
    Pagination,
    Card,
    Image,
    Grid,
    List,
    Table
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import DOMPurify from "dompurify";


const QuestionsDragAndDrop = ({testId}) => {

    const {
        data: test, isFetching: isTestFetching, isError: isTestError, error: testError,
        isSuccess: isTestSuccess, refetch: testRefetch
    } = useGetFullTestQuery(testId);

    const [editQuestionOrder, {
        data: editQuestionOrderData, error: editQuestionOrderError,
        isLoading: editQuestionOrderIsLoading, isSuccess: editQuestionOrderIsSuccess,
        isError: editQuestionOrderIsError
    }] = useEditTestReorderQuestionsMutation();

    const [editTest, {
        data: editTestData, error: editTestError, isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess, isError: editTestIsError
    }] = useEditTestMutation();


    useEffect(() => {
        if (isTestSuccess) {
            setQuestions([...test?.questions]);
        }
    }, [test, isTestSuccess]);

    const [questions, setQuestions] = useState([]);

    function handleOnDragEnd(result) {
        const {destination, source, draggableId} = result;
        // if destination outside Droppable context
        if (!destination) return;

        // same element in same spot
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // update state
        const items = Array.from(questions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setQuestions(items);

        // optimistic update to back-up, un-do optimistic update if error
        const body = {
            sourceIndex: result.source.index,
            destinationIndex: result.destination.index,
            testId,
            scope: 'questionOrder',
        }

        editQuestionOrder(body)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Update saved`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Unable to save`,
                    showConfirmButton: false,
                    timer: 3000
                })
                console.log(err)
            })
    }

    const handleOnDelete = (e, {questionIndex}) => {
        e.preventDefault();

        const body = {
            questionIndex,
            testId,
            scope: 'questionDelete',
        }

        editTest(body)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Question Deleted`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Unable to Delete Question `,
                    showConfirmButton: false,
                    timer: 3000
                })
                console.log(err)
            })
    }

    const sanitizedData = (htmlData) => ({
        __html: DOMPurify.sanitize(htmlData)
    })

    return (
        <Container textAlign={'left'}>
            <Header as={'h2'} textAlign='center'>
                Drag & Drop to Reorder Questions
            </Header>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="questions">
                    {(provided, snapshot) => (
                        <Container
                        >
                            <ul style={{listStyleType: 'none'}} className='questions' {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {questions.map(({
                                                    question_id,
                                                    question_text,
                                                    question_image,
                                                    score,
                                                    type,
                                                    difficulty,
                                                }, index) => {

                                    const isQuestionImage = question_image ? true : false;

                                    return (
                                        <Draggable key={question_id} draggableId={question_id} index={index}>
                                            {(provided, snapshot) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Card
                                                        fluid
                                                        key={question_id}
                                                        color={'grey'}
                                                        style={{cursor: 'pointer'}}
                                                        raised
                                                        // className={snapshot.isDragging ? 'bg-slate-50' : 'bg-inherit'}
                                                    >

                                                        <Card.Content>
                                                            <Card.Header

                                                            >{`Question Number ${index + 1}`}</Card.Header>
                                                            <Card.Meta>
                                                                <span>{`Question ID: ${question_id}`}</span>
                                                            </Card.Meta>

                                                        </Card.Content>

                                                        <Card.Content>
                                                            <Grid fluid>
                                                                <Grid.Column width={8} textAlign={'center'}>
                                                                    <Container>
                                                                        {
                                                                            question_image
                                                                                ? (
                                                                                    <Image
                                                                                        centered
                                                                                        size='medium'
                                                                                        src={question_image}
                                                                                        rounded
                                                                                        textAlign={'center'}
                                                                                    />
                                                                                )
                                                                                : (
                                                                                    <Table>
                                                                                        <Table.Header>

                                                                                            <Table.HeaderCell>
                                                                                                Question Text
                                                                                            </Table.HeaderCell>
                                                                                        </Table.Header>

                                                                                        <Table.Row>
                                                                                            <Table.Cell>
                                                                                                <div
                                                                                                    dangerouslySetInnerHTML={sanitizedData(question_text)}
                                                                                                />
                                                                                            </Table.Cell>
                                                                                        </Table.Row>
                                                                                    </Table>
                                                                                )
                                                                        }
                                                                    </Container>
                                                                </Grid.Column>
                                                                <Grid.Column width={8} textAlign={'left'}>

                                                                    <Card.Description>
                                                                        <Container>
                                                                            <Table definition>
                                                                                <Table.Body>
                                                                                    {
                                                                                        question_image &&
                                                                                        (
                                                                                            <Table.Row>
                                                                                                <Table.Cell
                                                                                                    width={4}>Question
                                                                                                    Text</Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    <div
                                                                                                        dangerouslySetInnerHTML={sanitizedData(question_text)}
                                                                                                    />
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                        )
                                                                                    }
                                                                                    <Table.Row>
                                                                                        <Table.Cell>Type</Table.Cell>
                                                                                        <Table.Cell>{type}</Table.Cell>
                                                                                    </Table.Row>
                                                                                    <Table.Row>
                                                                                        <Table.Cell>Score</Table.Cell>
                                                                                        <Table.Cell>{score}</Table.Cell>
                                                                                    </Table.Row>
                                                                                    <Table.Row>
                                                                                        <Table.Cell>Difficulty</Table.Cell>
                                                                                        <Table.Cell>{difficulty}</Table.Cell>
                                                                                    </Table.Row>
                                                                                </Table.Body>
                                                                            </Table>
                                                                        </Container>
                                                                    </Card.Description>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Card.Content>

                                                        <Card.Content extra>

                                                            <div className='ui two buttons'>

                                                                <Button
                                                                    basic
                                                                    size='small'
                                                                    color='red'
                                                                    fluid
                                                                    icon={'remove circle'}
                                                                    content={'Delete Question'}
                                                                    onClick={(e) => handleOnDelete(e, {questionIndex: index})}
                                                                />
                                                            </div>
                                                        </Card.Content>
                                                    </Card>
                                                </li>
                                            )}
                                        </Draggable>
                                    );
                                })}
                    {provided.placeholder}
                </ul>
        </Container>
    )
}
</Droppable>
</DragDropContext>

</Container>
)
    ;
}


export default QuestionsDragAndDrop;