import React from 'react'
import { Icon, Button, Divider, Container, Segment, Header, Table, Image, Label, Grid, Progress } from 'semantic-ui-react';
import { convertTimeStampToDate, convertTimeStampDifferenceToHoursAndMinutesAndSeconds } from '../utils/convertTimeStampToDate';


const ResultsSummaryTable_IQ = ({ icon, title, session }) => {

    const { test, results } = session?.results?.summary;
    const { startTime, endTime } = session;

    const { score_available, questions_available, score_result, questions_correct, questions_answered } = test;
    const questions_skipped = Number(questions_available) - Number(questions_answered);
    const questions_wrong = Number(questions_available) - Number(questions_correct) - Number(questions_skipped);
    const completion_percentage = (Number(questions_answered) / Number(questions_available) * 100).toFixed(1);
    const correct_percentage = (Number(questions_correct) / Number(questions_available) * 100).toFixed(1);
    const secondsElapsed = ((Number(endTime) - Number(startTime)) / 1000);
    // const timeElapsed = new Date(secondsElapsed * 1000).toISOString().substring(11, 19);

    const timeElapsed = convertTimeStampDifferenceToHoursAndMinutesAndSeconds(endTime, startTime)

    const averageAnswerTime = new Date(secondsElapsed * 1000 / questions_answered).toISOString().substring(11, 19);

    const { score, result } = results;
    return (

        <Segment basic>

            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard' />
                    Results
                </Header>
            </Divider>
            <br />

            <Grid >
                <Grid.Row>
                    <Container>
                        <p className={'py-2'}>{completion_percentage}% Complete</p>
                        <Progress percent={completion_percentage} color={'grey'} />
                    </Container>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} className={'bg-gray-100 py-8 m-2'}>
                        <Container textAlign={'center'}>
                            <Header as={'h2'}>{result}</Header>
                            <p>Result</p>
                        </Container>
                    </Grid.Column>

                    <Grid.Column width={2} className={'bg-gray-100 py-8 m-2'}>
                        <Container textAlign={'center'}>
                            <Header as={'h3'}>{correct_percentage}%</Header>
                            <p>Correct</p>
                        </Container>
                    </Grid.Column>

                    <Grid.Column width={2} className={'py-8 m-2 border-l-4 border-gray-200'}>
                        <Container textAlign={'center'}>
                            <Header as={'h3'}>{`${session?.session_status}`}</Header>
                            <p>Session Status</p>
                        </Container>
                    </Grid.Column>

                    <Grid.Column width={2} className={'py-8 m-2 border-l-4 border-gray-200'}>
                        <Container textAlign={'center'}>
                            <Header as={'h3'}>{`${questions_answered} of ${questions_available}`}</Header>
                            <p>Questions Taken</p>
                        </Container>
                    </Grid.Column>

                    <Grid.Column width={2} className={'py-8 m-2 border-l-4 border-gray-200'}>
                        <Container textAlign={'center'}>
                            <Header as={'h3'}>{timeElapsed}</Header>
                            <p>Time Elapsed</p>
                        </Container>
                    </Grid.Column>

                    <Grid.Column width={2} className={'py-8 m-2 border-l-4 border-gray-200'}>
                        <Container textAlign={'center'}>
                            <Header as={'h3'}>{averageAnswerTime}</Header>
                            <p>Average Answer Time</p>
                        </Container>
                    </Grid.Column>

                    <Grid.Column width={2} className={'py-8 m-2 border-l-4 border-gray-200'}>
                        <Container textAlign={'center'}>
                            <Header as={'h3'}>{convertTimeStampToDate(startTime)}</Header>
                            <p>Attempt Date</p>
                        </Container>
                    </Grid.Column>

                </Grid.Row>
            </Grid>



            <Divider horizontal>
                <Header as='h4'>
                    {icon && <Icon name={icon} />}
                    {title}
                </Header>
            </Divider>
            <br />

            <Table definition celled>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Overall Result
                            </Header>
                        </Table.Cell>
                        <Table.Cell >
                            <Header as={'h4'}>{result}</Header>
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Correct
                            </Header>
                        </Table.Cell>
                        <Table.Cell >
                            <Header as={'h4'}>{correct_percentage}%</Header>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>


            <Table definition celled>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={4}></Table.HeaderCell>
                        <Table.HeaderCell>Questions</Table.HeaderCell>
                        <Table.HeaderCell>Questions %</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Correct
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_correct}</Table.Cell>
                        <Table.Cell>{`${Number(questions_correct / questions_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Incorrect
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_wrong}</Table.Cell>
                        <Table.Cell>{`${Number(questions_wrong / questions_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Skipped
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_skipped}</Table.Cell>
                        <Table.Cell>{`${Number(questions_skipped / questions_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>


                </Table.Body>


                <Table.Footer>

                    <Table.Row>
                        <Table.HeaderCell width={4}>
                        </Table.HeaderCell>
                        <Table.HeaderCell>{questions_available}</Table.HeaderCell>
                        <Table.HeaderCell>100%</Table.HeaderCell>
                    </Table.Row>


                </Table.Footer>

            </Table>

        </Segment>


    )
}

export default ResultsSummaryTable_IQ
