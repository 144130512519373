import React from 'react'
import { Icon, Button, Divider, Container, Segment, Header, Table, Image, Label } from 'semantic-ui-react';


const ResultsSummaryTable_IQ = ({ session }) => {

    const { test, results } = session?.results?.summary;

    const { score_available, questions_available, score_result, questions_correct, questions_answered } = test;
    const questions_skipped = Number(questions_available) - Number(questions_answered);
    const questions_wrong = Number(questions_available) - Number(questions_correct) - Number(questions_skipped);
    const { score, percentile } = results;
    return (

        <Segment basic>

            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='clipboard' />
                    Results
                </Header>
            </Divider>
            <br />

            <Table definition>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                IQ Score
                            </Header>
                        </Table.Cell>
                        <Table.Cell colSpan={2}>{score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Percentile %
                            </Header>
                        </Table.Cell>
                        <Table.Cell colSpan={2}>{(Number(percentile) * 100).toFixed(1)}%</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Top %
                            </Header>
                        </Table.Cell>
                        <Table.Cell colSpan={2}>
                            You scored in the
                            top {Math.round((1 - Number(percentile) + Number.EPSILON) * 1000) / 1000 * 100}%
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Total Questions
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_available}</Table.Cell>
                        <Table.Cell>100%</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Correct
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_correct}</Table.Cell>
                        <Table.Cell>{`${Number(questions_correct / questions_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Wrong
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_wrong}</Table.Cell>
                        <Table.Cell>{`${Number(questions_wrong / questions_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Skipped
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{questions_skipped}</Table.Cell>
                        <Table.Cell>{`${Number(questions_skipped / questions_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Score Available
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{score_available}</Table.Cell>
                        <Table.Cell>100%</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <Header as="h4" textAlign="center">
                                Score Achieved
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{score_result}</Table.Cell>
                        <Table.Cell>{`${Number(score_result / score_available * 100).toFixed(0)}%`}</Table.Cell>
                    </Table.Row>
                    {/*<Table.Row>*/}
                    {/*    <Table.Cell width={4}>*/}
                    {/*        <Header as="h4" textAlign="center">*/}
                    {/*            Score %*/}
                    {/*        </Header>*/}
                    {/*    </Table.Cell>*/}
                    {/*    <Table.Cell>{(Number(score_result) / Number(score_available) * 100).toFixed(1)}%</Table.Cell>*/}
                    {/*</Table.Row>*/}
                </Table.Body>
            </Table>
        </Segment>


    )
}

export default ResultsSummaryTable_IQ
