import React from 'react'
import {Embed, Placeholder} from 'semantic-ui-react';

const VideoEmbed = ({url, placeholder = null}) => {
    try {
        const params = new URL(url).searchParams;
        const id = params.get('v');
        //TODO: consider using Youtube's native embed functionality
        return (
            <div>
                <Embed
                    id={id}
                    placeholder={placeholder}
                    source='youtube'
                />
            </div>
        )
    } catch (error) {
        console.log(error);
        return (
            <Placeholder style={{height: 150, width: 300}}>
                <Placeholder.Image/>
            </Placeholder>
        );
    }
}

export default VideoEmbed
