import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useGetTestQuery } from "../store/testsSlice";
import PlacehoderLoader from "./PlaceholderLoader";
import { Suspense, useEffect, useState } from "react";
import useUserStatus from './useUserStatus';

const PrivateWrapper = ({ children, admin = false, edit = false }) => {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const isAdmin = user?.signInUserSession?.idToken?.payload['cognito:groups'].includes('admin');

    // console.log('userStatus', userStatus)
    // console.log('isLoggedIn', isLoggedIn)

    // console.log('authStatus', authStatus)
    const { pathname } = useLocation();

    const trimPathname = (pathname) => {
        const segments = pathname.split('/');
        segments.pop();
        return segments.join('/')
    }

    // 1. accessing admin protected router
    if (admin) {

        return (
            // <Suspense fallback={<PlacehoderLoader size={'medium'} />} >
                (authStatus === 'authenticated' && isAdmin === true)
                ? (children)
                : (<Navigate to={`/login?redirectTo=${pathname}`} replace />)
            // </Suspense>
        )

    } else {

        return (

            // <Suspense fallback={<PlacehoderLoader size={'medium'} />} >
                (authStatus === 'authenticated')
                ? (children)
                : (<Navigate to={`/login?redirectTo=${pathname}`} replace />)
            // </Suspense>


        )
    }
}

export default PrivateWrapper;