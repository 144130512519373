export const convertTimeStampToDate = (timeStamp) => {

    const isNumber = !isNaN(timeStamp);

    if (isNumber) {
        return (
            new Date(Number(timeStamp))
                .toLocaleDateString('en-us', {
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                })
        )

    } else {
        return timeStamp;
    }
}

export const convertTimeStampToDateAndTime = (timeStamp) => {

    const isNumber = !isNaN(timeStamp);

    if (isNumber) {
        return (
            new Date(Number(timeStamp))
                .toLocaleDateString('en-us', {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: 'numeric',
                    minute: 'numeric',
                })
        )
    } else {
        return timeStamp;
    }

}

export const convertTimeStampDifferenceToHoursAndMinutes = (timestamp1, timestamp2) => {
    const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);
    const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const differenceInMinutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    return (`${differenceInHours} hours and ${differenceInMinutes} minutes`);

}


export const convertTimeStampDifferenceToHoursAndMinutesAndSeconds = (timestamp1, timestamp2) => {
    const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);
    const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const differenceInMinutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const differenceInSeconds = Math.floor((differenceInMilliseconds % (1000 * 60 )) / (1000));

    return (`${differenceInHours}h:${differenceInMinutes}m:${differenceInSeconds}s`);

}