import React, { useState, useEffect } from 'react';
import {
    Container,
    Header,
    Divider,
    Table,
    Icon,
    Image,
    Button,
    Progress,
    Grid,
    Segment,
    Label,
    Card,
    Checkbox
} from 'semantic-ui-react'
import alphabet from '../constants/alphabet';
import { useGetQuestionsQuery, useGetSessionQuery, useSubmitAnswerMutation } from '../store/testsSlice';
import { LAYOUT_VALUE as LV, QUESTION_FORMAT as QF } from '../constants/layouts';
import Swal from 'sweetalert2';
import { QUESTION_TYPE } from "../constants/questionsType";
import { SESSION_STATUS_OPTIONS } from '../constants/statusOptions';

const [SUBMIT_ANSWER, MARK_FAVORITE, ELIMINATE_OPTION] = ['submit_answer', 'mark_favorite', 'eliminate_option'];


const Question = ({ session: { isExam, session_status, id: sessionId, answers, favorites }, question, questionIndex, }) => {


    const [selectionIds, setSelectionIds] = useState([]);
    const [showAnswer, setShowAnswer] = useState(

        // Show answer if:
        // 1. session_status = 'completed'
        // 2. practice mode and there is an existing answer
        (session_status === SESSION_STATUS_OPTIONS.COMPLETED || (!isExam && answers?.[question.question_id].length > 0))
    );

    const [isFavorite, setFavorite] = useState(() => {

        // console.log('isFavorite', favorites?.[question?.question_id])

        if (favorites?.[question?.question_id] === true) {
            return true;
        } else {
            return false;
        }
    });

    // console.log('answers', answers)
    // console.log('question', question)
    console.log('favorites', favorites)


    // console.log('question Content:', question)
    // console.log('show Answers:', (session_status === SESSION_STATUS_OPTIONS.COMPLETED || (!isExam && answers?.[question.question_id].length > 0)))

    const questionHtml = question?.question_text ?? '';
    const safeQuestionHtml = questionHtml;

    // const {
    //     data: session,
    //     refetch: sessionRefetch,
    //     isSuccess: sessionIsSuccess,
    //     isError: sessionIsError
    // }
    //     = useGetSessionQuery(sessionId);

    const [submitAnswer, resultSubmitAnswer] = useSubmitAnswerMutation();

    const [STARTED] = ['Started'];

    // function to check whether option is selected
    // used for checkbox and radio button
    const isOptionSelected = (option_id) => {
        return selectionIds.some((elem) => elem.value === option_id)
    }

    // setSelectionIds when question is loaded
    useEffect(() => {
        // setSelectionId(session?.['answers']?.[question.question_id] || null)
        setSelectionIds(answers?.[question.question_id] || [])
    }, [answers, question]);

    // check whether to show the answer when question is loaded
    useEffect(() => {
        setShowAnswer(prev => {
            return (session_status === SESSION_STATUS_OPTIONS.COMPLETED || (!isExam && answers?.[question.question_id].length > 0))
        })
    });

    // check whether question is favorite
    useEffect(() => {

        // check if favorites object exist
        if (favorites?.[question?.question_id] === true) {
            setFavorite(true);
        } else {
            setFavorite(false);
        }

    },[favorites, question]);

    // INFO: Deprecated version using string instead of array
    // const handleSelectionOnClick = (optionId) => {
    //
    //     // console.log('sessionStatus', session?.['session_status'])
    //     // only edit answer if session.status is started
    //     if (session?.['session_status'] !== STARTED) {
    //         return;
    //     }
    //     // TODO: change to array
    //     setSelectionIds((prevId) => {
    //         if (prevId === optionId) {
    //             submitAnswer({
    //                 sessionId: sessionId,
    //                 questionId: question.question_id,
    //                 optionId: null,
    //                 questionIndex: questionIndex
    //             }).unwrap()
    //                 .then((fullfilled) => {
    //                     Swal.fire({
    //                         position: 'bottom',
    //                         toast: true,
    //                         icon: 'success',
    //                         title: `Selection saved`,
    //                         showConfirmButton: false,
    //                         timer: 3000
    //                     })
    //                 })
    //                 .catch((err) => {
    //                     console.log(err)
    //                     Swal.fire({
    //                         position: 'bottom',
    //                         toast: true,
    //                         icon: 'error',
    //                         title: `Error Saving Selection`,
    //                         showConfirmButton: false,
    //                         timer: 3000
    //                     })
    //
    //                 });
    //             return null;
    //         } else {
    //             submitAnswer({
    //                 sessionId: sessionId,
    //                 questionId: question.question_id,
    //                 optionId: optionId,
    //                 questionIndex: questionIndex
    //             }).unwrap()
    //                 .then((fullfilled) => {
    //                     Swal.fire({
    //                         position: 'bottom',
    //                         toast: true,
    //                         icon: 'success',
    //                         title: `Selection saved`,
    //                         showConfirmButton: false,
    //                         timer: 3000
    //                     })
    //                 })
    //                 .catch((err) => {
    //                     console.log(err)
    //                     Swal.fire({
    //                         position: 'bottom',
    //                         toast: true,
    //                         icon: 'error',
    //                         title: `Error Saving Selection`,
    //                         showConfirmButton: false,
    //                         timer: 3000
    //                     })
    //                 });
    //
    //
    //             return optionId;
    //         }
    //     })
    // }


    const handleOnConfirm = () => {

        // if isExam = false and user confirms selection, submit to server
        if (!isExam) {
            submitAnswer({
                sessionId: sessionId,
                questionId: question.question_id,
                optionId: selectionIds,
                action: SUBMIT_ANSWER,
                questionIndex: questionIndex
            }).unwrap()
                // .then((fullfilled) => {
                //     Swal.fire({
                //         position: 'bottom',
                //         toast: true,
                //         icon: 'success',
                //         title: `Selection saved`,
                //         showConfirmButton: false,
                //         timer: 3000
                //     })
                // })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'error',
                        title: `Error Saving Selection`,
                        showConfirmButton: false,
                        timer: 3000
                    })

                });
        }

    }

    const handleOnReset = () => {

        // if isExam = false and user confirms selection, submit to server
        if (!isExam) {
            setSelectionIds(prev => [])
            submitAnswer({
                sessionId: sessionId,
                questionId: question.question_id,
                optionId: [],
                action: SUBMIT_ANSWER,
                questionIndex: questionIndex
            }).unwrap()
                // .then((fullfilled) => {
                //     Swal.fire({
                //         position: 'bottom',
                //         toast: true,
                //         icon: 'success',
                //         title: `Selection saved`,
                //         showConfirmButton: false,
                //         timer: 3000
                //     })
                // })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'error',
                        title: `Error Saving Selection`,
                        showConfirmButton: false,
                        timer: 3000
                    })

                });
        }

    }

    const handleSelectionOnClick = (optionId) => {

        if (session_status !== STARTED) {
            return;
        }

        // change to array
        setSelectionIds((prevArr) => {

            const isIncludedIndex = prevArr.findIndex(elem => elem.value === optionId);

            if (isIncludedIndex >= 0) {
                // eliminate optionId
                const filteredArr = prevArr.filter((elem) => elem.value !== optionId);


                // reset showAnswer if selection is changed
                if (!isExam) {
                    setShowAnswer(false);
                }

                // if isExam = true, submit response to server
                // if isExam = false, wait for confirmation button before submitting to server
                if (isExam) {

                    submitAnswer({
                        sessionId: sessionId,
                        questionId: question.question_id,
                        optionId: filteredArr,
                        action: SUBMIT_ANSWER,
                        questionIndex: questionIndex
                    }).unwrap()
                        // .then((fullfilled) => {
                        //     Swal.fire({
                        //         position: 'bottom',
                        //         toast: true,
                        //         icon: 'success',
                        //         title: `Selection saved`,
                        //         showConfirmButton: false,
                        //         timer: 3000
                        //     })
                        // })
                        .catch((err) => {
                            console.log(err)
                            Swal.fire({
                                position: 'bottom',
                                toast: true,
                                icon: 'error',
                                title: `Error Saving Selection`,
                                showConfirmButton: false,
                                timer: 3000
                            })

                        });
                }

                return filteredArr;

            } else {
                // add optionId

                // check whether number of options selected reached size limit and return if so
                const questionOptionsLength = QUESTION_TYPE[question?.type].length;

                // if one-selection question only, set it to the new value
                let copyArr;

                if (questionOptionsLength && questionOptionsLength <= prevArr.length) {
                    if (questionOptionsLength === 1) {
                        copyArr = [{ value: optionId, type: 'option_id' }];
                    } else {
                        return prevArr;
                    }
                } else {
                    // const obj = {value: optionId, type: 'option_id'};
                    // console.log('obj', obj);
                    copyArr = [...prevArr, { value: optionId, type: 'option_id' }];
                    // console.log('copyArr', copyArr)
                }


                // if isExam = true, submit response to server
                // if isExam = false, wait for confirmation button before submitting to server

                if (isExam) {
                    submitAnswer({
                        sessionId: sessionId,
                        questionId: question.question_id,
                        optionId: copyArr,
                        action: SUBMIT_ANSWER,
                        questionIndex: questionIndex
                    }).unwrap()
                        // .then((fullfilled) => {
                        //     Swal.fire({
                        //         position: 'bottom',
                        //         toast: true,
                        //         icon: 'success',
                        //         title: `Selection saved`,
                        //         showConfirmButton: false,
                        //         timer: 3000
                        //     })
                        // })
                        .catch((err) => {
                            console.log(err)
                            Swal.fire({
                                position: 'bottom',
                                toast: true,
                                icon: 'error',
                                title: `Error Saving Selection`,
                                showConfirmButton: false,
                                timer: 3000
                            })
                        });

                }

                return copyArr;
            }

        })
    }

    const handleOnMark = () => {

        setFavorite((prev) => {
            submitAnswer({
                sessionId: sessionId,
                questionId: question.question_id,
                action: MARK_FAVORITE,
                isFavorite: !prev,
                questionIndex: questionIndex
            }).unwrap()
                // .then((fullfilled) => {
                //     Swal.fire({
                //         position: 'bottom',
                //         toast: true,
                //         icon: 'success',
                //         title: `Selection saved`,
                //         showConfirmButton: false,
                //         timer: 3000
                //     })
                // })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'error',
                        title: `Error Saving Selection`,
                        showConfirmButton: false,
                        timer: 3000
                    })

                });

            return !prev;
        });
    }

    return (
        <>
            <Container className='border border-grey-400 rounded-2xl py-8 px-4 '>
                <Container fluid>
                    <Grid
                        columns={LV[question?.layout]?.['grid_cols'] ?? LV['compact']?.['grid_cols']}
                        stackable
                    >

                        <Grid.Row >
                            <Label
                                attached='top right'
                                color='none'
                                style={{ background: '#fff', marginTop: '0.5rem' }}
                            >
                                <Icon size={'large'} name={'star'} color={isFavorite ? 'yellow' : 'grey'} className={'absolute top-1 right-6 cursor-pointer'} onClick={handleOnMark} />
                            </Label>
                            <Grid.Column
                                key={'abc1'}
                                width={LV[question?.layout]?.['question_col'] ?? LV['compact']?.['question_col']}
                            // className={'flex items-center'}
                            >
                                <div className={'flex items-start mt-4 h-full'}>

                                    <Container dangerouslySetInnerHTML={{ __html: safeQuestionHtml }} className={'text-2xl md:text-xl sm:text-base'} />
                                    <Image src={question?.question_image} />
                                </div>
                            </Grid.Column>

                            <Grid.Column
                                key={'abc2'}
                                width={LV[question?.layout]?.['answers_col'] ?? LV['compact']?.['answers_col']}
                            >
                                {question?.difficulty && <Label attached='bottom right'>{question.difficulty}</Label>}
                                <Grid
                                    columns={LV[question?.layout]?.['cols_per_answer_col'] ?? LV['compact']?.['cols_per_answer_col']}
                                    stackable
                                >
                                    {
                                        question?.type &&
                                        <div className={'text-base'}>
                                            Select <span className={'text-semibold'}> {QUESTION_TYPE[question?.type].length} </span> option{QUESTION_TYPE[question?.type].length > 1 ? 's' : ''}:
                                        </div>
                                    }
                                    {/*TODO: Instead of options, add a list of <input> components to capture text or number input for text_input type of questions*/}
                                    {/*TODO: Add number of choices to be made and validate*/}
                                    {question?.options?.map(({ option_id, option_text, option_image, option_header }, index) => {
                                        return (
                                            <Grid.Column
                                                key={option_id}
                                                width={LV[question?.layout]?.['answer_subCol'] ?? LV['compact']?.['answer_subCol']}
                                            >
                                                <Card
                                                    fluid
                                                    // raised
                                                    key={option_id}
                                                    className={
                                                        showAnswer
                                                            ? (
                                                                // if item is selected 
                                                                isOptionSelected(option_id)
                                                                    ? question?.answer?.values.some((elem) => elem.value === option_id)
                                                                        // and item is correct
                                                                        ? 'outline-4 outline-green-700 outline cursor-pointer'
                                                                        // and item is NOT correct
                                                                        : 'outline-4 outline-red-700 outline cursor-pointer'
                                                                    // item not selected
                                                                    : question?.answer?.values.some((elem) => elem.value === option_id)
                                                                        // and item is correct
                                                                        ? 'outline-1 outline-green-700 outline cursor-pointer'
                                                                        // and item is not correct
                                                                        : 'cursor-pointer'
                                                            )
                                                            : (
                                                                selectionIds && selectionIds.some((elem) => elem.value === option_id)
                                                                    ? 'outline-4 outline-black outline cursor-pointer'
                                                                    : 'cursor-pointer'
                                                            )
                                                    }
                                                    onClick={() => handleSelectionOnClick(option_id)}
                                                >
                                                    <Card.Content>
                                                        <Grid textAlign={'left'}
                                                            className={
                                                                showAnswer
                                                                    ? (
                                                                        // if item is selected 
                                                                        isOptionSelected(option_id)
                                                                            ? question?.answer?.values.some((elem) => elem.value === option_id)
                                                                                // and item is correct
                                                                                ? 'bg-green-200'
                                                                                // and item is NOT correct
                                                                                : 'bg-red-100'
                                                                            // item not selected
                                                                            : question?.answer?.values.some((elem) => elem.value === option_id)
                                                                                // and item is correct
                                                                                ? 'bg-green-100'
                                                                                // and item is not correct
                                                                                : 'bg-slate-100'
                                                                    )
                                                                    : (
                                                                        selectionIds && isOptionSelected(option_id)
                                                                            ? 'bg-slate-100'
                                                                            : ''
                                                                    )
                                                            }
                                                        >
                                                            <Grid.Column stretched
                                                                width={LV[question?.layout]?.['option_checkmark'] ?? LV['compact']?.['option_checkmark']}
                                                                style={{ padding: '1em 0.3em', textAlign: 'center' }}
                                                            >
                                                                {/* Stop checkbox and label from getting wrapped */}
                                                                <Container className={'whitespace-nowrap'}>

                                                                    {
                                                                        (question?.type === 'multiple_answer_1'
                                                                            || question?.type === 'multiple_choice')
                                                                            ? (
                                                                                showAnswer
                                                                                    ? (
                                                                                        // if item is selected 
                                                                                        isOptionSelected(option_id)
                                                                                            ? question?.answer?.values.some((elem) => elem.value === option_id)
                                                                                                // and item is correct
                                                                                                ? <Icon color={'green'} name={'checkmark'} size={'large'} />
                                                                                                // and item is NOT correct
                                                                                                : <Icon color={'red'} name={'delete'} size={'large'} />
                                                                                            // item not selected
                                                                                            : <Checkbox
                                                                                                radio
                                                                                                checked={isOptionSelected(option_id)}
                                                                                                readOnly
                                                                                            />
                                                                                    )
                                                                                    : (
                                                                                        <Checkbox
                                                                                            radio
                                                                                            checked={isOptionSelected(option_id)}
                                                                                            readOnly
                                                                                        />
                                                                                    )
                                                                            )
                                                                            : (
                                                                                <Checkbox
                                                                                    checked={isOptionSelected(option_id)}
                                                                                    readOnly />
                                                                            )
                                                                    }
                                                                    < span class='space' />
                                                                    <Label circular size='large' color={
                                                                        showAnswer
                                                                            ? (
                                                                                // if item is selected 
                                                                                isOptionSelected(option_id)
                                                                                    ? question?.answer?.values.some((elem) => elem.value === option_id)
                                                                                        // and item is correct
                                                                                        ? 'green'
                                                                                        // and item is NOT correct
                                                                                        : 'red'
                                                                                    // item not selected
                                                                                    : question?.answer?.values.some((elem) => elem.value === option_id)
                                                                                        // and item is correct
                                                                                        ? 'green'
                                                                                        // and item is not correct
                                                                                        : ''
                                                                            )
                                                                            : ('')
                                                                    }>
                                                                        {`${alphabet(index)} `}
                                                                    </Label>
                                                                    <span class='space' />
                                                                </Container>
                                                            </Grid.Column>

                                                            <Grid.Column
                                                                stretched
                                                                width={LV[question?.layout]?.['option_text'] ?? LV['compact']?.['option_text']}
                                                                style={{ padding: '1em 0.3em', margin: 'auto' }}
                                                            >
                                                                <Container textAlign={'left'} stretched >

                                                                    {/* <Card.Header className='bg-grey-300'> */}


                                                                    {option_header &&
                                                                        <Header as={'h4'}>
                                                                            {option_header}
                                                                        </Header>
                                                                    }
                                                                    {/* </Card.Header> */}

                                                                    {
                                                                        option_text &&
                                                                        // <Card.Description>
                                                                        // option_text
                                                                        <Container dangerouslySetInnerHTML={{ __html: option_text }} className={'text-base'} />
                                                                        // </Card.Description>
                                                                    }

                                                                    {option_image &&
                                                                        <Image
                                                                            wrapped ui={false}
                                                                            size={'large'} src={option_image}
                                                                        />
                                                                    }
                                                                </Container>

                                                            </Grid.Column>
                                                        </Grid>


                                                    </Card.Content>

                                                </Card>

                                            </Grid.Column>
                                        )
                                    })}

                                </Grid>
                            </Grid.Column>
                        </Grid.Row>



                    </Grid>
                </Container>
            </Container>


            {
                !isExam &&
                <div className={'m-2'}>

                    <Button
                        onClick={handleOnConfirm}
                        type='button'
                        color='black'
                        icon
                        labelPosition='left'
                    >
                        Confirm
                        <Icon name='check' />
                    </Button >
                    <Button
                        onClick={handleOnReset}
                        type='button'
                        icon
                        labelPosition='left'
                    >
                        Reset
                        <Icon name='undo' />
                    </Button >
                </div>
            }


            {showAnswer &&
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='check square outline' />
                        Answer & Explanation
                    </Header>
                </Divider>
            }

            {
                showAnswer &&
                <Container className='border border-grey-400 rounded-2xl py-8 px-4'>
                    {/* <Container> */}

                    {question?.answer?.answer_image &&
                        <Image
                            wrapped ui={false}
                            size={'large'} src={question?.answer?.answer_image}
                        />
                    }
                    {
                        question?.answer?.answer_text &&
                        // <Card.Description>
                        // option_text
                        <Container dangerouslySetInnerHTML={{ __html: question?.answer?.answer_text }} className={'text-base p-4'} />
                        // </Card.Description>
                    }
                    {/* </Container> */}
                </Container>

            }
        </>

    )
}

export default Question
