import React from 'react'
import { Container, Header, Table } from 'semantic-ui-react'
import PlaceholderComponent from './PlaceholderComponent'
import { useGetFullTestQuery } from '../store/testsSlice'

// TODO implement

const Scoring = ({ testId }) => {

    const {
        data: test,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);

    return (
        isTestSuccess
            ? (
                <Container>
                    {/* TODO make it Table definition type */}
                    <Table celled>
                        <Table.Body>

                            <Table.Row>
                                <Table.Cell width={4}>Scoring Type</Table.Cell>
                                <Table.Cell>{test?.['config']?.['scoring']?.['type']}</Table.Cell>
                            </Table.Row>

                            {
                                test?.['config']?.['scoring'] &&
                                Object.entries(test?.['config']?.['scoring'])
                                    .map(([key, val], index) => {

                                        if (key === 'type') {
                                            // skip
                                        } else if (key === 'scoring_table') {
                                            // console.log('key', key)
                                            // TODO implement table
                                            return (

                                                <Table.Row key={index}>
                                                    <Table.Cell>{key}</Table.Cell>
                                                    <Table.Cell fluid>

                                                        <Container fluid>
                                                            <Table striped celled stackable>
                                                                <Table.Header>
                                                                    <Table.Row key={'random1'}>
                                                                        <Table.HeaderCell>
                                                                            Points
                                                                        </Table.HeaderCell>
                                                                        <Table.HeaderCell>
                                                                            Score
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>

                                                                <Table.Body>



                                                                    {val.map((row, index) => {
                                                                        // console.log('row', row)
                                                                        return (
                                                                            <Table.Row key={index}>
                                                                                {Object.entries(row)
                                                                                    .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
                                                                                    .map(([key, value], idx) => {
                                                                                        return (
                                                                                            <Table.Cell>
                                                                                                {value}
                                                                                            </Table.Cell>
                                                                                        )
                                                                                    })}
                                                                            </Table.Row>
                                                                        );
                                                                    })}



                                                                </Table.Body>
                                                            </Table>
                                                        </Container>

                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        } else {
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>{key}</Table.Cell>
                                                    <Table.Cell>{val}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }

                                    })
                            }
                        </Table.Body>
                    </Table >
                </Container >
            )
            : (<PlaceholderComponent />)

    )
}

export default Scoring
