export const LAYOUT_VALUE = {
  optionsOnly: {
    grid_cols: 2,
    question_col: 11,
    answers_col: 5,
    cols_per_answer_col: 1,
    answer_subCol: 16,
    option_checkmark: 5,
    option_text:11,
  },
  tiny: {
    grid_cols: 2,
    question_col: 7,
    answers_col: 9,
    cols_per_answer_col: 3,
    answer_subCol: 5,
    option_checkmark: 4,
    option_text:12,
  },
  mini: {
    grid_cols: 2,
    question_col: 8,
    answers_col: 8,
    cols_per_answer_col: 2,
    answer_subCol: 8,
    option_checkmark: 4,
    option_text:12,
  },
  mini_spaced: {
    grid_cols: 2,
    question_col: 6,
    answers_col: 10,
    cols_per_answer_col: 2,
    answer_subCol: 8,
    option_checkmark: 3,
    option_text:13,
  },
  compact: {
    grid_cols: 2,
    question_col: 8,
    answers_col: 8,
    cols_per_answer_col: 1,
    answer_subCol: 16,
    option_checkmark: 3,
    option_text:13,
  },
  compact_spaced: {
    grid_cols: 2,
    question_col: 6,
    answers_col: 10,
    cols_per_answer_col: 1,
    answer_subCol: 16,
    option_checkmark: 3,
    option_text:13,
  },
  medium: {
    grid_cols: 1,
    question_col: 16,
    answers_col: 16,
    cols_per_answer_col: 2,
    answer_subCol: 8,
    option_checkmark: 2,
    option_text:14,
  },
  full: {
    grid_cols: 1,
    question_col: 16,
    answers_col: 16,
    cols_per_answer_col: 1,
    answer_subCol: 16,
    option_checkmark: 2,
    option_text:14,
  },
}

export const LAYOUTS = [
  {
    key: 'optionsOnly',
    text: 'Options Only',
    value: 'optionsOnly'
  },
  {
    key: 'tiny',
    text: 'Tiny Layout',
    value: 'tiny'
  },
  {
    key: 'mini',
    text: 'Mini Layout',
    value: 'mini'
  },
  {
    key: 'mini_spaced',
    text: 'Mini Even-Spaced Layout',
    value: 'mini_spaced'
  },
  {
    key: 'compact',
    text: 'Compact Layout',
    value: 'compact'
  },
  {
    key: 'compact_spaced',
    text: 'Compact Even-Spaced Layout',
    value: 'compact_spaced'
  },
  {
    key: 'medium',
    text: 'Medium Layout',
    value: 'medium'
  },
  {
    key: 'full',
    text: 'Full Layout',
    value: 'full'
  },
];

export const QUESTION_FORMAT = {
  H3: 'h3',
  H2: 'h2',
  HTML: 'html',
}
