import React, {useReducer, useEffect, createRef} from 'react';
import {Container,} from 'semantic-ui-react';
import {useParams} from "react-router-dom";
import {USER_TEST_SECTIONS} from "../constants/userTestSections";
import UserTestView from "./UserTestView";
import InstructionsViewUser from './InstructionsViewUser';
import OverviewUser from './OverviewUser';
import RatingView from "./RatingView";
import RatingEditor from "./RatingEditor";
import TestResultsPage from "./TestResultsPage";
import ReactGA from "react-ga4";


const reducer = (state, action) => {

    switch (action.type) {
        case USER_TEST_SECTIONS.INSTRUCTIONS.value: {
            // TODO add test data
            const DisplayComponent = (props) => <InstructionsViewUser  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case USER_TEST_SECTIONS.OVERVIEW.value: {
            const DisplayComponent = (props) => <OverviewUser  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case USER_TEST_SECTIONS.RESULTS.value: {
            // TODO Implement Results page
            const DisplayComponent = (props) => <TestResultsPage  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
        case USER_TEST_SECTIONS.RATING.value: {
            // TODO Implement Rating page
            const DisplayComponent = (props) => <RatingEditor  {...props} testId={action.payload}/>
            return {
                DisplayComponent,
                scope: action.type,
                testId: action.payload,
            };
        }
    }
    throw Error('Unknown action: ' + action.type);

}

const UserTestForm = ({section}) => {
    const {testId} = useParams();

    const [viewContent, viewContentDispatch] = useReducer(
        reducer,
        // initialArg
        {type: section, payload: testId},
        // Initilizer Function, gets called with initialArg
        (initialState) => reducer(null, initialState)
    );

    const handleItemClick = (e, {name}) => {

        switch (name) {
            case USER_TEST_SECTIONS.INSTRUCTIONS.value:
                viewContentDispatch(({type: USER_TEST_SECTIONS.INSTRUCTIONS.value, payload: testId}))
                break;
            case USER_TEST_SECTIONS.OVERVIEW.value:
                viewContentDispatch(({type: USER_TEST_SECTIONS.OVERVIEW.value, payload: testId}))
                break;
            case USER_TEST_SECTIONS.RESULTS.value:
                viewContentDispatch(({type: USER_TEST_SECTIONS.RESULTS.value, payload: testId}))
                break;
            case USER_TEST_SECTIONS.RATING.value:
                viewContentDispatch(({type: USER_TEST_SECTIONS.RATING.value, payload: testId}))
                break;
            default:
                console.log(`Error : '${name}' menu item not recognized`)
                return;
        }

    }
    useEffect(() => {
        handleItemClick(null, {name: section})

        ReactGA.send({
            hitType: "pageview", page: window.location.pathname + window.location.search,
            title: section
        });

    }, [section]);

    return (
        <Container style={{marginTop: '2rem', marginBottom: '2rem'}}>

            <UserTestView {...viewContent}/>

        </Container>
    );
}

export default UserTestForm
