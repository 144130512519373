import React from 'react'
import { Segment, Button, Icon, ButtonGroup, Checkbox, Grid, Label } from 'semantic-ui-react';

const SessionNavBar = ({
    session, questionIndex, questionCount,
    handleComplete, handleCancel, handleStart,
    handlePrevious, handleNext, handleShowProgress, timer
}) => {
    return (
        <nav className="bg-white py-3 px-3 sm:px-3 md:px-3 border rounded-xl">
            <div className={'flex flex-wrap md:justify-between items-center justify-center gap-2'}>
                <div className="flex space-x-4">
                    <ButtonGroup>
                        <Button
                            // icon
                            // labelPosition='left'
                            // color='black'
                            compact
                            basic
                            onClick={handleComplete}
                            disabled={(session?.['session_status'] !== 'Started')}
                        >
                            <Icon name='flag checkered' />
                            Complete
                        </Button>
                        <Button compact basic onClick={handleCancel}>
                            <Icon name='cancel' />
                            Cancel
                        </Button>
                        <Button basic disabled compact>
                            {`${session.isTimed ? 'Timed' : 'Untimed'} ${session.isExam ? 'Exam' : 'Practice'}`}
                        </Button>
                        <Button circular basic compact onClick={handleShowProgress}>
                            <div className="m-auto">
                                <Icon name='th' />
                            </div>
                        </Button>
                        {timer}
                    </ButtonGroup>
                </div>
                <div className="flex flex-col md:flex-row md:space-x-4 items-start md:items-center">
                    <ButtonGroup className='flex-row items-end'>

                        <Button icon labelPosition='left' onClick={handlePrevious}>
                            <Icon name='left arrow' />
                            Previous
                        </Button>
                        {
                            questionIndex >= 0
                                ? <>
                                    <Button disabled={questionIndex >= questionCount - 1} icon labelPosition='right' color='black'
                                        onClick={handleNext}>
                                        Next
                                        <Icon name='right arrow' />
                                    </Button>
                                </>
                                : <>
                                    <Button icon labelPosition='right' color='black' onClick={handleStart}>
                                        Start
                                        <Icon name='right arrow' />
                                    </Button>
                                </>
                        }
                    </ButtonGroup>
                </div>
            </div>
        </nav>


        // <Grid stackable celled>
        //     <Grid.Row>
        //         <Grid.Column width={8}>
        //             <ButtonGroup>
        //                 <Button
        //                     icon
        //                     labelPosition='left'
        //                     color='black'
        //                     onClick={handleComplete}
        //                     disabled={(session?.['session_status'] !== 'Started')}
        //                 >
        //                     <Icon name='flag checkered' />
        //                     Complete
        //                 </Button>
        //                 <Button basic onClick={handleCancel}>
        //                     <Icon name='cancel' />
        //                     Cancel
        //                 </Button>
        //                 {timer}
        //             </ButtonGroup>
        //         </Grid.Column>
        //         <Grid.Column>
        //             <ButtonGroup className='flex-row items-end'>

        //                 <Button icon labelPosition='left' onClick={handlePrevious}>
        //                     <Icon name='left arrow' />
        //                     Previous
        //                 </Button>
        //                 {
        //                     questionIndex >= 0
        //                         ? <>
        //                             <Button disabled={questionIndex >= questionCount - 1} icon labelPosition='right' color='black'
        //                                 onClick={handleNext}>
        //                                 Next
        //                                 <Icon name='right arrow' />
        //                             </Button>
        //                         </>
        //                         : <>
        //                             <Button icon labelPosition='right' color='black' onClick={handleStart}>
        //                                 Start
        //                                 <Icon name='right arrow' />
        //                             </Button>
        //                         </>
        //                 }
        //             </ButtonGroup>

        //         </Grid.Column>
        //     </Grid.Row>
        // </Grid>
        // <Segment.Group horizontal>
        //     <Segment basic>

        //     </Segment>
        //     <Segment basic className={'flex items-center justify-end justify-items-end'}>

        //     </Segment>
        // </Segment.Group>
    )
}

export default SessionNavBar