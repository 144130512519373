import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {
    useCreateRatingMutation, useDeleteRatingMutation, useEditRatingMutation,
    useEditTestMutation,
    useGetFullTestQuery,
    useGetRatingsQuery
} from "../store/testsSlice";
import Swal from "sweetalert2";
import {
    Button,
    Container,
    Divider,
    Form,
    Grid, Header,
    Icon,
    Image,
    Input,
    Label,
    List,
    Loader,
    Rating, Segment
} from "semantic-ui-react";
import HookFormControlledImagePicker from "./HookFormControlledImagePicker";
import HookFormControlledField from "./HookFormControlledField";
import HookFormControlledRating from "./HookFormControlledRating";
import HookFormControlledTextEditor from "./HookFormControlledTextEditor";
import {Auth} from 'aws-amplify';
import {useLoaderData, useParams} from "react-router-dom";

// const userId = (await Auth.currentAuthenticatedUser())?.username;

export const loader = async () => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userId = currentUser.username;
        // You can also access other user attributes here
        // For example: currentUser.attributes.email
        return userId;
    } catch (error) {
        console.log('Error getting current user: ', error);
        return null;
    }
}

const CardEditor = ({testId}) => {

    const userId = useLoaderData()

    const searchQuery = new URLSearchParams({
        testId,
        userId,
    }).toString();

    const {
        data: ratingData,
        isLoading: isRatingLoading,
        isError: isRatingError,
        error: ratingError,
        isSuccess: isRatingSuccess,
        refetch: ratingRefetch
    } = useGetRatingsQuery(searchQuery);

    const [createRating, {
        data: createRatingData,
        error: createRatingError,
        isLoading: createRatingIsLoading,
        isSuccess: createRatingIsSuccess,
        isError: createRatingIsError
    }] = useCreateRatingMutation();

    const [editRating, {
        data: editRatingData,
        error: editRatingError,
        isLoading: editRatingIsLoading,
        isSuccess: editRatingIsSuccess,
        isError: editRatingIsError
    }] = useEditRatingMutation();

    const [deleteRating, {
        data: deleteRatingData,
        error: deleteRatingError,
        isLoading: deleteRatingIsLoading,
        isSuccess: deleteRatingIsSuccess,
        isError: deleteRatingIsError
    }] = useDeleteRatingMutation();


    const {handleSubmit, getValues, reset, control, watch, setValue, formState: {isDirty}} = useForm({
        values: structuredClone(ratingData?.[0]),
        resetOptions: {
            keepDirtyValues: false, // keep dirty fields unchanged, but update defaultValues
        },
    })

    const handleOnDeleteRating = () => {
        const id = getValues('id');
        deleteRating(id)
            .unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Rating Deleted`,
                    showConfirmButton: false,
                    timer: 3000
                })
                // force page fresh
                // window.location.reload();
                // ratingRefetch();
                reset({
                    rating: 5,
                    comment: '',
                })
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Delete Error: ${JSON.stringify(err)}`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    const handleOnFormSubmit = (data) => {

        const {rating, comment} = data;
        const body = {
            ...data,
            testId,
        }

        if (ratingData?.[0]) {
            // if prior rating exists then editRating
            editRating(body)
                .unwrap()
                .then((fullfilled) => {
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'success',
                        title: `Rating saved`,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
                .catch((err) => {
                    console.log(err);
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'error',
                        title: `Saving Error: ${JSON.stringify(err)}`,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
        } else {
            // createRating
            // console.log('triggered createRating')

            createRating(body)
                .unwrap()
                .then((fullfilled) => {
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'success',
                        title: `Rating saved`,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
                .catch((err) => {
                    console.log(err);
                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'error',
                        title: `Saving Error: ${JSON.stringify(err)}`,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
        }
        // console.log(body)
    }


    return (
        <Container>

            <Form
                onSubmit={handleSubmit(handleOnFormSubmit)}
                loading={isRatingLoading}
            >

                <HookFormControlledRating
                    label={'Rating'}
                    name={'rating'}
                    control={control}
                    disabled={false}
                    required={true}
                    size={'massive'}
                    icon={'star'}
                    maxRating={5}
                    defaultRating={5}
                />

                <HookFormControlledTextEditor
                    name={'comment'}
                    control={control}
                    label={'Comment'}
                    required={false}
                />

                <Button
                    type='submit'
                    color='green'
                    // disabled={}
                    className={'mx-2'}
                >
                    <>
                        {/*{!editTestIsLoading ? <Icon name='save outline left'/> : <Loader inline active size={'mini'}/>}*/}
                        Save Rating
                    </>
                </Button>

                <Button
                    type='button'
                    onClick={handleOnDeleteRating}
                    color='red'
                    disabled={!ratingData?.[0]}
                    className={'mx-2'}
                >
                    <>
                        {/*{!editTestIsLoading ? <Icon name='save outline left'/> : <Loader inline active size={'mini'}/>}*/}
                        Delete Rating
                    </>
                </Button>

                {/*{editTestIsError &&*/}
                {/*    <Segment><Header as={'h3'}>{JSON.stringify(editTestError)}</Header></Segment>}*/}

            </Form>

        </Container>
    );
};

export default CardEditor;
