import React from 'react'
import {
    Button,
    Container,
    Icon,
    Image,
    Item,
    Label,
    List,
    Rating,
    Loader,
    Divider,
    Segment,
    Grid,
    Card
} from 'semantic-ui-react';
import {redirect, useNavigate, useLocation, useParams} from "react-router-dom";
import {useGetFullTestQuery} from '../store/testsSlice';
import {STATUS_OPTIONS_COLOR, STATUS_OPTIONS_LOOKUP} from '../constants/statusOptions';
import {convertTimeStampToDateAndTime} from "../utils/convertTimeStampToDate";
import ReactGA from "react-ga4";


const TestCard = ({
                      test,
                      authorTestCard = false,
                      modal: {mountModal = null, setContent = null},
                      isPurchaseDisabled = true
                  }) => {

    const handleOnPurchase = () => {

        ReactGA.event({
            category: "purchase",
            action: "click-to-purchase",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        setContent(test);
        mountModal(true);
    }
    const handleOnLogin = () => {
        navigate(`/login?redirectTo=/`)
    }


    const {pathname} = useLocation()
    const navigate = useNavigate();
    // const { data: test, isLoading: isTestLoading, isError: isTestError, error: testError, isSuccess: isTestSuccess, refetch: testRefetch } = useGetFullTestQuery(testId);

    const handleOnClick = () => {

        ReactGA.event({
            category: "testcard",
            action: "click",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        if (authorTestCard) return;

        pathname.includes('/tests')
            ? navigate(`${test.id}`)
            : navigate(`/tests/${test.id}`);
    }

    return (
        <Segment>
            {
                authorTestCard &&
                <Label as='a' color={STATUS_OPTIONS_COLOR[test?.['publish_status']]} ribbon>
                    {`Version: [${convertTimeStampToDateAndTime(test?.['version'])}] - Status: [${STATUS_OPTIONS_LOOKUP[test?.['publish_status']]}]`}
                </Label>
            }
            <Grid stackable>
                <Grid.Column
                    width={8}
                    columns={2}
                    className={
                        !authorTestCard ? 'cursor-pointer' : ''
                    }
                    onClick={handleOnClick}
                >
                    <Image
                        rounded
                        centered
                        src={test?.['product_card']?.['image']}
                        className={'object-cover min-w-full max-h-[26rem] my-3'}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Card fluid style={{boxShadow: 'none'}}>

                        <Card.Content
                            className={!authorTestCard ? 'border-0 cursor-pointer' : 'border-0'}
                            width={8}
                            onClick={handleOnClick}
                        >
                            <Card.Header>{test?.['product_card']?.['header']}</Card.Header>
                            <Card.Meta>
                                <Image avatar src='https://react.semantic-ui.com/images/avatar/small/rachel.png'/>
                                <span className='author' style={{color: 'blue'}}>Quizby Team, Intelligence Testing Domain</span>
                            </Card.Meta>
                            <Card.Description>
                                {test?.['product_card']?.['description']}
                            </Card.Description>

                        </Card.Content>

                        <Card.Content
                            className={!authorTestCard ? 'border-0 cursor-pointer' : 'border-0'}
                            width={8}
                            onClick={handleOnClick}
                        >
                            <List>
                                {test?.['product_card']?.['description_bullet_points']
                                    .map((item, index) => {
                                        if (index === 0) {
                                            return (
                                                <List.Header key={index}>{item?.text}</List.Header>
                                            );
                                        } else {
                                            return (
                                                <List.Item key={index}>
                                                    <List.Icon name='check'/>
                                                    <List.Content>{item?.text}</List.Content>
                                                </List.Item>
                                            );
                                        }
                                    })
                                }
                            </List>

                            <List>
                                <List.Item>
                                    <List.Content>
                                        <span
                                            style={{color: 'orange', 'fontWeight': 'bold', 'fontSize': '1.2rem'}}
                                        >
                                            {(Math.round((Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])) * 100) / 100).toFixed(1)}
                                        </span>
                                        <Rating
                                            disabled icon='star'
                                            defaultRating={Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])}
                                            maxRating={5}
                                        />
                                        <span>
                                            ({Number(test?.['stats']?.['ratings'])})
                                        </span>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <Label.Group className={'mt-2'}>
                                        {
                                            test?.['product_card']?.['tags'] &&
                                            Object.entries(test?.['product_card']?.['tags'])
                                                .map(([key, val], index) => {
                                                    if (key === 'sale') {
                                                        return (
                                                            <Label key={index} color='red'>{val}</Label>
                                                        );
                                                    } else if (key === 'language') {
                                                        return (
                                                            <Label key={index}><Icon name='globe'/>{val}</Label>
                                                        );
                                                    } else {
                                                        return (
                                                            <Label key={index}>{val}</Label>
                                                        );
                                                    }
                                                })

                                        }
                                    </Label.Group>
                                </List.Item>

                            </List>

                        </Card.Content>

                        <Card.Content style={{border: 'none'}}>
                            <div className='ui two buttons'>
                                {
                                    test?.isPurchased
                                        ? (
                                            <Button
                                                color={'purple'}
                                                icon={'checkmark'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate(`/tests/${test?.id}/content`);
                                                }}
                                            >
                                                Already Purchased - Go To Test
                                            </Button>
                                        )
                                        : (
                                            <Button basic color='green'
                                                    onClick={isPurchaseDisabled ? handleOnLogin : handleOnPurchase}
                                                    disabled={authorTestCard
                                                        // || isPurchaseDisabled
                                                    }
                                            >
                                                <Icon name='cart plus'/>
                                                {`$${Number(test?.['product_card']?.['price']).toFixed(2)} `}
                                                {isPurchaseDisabled ? 'Login to Purchase' : 'Purchase'}
                                            </Button>
                                        )
                                }

                                <Button basic color='blue' disabled>
                                    <Icon name='heart'/>
                                    Wishlist
                                    (coming soon)
                                </Button>
                            </div>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default TestCard
