import React, { useState } from 'react'
import { Container, List } from 'semantic-ui-react'
import PurchaseModal from './PurchaseModal'
import TestCard from './TestCard'
import { useAuthenticator } from "@aws-amplify/ui-react";
import TestCardV4 from './TestCardV4';

const ListingProducts = ({ tests }) => {

    const [isModalMounted, setIsModalMounted] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const { authStatus } = useAuthenticator(context => [context.authStatus]);


    return (
        <Container>
            {/* Purchase Model to execute transaction, managed by isModalMounted */}
            {isModalMounted && <PurchaseModal
                open={isModalMounted}
                mountModal={setIsModalMounted}
                test={modalContent}
                setContent={setModalContent}
            />}

            {/* <div className="flex flex-wrap justify-start gap-x-8 gap-y-16"> */}
            <div id="listingProductSection"  style={{ margin: '0 auto', rowGap: '20px', columnGap: '1.33%', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 300px', justifyContent: 'space-around' }}>
            {/* <div className="mx-auto grid grid-cols-auto-fill-300 gap-y-5 gap-x-1.33% justify-around"> */}


                {tests.map((test, index) => {
                    return (
                        <TestCardV4
                            key={test.id}
                            test={test}
                            modal={{
                                mountModal: setIsModalMounted,
                                setContent: setModalContent
                            }}
                            isPurchaseDisabled={authStatus !== 'authenticated'}
                        />
                    )
                })}
            </div>

            {/* <List>
                {tests.map((test, index) => {
                    return (
                        <List.Item
                            key={test.id}
                        >
                            <TestCard
                                test={test}
                                modal={{
                                    mountModal: setIsModalMounted,
                                    setContent: setModalContent
                                }}
                                isPurchaseDisabled={authStatus !== 'authenticated'}
                            />
                        </List.Item>
                    )
                })}

            </List> */}
        </Container>

    )
}

export default ListingProducts