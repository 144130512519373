const DIFFICULTY = [
  {
    key: 'practice',
    text: 'Practice',
    value: 'practice'
  },
  {
    key: 'easy',
    text: 'Easy',
    value: 'easy'
  },
  {
    key: 'medium',
    text: 'Medium',
    value: 'medium'
  },
  {
    key: 'hard',
    text: 'Hard',
    value: 'hard'
  }
];

export default DIFFICULTY;
