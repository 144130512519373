import { Container } from "semantic-ui-react";
import { features } from "../constants/features";

const FeatureSection = () => {
    return (
        <Container>

            <div className="relative mt-10 min-h-[460px]">
                <div className="text-center">
                    <span className="bg-neutral-100 text-emerald-500 rounded-full h-6 text-sm font-medium px-2 py-1 uppercase">
                        Features
                    </span>
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Rapidly pass your {" "}
                        <span className="bg-gradient-to-r from-emerald-500 to-emerald-800 text-transparent bg-clip-text">
                            certification exams
                        </span>
                    </h2>
                </div>
                <div className="flex flex-wrap mt-10 lg:mt-12">
                    {features.map((feature, index) => (
                        <div key={index} className="w-full sm:w-1/2 md:w-2/4">
                            <div className="flex mb-6">
                                <div className="flex mx-6 h-14
                             w-14 p-2 justify-center items-center rounded-full">
                                    {feature.icon}
                                </div>
                                <div>
                                    <h5 className="mt-1 mb-6 text-xl">{feature.text}</h5>
                                    <p className="text-md p-2 mb-10 text-neutral-500">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default FeatureSection;
