import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkLintListItemIndent from 'remark-lint-list-item-indent';
import CodeBlock from './CodeBlock';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css'; // Import the KaTeX CSS

const components = {
  code: ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <CodeBlock language={match[1]} code={children}>
        <SyntaxHighlighter
          style={dracula}
          language={match[1]}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          {...props}
        />
      </CodeBlock>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
  // Renderer for inline math
  inlineMath: ({ value }) => {
    console.log('inLineMath:', value)
    return (
      <InlineMath math={value} />
    )
  },
  // Renderer for block math
  math: ({ value }) => {
    console.log('BlockMath:', value)
    return (
      <BlockMath math={value} />
    );
  },
  // Renderer for inline math
  // inlineMath: (props) => {
  //   console.log('inLineMath:', props)
  //   return (
  //     <InlineMath math={props} />
  //   )
  // },
  // // Renderer for block math
  // math: ( props ) => {
  //   console.log('BlockMath:', props)
  //   return (
  //     <BlockMath math={props} />
  //   );
  // },
};

function convertMathEquation(input) {
  // Use a regular expression to find and replace block math equations
  const blockMath = input.replace(/\\\[([\s\S]*?)\\\]/g, (match, p1) => {
      return `$$${p1.trim()}$$`;
  });

  // Use a regular expression to find and replace inline math equations
  return blockMath.replace(/\\\(([\s\S]*?)\\\)/g, (match, p1) => {
    return `$${p1.trim()}$`;
});
}


const markdown2 = `
# Example Document

Inline math example: $E = mc^2$

Block math example:
$$
a^2 + b^2 = c^2
$$

Code block example:
\`\`\`javascript
function sum(a, b) {
  return a + b;
}
\`\`\`
`;

const markdown = `
Math inside square brackets:
\[ x^2 + y^2 = z^2 \]

Another example:
\[ a^2 + b^2 = c^2 \]

Another example:
\( a^2 + b^2 = c^2 \)
`;

const ChatBotMessage = ({ message }) => {

  
  message = convertMathEquation(message)
  // console.log('botMessage', message)

  return (
    <div className="prose">
      <ReactMarkdown
        skipHtml={false}
        disallowedElements={[]}
        remarkPlugins={[
          remarkGfm,
          remarkLintListItemIndent,
          remarkMath,
        ]}
        rehypePlugins={[
          rehypeKatex,
        ]}
        components={components}
      >
        {message}
        {/* {markdown} */}
      </ReactMarkdown>
    </div >
  );
};

export default ChatBotMessage;