import React from 'react'
import {
    Icon,
    Image,
    Label,
    List,
    Rating,
    Card,
    Popup,
    Button
} from 'semantic-ui-react';
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import ButtonPill from './ButtonPill';


const TestCardV4 = ({
    test,
    authorTestCard = false,
    modal: { mountModal = null, setContent = null },
    isPurchaseDisabled = true
}) => {

    const handleOnPurchase = () => {

        ReactGA.event({
            category: "purchase",
            action: "click-to-purchase",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        setContent(test);
        mountModal(true);
    }
    const handleOnLogin = () => {
        navigate(`/login?redirectTo=/`)
    }

    // console.log('testCard', test)

    const { pathname } = useLocation()
    const navigate = useNavigate();
    // const { data: test, isLoading: isTestLoading, isError: isTestError, error: testError, isSuccess: isTestSuccess, refetch: testRefetch } = useGetFullTestQuery(testId);

    const handleOnClick = () => {

        ReactGA.event({
            category: "testcard",
            action: "click",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        if (authorTestCard) return;

        pathname.includes('/tests')
            ? navigate(`${test.id}`)
            : navigate(`/tests/${test.id}`);
    }

    // const [showPopup, setShowPopup] = useState(false);
    // const [popupPosition, setPopupPosition] = useState('right center');

    // const handleMouseEnter = () => {
    //     setShowPopup(true);
    //     // setPopupPosition(getPopupPosition());
    // };

    // const handleMouseLeave = () => {
    //     setShowPopup(false);
    // };

    const getPopupPositionV1 = () => {
        const cardRef = React.createRef();

        // Get the dimensions of the TestCardV4 component
        const cardRect = cardRef.current?.getBoundingClientRect();

        // Get the width of the viewport
        const viewportWidth = window.innerWidth;

        // Calculate the available space to the right and left of the component
        const spaceToRight = viewportWidth - (cardRect?.left + cardRect?.width);
        const spaceToLeft = cardRect?.left;

        // Determine the position of the popup based on the available space
        if (spaceToRight > spaceToLeft) {
            return 'right center';
        } else {
            return 'left center';
        }
    };
    // const cardRef = React.useRef(null);
    // const getPopupPositionV2 = () => {

    //     // Get the dimensions of the TestCardV4 component
    //     const cardRect = cardRef.current?.getBoundingClientRect();
    //     // console.log('cardRect', cardRect)
    //     // console.log('ref', cardRef.current)

    //     // Calculate the position of the popup
    //     const popupLeft = cardRect?.right + 16; // 16 is the spacing between the card and popup
    //     const popupTop = cardRect?.top;

    //     // console.log(`${popupLeft}px, ${popupTop}px`)

    //     return [`${popupLeft}px`, `${popupTop}px`];
    // };

    const handleOnAccessButton = (e) => {
        e.preventDefault();
        if (test?.config?.category === 'flashcard_deck') {
            navigate(`/flashcards/${test?.id}`)
        } else {
            navigate(`/tests/${test?.id}/content`);
        }
    }

    return (
        <Popup
            trigger={
                <div
                    className={'min-w-[250px] max-w-[300px] rounded-lg shadow-[0_0_15px_rgba(192,192,192,0.3)]'}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                // ref={cardRef}
                >

                    {/* {showPopup && (
                <div
                    className="absolute bg-white rounded-lg shadow-md p-4 z-50 h-[200px]"
                    style={{
                        left: getPopupPositionV2()[0],
                        top: getPopupPositionV2()[1]
                        // left: '320px',
                        // top: '600px'
                    }}
                >
                    <h3>Additional Details</h3>
                    <p>This is where you can display more information about the test.</p>
                </div>
            )} */}

                    <Card style={{ boxShadow: 'none', borderWidth: '1px', borderStyle: 'solid', borderColor: '#e2e8f0' }} fluid>
                        <div className={'w-full h-[150px] relative'}>
                            <img src={test?.['product_card']?.['image']} className={'w-full h-full object-cover object-center absolute inset-0 rounded-t-lg rounded-b-none'} />
                        </div>

                        <div className={'px-4'}>

                            <div className="overflow-hidden text-ellipsis line-clamp-2 mt-1 font-extrabold text-lg leading-tight">
                                {test?.['product_card']?.['header']}
                            </div>

                            {/* <div class="overflow-hidden text-ellipsis line-clamp-2 mt-1 text-base leading-none">
                    {test?.['product_card']?.['description']}
                    </div> */}

                            <div className='mt-1 text-sm overflow-hidden text-ellipsis line-clamp-1'>
                                <Image avatar src='https://react.semantic-ui.com/images/avatar/small/rachel.png' />
                                <span className={'text-slate-500'}>Quizby Team</span>
                            </div>


                            <div className='mt-1 flex items-center'>
                                <span
                                    className={'font-extrabold'}
                                >
                                    {isNaN((Math.round((Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])) * 100) / 100).toFixed(1)) ? '0.0' : (Math.round((Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])) * 100) / 100).toFixed(1)}
                                </span>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                                <Rating
                                    disabled icon='star'
                                    defaultRating={Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])}
                                    maxRating={5}
                                />
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                                <span className='text-sm text-slate-400'>
                                    ({Number(test?.['stats']?.['ratings'])})
                                </span>

                            </div>

                            <div className='ui mt-3'>
                                {
                                    test.isPurchased
                                        ? (
                                            <>
                                                {/* <ButtonPill
                                                    onClick={(e) => {
                                                        navigate(`/tests/${test?.id}/content`);
                                                    }}
                                                    fluid={true}
                                                >
                                                    <Icon name='checkmark' />
                                                    Take Test
                                                </ButtonPill> */}
                                                <Button
                                                    style={{ backgroundColor: '#10b981', fontWeight: 'normal', color: '#ffffff' }}
                                                    circular
                                                    fluid
                                                    onClick={handleOnAccessButton}
                                                >
                                                    <Icon name='checkmark' />
                                                    {test?.config?.category === 'flashcard_deck' ? 'View Flashcards' : 'Take Test'}
                                                </Button>
                                            </>
                                        )
                                        : (
                                            <div className='flex items-center'>
                                                {/* <ButtonPill
                                                    onClick={isPurchaseDisabled ? handleOnLogin : handleOnPurchase}
                                                    disabled={authorTestCard || isPurchaseDisabled}
                                                    variant={'secondary'}
                                                    fluid={true}
                                                >
                                                    <Icon name='cart plus' />
                                                    {`$${Number(test?.['product_card']?.['price']).toFixed(2)} `}
                                                    {isPurchaseDisabled ? 'Login to Purchase' : 'Purchase'}
                                                </ButtonPill>

                                                <ButtonPill disabled={true} variant={'secondary'} noBorder={true} color='fuchsia'>
                                                    <Icon color='pink' name='heart outline' size='large' />

                                                </ButtonPill> */}
                                                <Button
                                                    color='green'
                                                    onClick={isPurchaseDisabled ? handleOnLogin : handleOnPurchase}
                                                    disabled={authorTestCard}
                                                    circular
                                                    basic
                                                    fluid
                                                >
                                                    <div className='text-emerald-500 font-normal'>
                                                        <Icon name='cart plus' />
                                                        {`$${Number(test?.['product_card']?.['price']).toFixed(2)} `}
                                                        {isPurchaseDisabled ? 'Login to Purchase' : 'Purchase'}
                                                    </div>
                                                </Button>
                                                <Button
                                                    circular
                                                    disabled
                                                    basic
                                                >
                                                    <Icon color='pink' name='heart outline' size='large' />
                                                </Button>
                                            </div>
                                        )
                                }
                            </div>

                            <div>
                                <Label.Group className={'mt-4 mx-1'}>
                                    {
                                        test?.['product_card']?.['tags'] &&
                                        Object.entries(test?.['product_card']?.['tags'])
                                            .map(([key, val], index) => {
                                                if (key === 'sale') {
                                                    // return (
                                                    //     <Label key={index} color='red'>{val}</Label>
                                                    // );
                                                } else if (key === 'language') {
                                                    // return (
                                                    //     <Label key={index}><Icon name='globe' />{val}</Label>
                                                    // );
                                                } else if (key === 'category') {
                                                    return (
                                                        <Label key={index}>{val}</Label>
                                                    );
                                                } else {
                                                    // return (
                                                    //     <Label key={index}>{val}</Label>
                                                    // );
                                                }
                                            })
                                    }
                                </Label.Group>
                            </div>
                        </div>
                    </Card >


                </div >
            }
            position={getPopupPositionV1()}
            flowing hoverable
        >
            <Card fluid style={{ boxShadow: 'none', width: '300px' }}>

                <Card.Content
                    className={!authorTestCard ? 'border-0 cursor-pointer' : 'border-0'}
                    width={8}
                    onClick={handleOnClick}
                >
                    <Card.Header>{test?.['product_card']?.['header']}</Card.Header>
                    <Card.Description>
                        {test?.['product_card']?.['description']}
                    </Card.Description>

                </Card.Content>

                <Card.Content
                    className={!authorTestCard ? 'border-0 cursor-pointer' : 'border-0'}
                    width={8}
                    onClick={handleOnClick}
                >
                    <List>
                        {test?.['product_card']?.['description_bullet_points']
                            .map((item, index) => {
                                if (index === 0) {
                                    return (
                                        <List.Header key={index}>{item?.text}</List.Header>
                                    );
                                } else {
                                    return (
                                        <List.Item key={index}>
                                            <List.Icon name='check' />
                                            <List.Content>{item?.text}</List.Content>
                                        </List.Item>
                                    );
                                }
                            })
                        }
                    </List>

                    <List>
                        <List.Item>
                            <Label.Group className={'mt-2'}>
                                {
                                    test?.['product_card']?.['tags'] &&
                                    Object.entries(test?.['product_card']?.['tags'])
                                        .map(([key, val], index) => {
                                            if (key === 'sale') {
                                                return (
                                                    val ? <Label key={index} color='red'>{val}</Label> : null
                                                );
                                            } else if (key === 'language') {
                                                return (
                                                    <Label key={index}><Icon name='globe' />{val}</Label>
                                                );
                                            } else {
                                                return (
                                                    <Label key={index}>{val}</Label>
                                                );
                                            }
                                        })

                                }
                            </Label.Group>
                        </List.Item>

                    </List>

                </Card.Content>

                {/* <Card.Content style={{ border: 'none' }}>
                    <div className='ui two buttons'>
                        {
                            test?.isPurchased
                                ? (
                                    <Button
                                        color={'purple'}
                                        icon={'checkmark'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(`/tests/${test?.id}/content`);
                                        }}
                                    >
                                        Already Purchased - Go To Test
                                    </Button>
                                )
                                : (
                                    <Button basic color='green'
                                        onClick={isPurchaseDisabled ? handleOnLogin : handleOnPurchase}
                                        disabled={authorTestCard
                                            // || isPurchaseDisabled
                                        }
                                    >
                                        <Icon name='cart plus' />
                                        {`$${Number(test?.['product_card']?.['price']).toFixed(2)} `}
                                        {isPurchaseDisabled ? 'Login to Purchase' : 'Purchase'}
                                    </Button>
                                )
                        }

                        <Button basic color='blue' disabled>
                            <Icon name='heart' />
                            Wishlist
                            (coming soon)
                        </Button>
                    </div>
                </Card.Content> */}
            </Card>
        </Popup>





    )
}

export default TestCardV4;