import React from 'react';
import { Container } from "semantic-ui-react";
import SessionResultCard from "./SessionResultCard";

const SessionResultsListing = ({ sessions }) => {

    // console.log('sessions', sessions)

    // Sort the sessions by start time in decending order
    const sortedSessions = [...sessions].sort((a, b) => new Date(Math.max(b.startTime, b.createTime)) - new Date(Math.max(a.startTime, a.createTime)));


    return (
        <Container>
            {
                // sessions && sessions.length > 0 &&
                sortedSessions.map((session, index) => {
                    return (<SessionResultCard session={session} key={index}/>);
                })
            }
        </Container>
    );
};

export default SessionResultsListing;
