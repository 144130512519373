import React from 'react'
import {Divider, Image, Segment, Card, Icon, Button, Container, Form, Input} from 'semantic-ui-react'
import {Controller, useFormContext, useFieldArray, useController,} from 'react-hook-form';
import HookFormControlledField from './HookFormControlledField';
import {alphabet} from '../constants';
import HookFormControlledImagePicker from './HookFormControlledImagePicker';

const QuestionOptionEditorUncontrolled = ({
                                              name,
                                              index,
                                              setModalState,
                                              onDelete,
                                              onMove,
                                              onMarkCorrect,
                                          }) => {


    const {
        handleSubmit,
        register,
        reset,
        getValues,
        control,
        watch,
        setValue,
        getFieldState,
        formState: {isDirty, isValid, errors}
    } = useFormContext();


    const watchImage = watch(`${name}.option_image`)

    return (
        <Container>
            <Card
                fluid
                key={name}
                style={{cursor: 'pointer'}}
                raised
            >

                <Card.Content>
                    <Card.Header>{`${alphabet(index)}`}</Card.Header>
                    <br/>
                    <Card.Meta>
                        <Form.Field>
                            <label for={`${name}.option_id`}>{'Option ID (Not-editable)'}</label>
                            <Form.Input
                                name={`${name}.option_id`}
                                disabled
                                {...register(`${name}.option_id`)}
                                value={watch(`${name}.option_id`)}
                            />
                        </Form.Field>
                    </Card.Meta>


                    <Card.Description>
                        <Form.Field>
                            <label for={`${name}.option_header`}>{'Enter Option Header'}</label>
                            <Form.Input
                                {...register(`${name}.option_header`)}
                                name={`${name}.option_header`}
                                value={watch(`${name}.option_header`)}
                                onChange={(e) => setValue(`${name}.option_header`, e.target.value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true
                                })}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label for={`${name}.option_text`}>{'Enter Option Text'}</label>
                            <Form.Input
                                {...register(`${name}.option_text`)}
                                name={`${name}.option_text`}
                                value={watch(`${name}.option_text`)}
                                onChange={(e) => setValue(`${name}.option_text`, e.target.value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true
                                })}
                            />
                        </Form.Field>

                    </Card.Description>

                </Card.Content>

                <Image
                    wrapped ui={false}
                    size={'large'} src={watchImage}
                />

                <Card.Content extra>
                    <Form.Input
                        {
                            ...register(`${name}.option_image`,
                                // { required: 'Image URL required', }
                            )}
                        action={{
                            color: 'blue',
                            labelPosition: 'left',
                            icon: 'browser',
                            content: 'Browse',
                            onClick: (e) => {
                                e.preventDefault();
                                setModalState({
                                    isOpen: true,
                                    field: `${name}.option_image`,
                                })
                            },
                        }}
                        value={getValues(`${name}.option_image`)}
                        width={16}
                        label={'Image Url'}
                        placeholder={'Image Url'}

                        // error={getFieldState(`${name}.option_image`).error ? {
                        //     content: getFieldState(`${name}.option_image`)?.error?.message,
                        //     pointing: 'above',
                        // } : false}
                        onChange={(e) => setValue(`${name}.option_image`, e.target.value, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })}
                        focus
                    />

                    <div className='ui two divs'>

                        <div className='ui three buttons'>
                            <Button
                                basic
                                size='small'
                                color='blue'
                                fluid
                                compact
                                icon={'arrow alternate circle up'}
                                content={'Up'}
                                onClick={(e) => onMove(e, {from: index, to: index - 1})}
                                disabled={index === 0}
                            />
                            <Button
                                basic
                                size='small'
                                color='blue'
                                fluid
                                compact
                                icon={'arrow alternate circle down'}
                                content={'Down'}
                                onClick={(e) => onMove(e, {from: index, to: index + 1})}
                                disabled={!watch('options') && index >= watch('options')?.length - 1}
                            />
                            <Button basic
                                    size='small'
                                    color='red'
                                    fluid
                                    compact
                                    icon={'remove circle'}
                                    content={'Delete'}
                                    onClick={(e) => onDelete(e, {index, value: getValues(`${name}`)})}
                            />
                        </div>
                        <div className='ui two buttons'>
                            <Button
                                // basic={watch('answer?.answer_id') !== (getValues(`${name}`).option_id)}
                                // basic={watch('answer.answer_id').some((obj) => obj.value === (getValues(`${name}`).option_id))}
                                basic={!watch('answer.values')?.some((elem) => elem.value === getValues(`${name}`).option_id)}
                                // basic={true}
                                size='mini'
                                color='green'
                                fluid compact
                                icon={'check circle'}
                                content={'Mark As Correct Answer'}
                                onClick={(e) => onMarkCorrect(e, {value: getValues(`${name}`), index: index, type: 'option_id'})}
                            />
                        </div>


                    </div>

                </Card.Content>

            </Card>
        </Container>

    )
}

export default QuestionOptionEditorUncontrolled
