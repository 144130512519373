import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './CodeBlock.css';
import { Icon } from 'semantic-ui-react';

const CodeBlock = ({ language, code, children }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="code-block-container">
            <div className="code-block-header">
                <span className="language">{language}</span>
                <button className="copy-button" onClick={handleCopy}>
                    <Icon name='copy outline' />
                    {copied ? 'Copied!' : 'Copy'}
                </button>
            </div>
            {children}
        </div>
    );
};

export default CodeBlock;
