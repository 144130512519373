import React, {useState} from 'react'
import {Button, Modal, Container, Icon, Image, Item, Label, List, Rating, Table, Header, Grid} from 'semantic-ui-react';
import {usePurchaseTestMutation} from '../store/store';
import {convertTimeStampToDate} from "../utils/convertTimeStampToDate";
import {useLocation, useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import PaypalCheckoutButton from "./PaypalCheckoutButton";

const PurchaseModal = ({open, mountModal, setContent, test}) => {

    const product = {
        description: "Design+Code React Hooks Course",
        price: 19
    };
    const [purchaseTest, {data, error, isLoading, isSuccess, isError}] = usePurchaseTestMutation();
    const [transactionSuccess, setTransactionSuccess] = useState(null);
    // console.log('transactionSuccess:', transactionSuccess)


    const navigate = useNavigate();
    const location = useLocation();
    // console.log('test:', test)

    const handleOnClose = () => {
        // console.log(setContent)

        ReactGA.event({
            category: "purchase",
            action: "cancel",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        setContent({});
        mountModal(false);
    }

    const handlePurchase = () => {

        ReactGA.event({
            category: "purchase",
            action: "purchase",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        purchaseTest({
            id: test.id,
        });
    }

    // console.log('test', test)
    // console.log('purchase data return', data)

    return (
        <Modal
            onClose={handleOnClose}
            onOpen={() => setContent(test)}
            open={open}
        >
            <Modal.Header>
                <Grid columns={2} stackable>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column textAlign={'left'}>
                            <Header>
                                Purchase Summary
                            </Header>
                        </Grid.Column>

                        <Grid.Column textAlign={'right'}>
                            <Button basic color='black' onClick={handleOnClose}>
                                <Icon name={'cancel left'}/>
                                Close
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Header>
            <Modal.Content>

                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>No.</Table.HeaderCell>
                            <Table.HeaderCell>Product Description</Table.HeaderCell>
                            <Table.HeaderCell>Product Code</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>1</Table.Cell>
                            <Table.Cell>{test?.product_card?.header}</Table.Cell>
                            <Table.Cell>{test?.id}</Table.Cell>
                            <Table.Cell>{Number(test?.product_card?.price).toFixed(2)}</Table.Cell>
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>Total Price to Be Charged:</Table.HeaderCell>
                            <Table.HeaderCell>{Number(test?.product_card?.price).toFixed(2)}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>

                <p>You are purchasing: <span className={'font-bold'}>{test?.product_card?.header}</span></p>
                <p>You will be charged <span
                    className={'font-bold'}>USD ${Number(test?.product_card?.price).toFixed(2)}</span></p>
                <p>Are you sure you want to make this purchase?</p>

                {/*// INFO: for Free course $0 purchase*/}
                {isSuccess &&
                    (
                        <>
                            <h2 className={'font-bold text-green-500'}>
                                <Icon name={'thumbs up outline'}/>
                                Purchase completed.
                            </h2>
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Product Description.</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>{data?.test}</Table.Cell>
                                        <Table.Cell>{convertTimeStampToDate(data?.date)}</Table.Cell>
                                        <Table.Cell>{data?.id}</Table.Cell>
                                        <Table.Cell>{Number(data?.price).toFixed(2)}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </>
                    )
                }
                {/*// INFO: Paypal purchase*/}
                {transactionSuccess &&
                    (
                        <>
                            <h2 className={'font-bold text-green-500'}>
                                <Icon name={'thumbs up outline'}/>
                                Purchase completed.
                            </h2>
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Product Description.</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>{test?.product_card?.header}</Table.Cell>
                                        <Table.Cell>{transactionSuccess?.create_time}</Table.Cell>
                                        <Table.Cell>{transactionSuccess?.id}</Table.Cell>
                                        <Table.Cell>{`${Number(transactionSuccess?.amount?.value).toFixed(2)}
                                        ${transactionSuccess?.amount?.currency_code}`}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </>
                    )
                }

                {isError && <h2 className={'text-red-500'}>Unable to process the transaction. Please try
                    again. {(error?.data?.error) ?? JSON.stringify(error)}</h2>}
            </Modal.Content>
            <Modal.Actions>
                {
                    //INFO: if test is already purchased or purchase transaction is success, show redirect button,
                    // else purchase button
                    (
                        test?.isPurchased
                        || isSuccess || transactionSuccess
                    )
                        ? (
                            <Button
                                // className={'inline w-[36rem]'}
                                color={'purple'}
                                // size={'massive'}
                                icon={'checkmark'}
                                onClick={(e) => {
                                    e.preventDefault();

                                    if (location.pathname.includes(`tests/${test?.id}`)) {
                                        navigate('content')
                                    } else {
                                        navigate(`/tests/${test?.id}/content`);
                                    }
                                }}
                            >
                                Go To Test
                            </Button>
                        )
                        : (
                            test?.['product_card']?.['price'] && Number(test?.['product_card']?.['price'])
                        )
                            ? (
                                <Container>
                                    <PaypalCheckoutButton test={test} setTransactionSuccess={setTransactionSuccess}/>
                                </Container>
                            )
                            : (
                                <Button
                                    content="Purchase"
                                    labelPosition='right'
                                    icon='checkmark'
                                    onClick={handlePurchase}
                                    positive
                                />
                            )

                }
            </Modal.Actions>
        </Modal>
    )
}

export default PurchaseModal