import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Segment, Icon, Container, Header, Divider, Checkbox, Form } from 'semantic-ui-react';
import { useEditTestMutation } from '../store/testsSlice';

import { Auth } from 'aws-amplify';
import { ADMIN_EDIT_TEST_SECTIONS } from '../constants/adminEditTestSections';

const AdminEditable = ({ DisplayComponent, EditComponent = null, scope, testId }) => {

    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestMutation();

    // console.log(props)
    const [editMode, setEditMode] = useState(false);
    const [photosPublic, setPhotosPublic] = useState(
        async () => {
            const { identityId } = await Auth.currentUserCredentials();

            const body = {
                tag: true,
                testId,
                scope: 'tags',
                identityId,
            }

            editTest(body)
                .unwrap()
                .then((fulfilled => {
                    // console.log((fulfilled));
                    return true;
                }))
                .catch((rejected) => {
                    console.log(rejected);
                    return { message: rejected };
                })
        }
        // false
    );


    const handleOnTogglePhotoTags = async (e, data) => {
        const { identityId } = await Auth.currentUserCredentials();

        const body = {
            tag: data.checked,
            testId,
            scope: 'tags',
            identityId,
        }

        // Optimistic update
        setPhotosPublic((prev) => !prev);

        editTest(body)
            .unwrap()
            .then((fulfilled => {
                // console.log((fulfilled));
                // return true;
            }))
            .catch((rejected) => {
                console.log(rejected);
                // reverse optimistic update if it fails
                setPhotosPublic((prev) => !prev)
                // return false;
            })


    }

    let content;

    // Scenario 1: edit questions
    if (scope === ADMIN_EDIT_TEST_SECTIONS.QUESTIONS.value
        || scope === ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value
        || scope === ADMIN_EDIT_TEST_SECTIONS.APPROVE.value
        || scope === ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value
        ) {

        content = <DisplayComponent editMode={editMode} setEditMode={setEditMode} />

    } else {
        // Scenario 2: edit all else
        content = !editMode
            ? <DisplayComponent />
            : <EditComponent setEditMode={setEditMode} />

    }

    return (
        <Container>
            <Segment.Group horizontal>
                <Segment basic>
                    <Checkbox
                        name={'editMode'}
                        color='green'
                        toggle
                        label={editMode ? 'Exit Edit Mode' : 'Enter Edit Mode'}
                        onChange={(e, data) => setEditMode(data.checked)}
                        checked={editMode}
                        disabled={scope === ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value
                            || scope === ADMIN_EDIT_TEST_SECTIONS.APPROVE.value
                            || scope === ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value
                        }
                    />
                </Segment>

                <Segment basic>
                    <Checkbox
                        name={'photoAccess'}
                        align='right'
                        color='green'
                        toggle
                        label={photosPublic ? 'Remove Photos Access' : 'Grant Photos Access'}
                        onChange={(e, data) => handleOnTogglePhotoTags(e, data)}
                        checked={photosPublic}
                    />

                </Segment>
            </Segment.Group>
            <Segment basic>

                {content}

            </Segment>

        </Container>
    )
}

export default AdminEditable
