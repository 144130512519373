// FlashcardEditor.js
import React, { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip'; // Import the library
import './flashcard.scss'; // Import the CSS file for styling
import AnswerTimer from './AnswerTimer';
import QuestionTextEditor from './QuestionTextEditor';
import { useForm, useFieldArray, FormProvider, Controller, get } from 'react-hook-form';
import { Container, Form, Header, Divider, Icon, Image, GridRow, GridColumn, Grid, Segment, Label, List, Button } from 'semantic-ui-react'
import HookFormControlledField from './HookFormControlledField';
import ButtonSave from './ButtonSave';


const FlashcardEditor = ({
    flashcard,
    index = 0,
    onEdit = null,
    onDelete = null,
}) => {

    console.log('flashcardEditor:', flashcard)

    let methods = useForm({
        // INFO: defaultValues persist from card to card, do NOT use
        // defaultValues: structuredClone(card),
        // INFO: Values refresh the form content BUT create infinite loop with Controller under QuestionOptionEditor
        values: structuredClone(flashcard),
        shouldUnregister: true,
    })


    const {
        handleSubmit,
        register,
        reset,
        getValues,
        control,
        watch,
        setValue,
        formState: { isDirty, isValid, errors }
    } = methods;

    return (

        <Container >
            <Segment >
                <Label attached='top' color='black'>
                    <div className='flex justify-between'>
                        <div>Card Number # {index + 1}</div>
                        <div className={'text-gray-600'}>{flashcard?.id ? `ID#: ${flashcard.id}` : ''}</div>
                    </div>
                </Label>
                <Form
                    onSubmit={handleSubmit(onEdit)}
                    widths={'equal'}
                >

                    <Grid stackable columns={'equal'} >

                        <GridRow >
                            <GridColumn>
                                <Segment >
                                    <Label attached='top'>Card Front</Label>

                                    <QuestionTextEditor
                                        control={control}
                                        label={'Front'}
                                        name={'front'}
                                    />
                                </Segment>

                            </GridColumn>
                            <GridColumn>
                                <Segment >
                                    <Label attached='top'>Card Back</Label>

                                    <QuestionTextEditor
                                        control={control}
                                        label={'Back'}
                                        name={'back'}
                                    />
                                </Segment>
                            </GridColumn>
                        </GridRow>

                        <GridRow>
                            <GridColumn>
                                <Segment >
                                    <Label attached='top'>Card Label</Label>

                                    <HookFormControlledField
                                        name={'label'}
                                        label={'Card Label'}
                                        control={control}
                                        type='text'
                                    />
                                </Segment>
                            </GridColumn>

                            <GridColumn>
                                <Segment >
                                    <Label attached='top'>Autoplay Duration</Label>

                                    <HookFormControlledField
                                        name={'timerDuration'}
                                        label={'Auto-play Timer Duration in Seconds'}
                                        control={control}
                                        type='number'
                                    />
                                </Segment>
                            </GridColumn>

                        </GridRow>

                        <GridRow columns={1}>
                            <GridColumn>
                                <div className={'flex flex-between my-2'}>
                                    <ButtonSave
                                        type='submit'
                                        color='green'
                                        // isDisabled={!isDirty}
                                        label={'Save Changes'}
                                        isError={Object.keys(errors).length !== 0}
                                        error={{ ...errors }}
                                    />
                                    {/* <Button basic color='grey'>Move Up</Button> */}
                                    {/* <Button basic color='grey'>Move Down</Button> */}
                                    <Button
                                        basic
                                        color='red'
                                        onClick={(e) => {
                                            reset();
                                            onDelete(e, { currentIndex: index });
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </GridColumn>
                        </GridRow>
                    </Grid>

                </Form>
            </Segment>
        </Container>


    );
};

export default FlashcardEditor;


