import React, {useState} from 'react';
import {Button, Container, Icon, Image, Label, Accordion, Table} from "semantic-ui-react";

const TestProductPageIncluded = ({test}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (e, titleProps) => {
        const {index} = titleProps
        console.log('index', index)
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex)
    }

    return (
        <Container className={'my-10'} text>
            <div className={'m-2'}>
                <h1
                    className={'text-4xl font-extrabold my-3.5'}
                >
                    Included In The Test
                </h1>
            </div>

            <div className={'m-2'}>
                <h3
                    className={'text-base my-3.5'}
                >
                    {test?.['product_summary']?.['question_count']}
                </h3>
            </div>


            <Accordion styled fluid>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleClick}
                    className={'text-2xl font-bold'}
                >
                    <Icon name='dropdown'/>
                    {test?.['product_summary']?.['title']}
                </Accordion.Title>


                {
                    test?.['product_summary']?.['sections']?.length > 0 &&
                    <Accordion.Content active={activeIndex === 0}>
                        <Table>
                            <Table.Body>
                                {
                                    test?.['product_summary']?.['sections']?.map((section, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell className={'text-center'}>
                                                    <Icon
                                                        name={'question circle outline'}/>
                                                </Table.Cell>
                                                <Table.Cell className={'text-left'}>
                                                    {section?.title}
                                                </Table.Cell>
                                                <Table.Cell className={'text-center'}>
                                                    {section?.question_count}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })
                                }
                            </Table.Body>
                        </Table>
                    </Accordion.Content>
                }
            </Accordion>

        </Container>

    )
}


export default TestProductPageIncluded;
