import React, {useState} from 'react'
import SubmitTabs from './SubmitTabs';
import {Container, Divider, Segment, Message, Grid, Icon} from 'semantic-ui-react';
import {TERMS_HTML} from '../constants/terms';
import ButtonSave from './ButtonSave';
import {useEditTestAdminMutation, useEditTestMutation} from '../store/testsSlice';
import Swal from 'sweetalert2';

const AdminPublish = ({testId}) => {

    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestAdminMutation();

    const handleOnSubmit = () => {

        const body = {
            testId,
            scope: 'publish'
        }

        // console.log('body:', body)

        editTest(body).unwrap()
            .then((fullfilled) => {
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Publish Successful`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch((err) => {
                console.log(err)
                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Publish Error: ${JSON.stringify(err)}`,
                    showConfirmButton: false,
                    timer: 3000
                })
            });
    }


    let content;

    content = (
        <Container>
            <Segment.Group horizontal>
                <Segment basic textAlign={'left'}>
                    <Message icon
                    >
                        <Icon name='lock'/>

                        <Message.Content>
                            <Message.Header>
                                Publish The Test
                            </Message.Header>
                            <p>Publish the Test Draft</p>
                        </Message.Content>
                    </Message>

                    <ButtonSave
                        type={'button'}
                        color={'green'}
                        isDisabled={false}
                        isLoading={false}
                        onClick={() => {
                            handleOnSubmit();
                        }}
                        label={'Publish'}
                        isError={false}
                        error={null}
                        icon={'check circle outline'}
                    />

                </Segment>
            </Segment.Group>
        </Container>
    );


    return (
        <Container fluid>
            {content}
        </Container>
    )
}

export default AdminPublish
