import React, {useEffect, useState} from 'react'
import AdminSearchForm from "./AdminSearchForm";
import {Container, Divider,} from "semantic-ui-react";
import AdminTests from "./AdminTests";
import {apiSlice} from "../store/apiSlice";
import {store, useGetTestsAdminQuery} from "../store/store";
import Loader from "./Loader";
import PlacehoderLoader from "./PlaceholderLoader";
import PlaceholderLoader from "./PlaceholderLoader";


const Admin = () => {

    const [searchParams, setSearchParams] = useState('')
    const [tests, setTests] = useState([])
    const [isTestsLoading, setTestsLoading] = useState(false);


    useEffect(() => {

        const fetchTests = async () => {
            setTests([])
            setTestsLoading(true);
            const p = store.dispatch(apiSlice.endpoints.getTestsAdmin.initiate(searchParams.toString()));

            try {
                const data = await p.unwrap();
                setTests(data);
            } catch (e) {
                console.log(`Error: ${JSON.stringify(e)}`)
            } finally {
                setTestsLoading(false);
                p.unsubscribe();
            }
        }

        fetchTests();

        // console.log('searchParams', searchParams)

    }, [searchParams]);

    return (
        <Container>
            <AdminSearchForm setSearchParams={setSearchParams}/>
            <Divider/>
            {isTestsLoading && < PlaceholderLoader size={'medium'} isCentered={false}/>}
            <AdminTests tests={tests}/>
        </Container>
    )
}

export default Admin;