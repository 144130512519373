import React from 'react'
import { Tab, Step, Container } from 'semantic-ui-react'

const SubmitTabs = ({ step }) => {
    return (
        <Container fluid>
            <Step.Group ordered fluid>
                <Step active={step === 0} completed={step > 0}>
                    <Step.Content>
                        <Step.Title>Complete</Step.Title>
                        <Step.Description>Complete and Lock the Test</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1} completed={step > 1}>
                    <Step.Content>
                        <Step.Title>Terms Acceptance</Step.Title>
                        <Step.Description>Accept the Terms & Conditions</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 2} completed={step >= 2}>
                    <Step.Content>
                        <Step.Title>Submit</Step.Title>
                        <Step.Description>Submission the Test for Review</Step.Description>

                    </Step.Content>
                </Step>
            </Step.Group>
        </Container>
    )
}

export default SubmitTabs;