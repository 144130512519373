import React from 'react';

const ButtonPill = ({ children, onClick, fluid = false, disabled = false,
  variant = 'primary', color = 'emerald', fontWeight = 'font-base', noBorder = false }) => {


  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  const getButtonClasses = () => {
    switch (variant) {
      case 'secondary':
        return `inline-flex items-center justify-center px-6 py-2 ${fontWeight} text-${color}-500 bg-white ${noBorder ? '' : 'border-2 border-' + color + '-500'
          } rounded-full shadow-md hover:bg-${color}-100 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-${color}-400 focus:ring-opacity-50 relative overflow-hidden transition-colors duration-300 ease-in-out ${fluid ? 'w-full' : ''
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;
      default:
        return `inline-flex items-center justify-center px-6 py-2 ${fontWeight} text-white bg-${color}-500 border-none rounded-full shadow-md hover:bg-${color}-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-${color}-400 focus:ring-opacity-50 relative overflow-hidden transition-colors duration-300 ease-in-out ${fluid ? 'w-full' : ''
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;
    }
  };

  // console.log('buttonClass', getButtonClasses())

  return (
    <button
      className={getButtonClasses()}
      onClick={handleClick}
    >
      {children}
      
      {/* <span className="absolute inset-0 bg-white opacity-20 transform -translate-x-full transition-transform duration-300 ease-in-out group-hover:translate-x-0"></span> */}
    
    </button>
  );
};

export default ButtonPill;
