import React from 'react'
import { Container } from 'semantic-ui-react'
import AuthorTests from './AuthorTests'
import TestCreateNew from './TestCreateNew'
import ProtectedRoute from "./Archived/ProtectedRoute";

const Author = () => {
    return (
        <Container className='py-24'>
            <TestCreateNew />
            <AuthorTests />
        </Container>
    )
}

export default Author
