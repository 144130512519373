import React from 'react'
import {Dimmer, Loader, Image, Segment, Container} from 'semantic-ui-react'

const PlacehoderLoader = ({size, isCentered = false}) => {

    //TODO set location middle of the screen

    const SIZE = {
        MINI: 'mini',
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    }

    return (

        <div>
            <Segment textAlign={'center'} className={isCentered ? 'flex h-screen m-auto' : 'flex m-auto'}>
                <Dimmer active inverted>
                    <Loader size={size}>Loading</Loader>
                </Dimmer>

            </Segment>
        </div>

    );
}

export default PlacehoderLoader