import React, { useEffect, useRef, useState, useReducer } from 'react';
import PlaceholderComponent from './PlaceholderComponent'
import { Button, Container, Divider, Icon, Segment, Form, Loader, Header } from 'semantic-ui-react';
import { useEditTestMutation, useGetFullTestQuery } from '../store/testsSlice';
import { useForm, Controller, FormProvider } from "react-hook-form";
import { SCORING_OPTIONS, SCORING_OPTION_FIELDS } from '../constants/scoringOptions';
import HookFormControlledField from './HookFormControlledField';
import HookFormControlledDropdown from './HookFormControlledDropdown';
import ButtonSave from './ButtonSave';
import HookFormControlledTable from './HookFormControlledTable';

const ScoringEditor = ({ testId }) => {

    const {
        data: test,
        isLoading: isTestLoading,
        isFetching: isTestFetching,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);
    const [editTest, {
        data: editTestData,
        error: editTestError,
        isLoading: editTestIsLoading,
        isSuccess: editTestIsSuccess,
        isError: editTestIsError
    }] = useEditTestMutation();

    const methods = useForm({
        values: structuredClone(test?.['config']?.['scoring']),
    })

    const { handleSubmit, reset, getValues, control, watch, setValue, formState: { isDirty, errors, isValid } } = methods;

    const type = watch('type');

    useEffect(() => {
        reset({
            ...test?.['config']?.['scoring'],
            type: type,
        });

    }, [type, reset]);

    const handleOnFormSubmit = (data) => {

        const body = {
            scoring: data,
            testId,
            scope: 'scoring',
        }
        // console.log(body);
        editTest(body)

    }

    // console.log('formValues:', getValues())

    const content = type && SCORING_OPTION_FIELDS[type]
        .map(({ key, label, type }, index) => {

            // console.log(key, label, type)

            switch (type) {
                case 'text':
                    return (
                        <HookFormControlledField
                            name={key}
                            control={control}
                            label={label}
                            type={'text'}
                            required={true}
                        />
                    );
                case 'number':
                    return (
                        <HookFormControlledField
                            name={key}
                            control={control}
                            label={label}
                            type={'number'}
                            required={true}
                        />
                    );
                case 'table':
                    return (
                        <HookFormControlledTable
                            label={label}
                            name={key}
                            control={control}
                            columns={2}
                            headers={['points', 'score']}
                            types={['number', 'number']}
                            striped={true}
                        />
                    );
                default:
                    console.error(`Error: Field Type not recognized - Field: ${key}, Type: ${type}`)
            }
        });


    return (

        <Container>
            <FormProvider {...methods}>
                <Form
                    onSubmit={handleSubmit(handleOnFormSubmit)}
                    loading={isTestFetching}
                    widths={'equal'}
                >

                    <HookFormControlledDropdown
                        options={SCORING_OPTIONS}
                        label={'Select Scoring Type'}
                        name={'type'}
                        control={control}
                        required={true}
                    />

                    {content}

                    <Divider hidden />

                    <ButtonSave
                        type={'submit'}
                        color={'green'}
                        isDisabled={editTestIsLoading || !isDirty}
                        isLoading={editTestIsLoading}
                        label={'Save Scoring Changes'}
                        isError={editTestIsError || Object.keys(errors).length !== 0}
                        error={{ ...editTestError, ...errors }}
                    />


                </Form>
            </FormProvider>

        </Container>
    )
}

export default ScoringEditor