import React from 'react'
import { Container, Header } from "semantic-ui-react";
import AuthorTestCard from "./AuthorTestCard";
import { useGetTestsAdminQuery, useGetTestsByAuthorIdQuery } from "../store/testsSlice";
import PlaceholderComponent from "./PlaceholderComponent";

const AdminTests = ({ tests }) => {

    // console.log('AdminTests tests', tests)

    return (
        <Container>
            {tests &&
                tests.map((test, index) => {
                    return (
                        <AuthorTestCard test={test} key={`${test?.id}-${test?.version}`} />
                    );
                })
            }
        </Container>
    )
}

export default AdminTests;