import { Container, Breadcrumb } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';


const SessionBreadcrumbsNav = ({ session }) => {

    const navigate = useNavigate();


    const sections = [
        { key: 'Home', content: 'Home', link: true, onClick: () => navigate('/') },
        { key: 'Test', content: 'Test', link: true, onClick: () => navigate(`/tests/${session?.testId}/content`) },
        { key: 'Session', content: 'Session', active: true },
    ]

    if (session?.session_status === 'Completed') {
        sections.push({ key: 'Result', content: 'Result', link: true, onClick: () => navigate(`/sessions/${session?.id}/results`) })
    }

    return (
        <Container>
            <Breadcrumb icon='right angle' sections={sections} />
        </Container>
    );
}

export default SessionBreadcrumbsNav;