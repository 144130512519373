// src/components/Flashcard.js
import React, { useState, useEffect } from 'react';
import Flashcard from './Flashcard';
import { Icon, Segment, Pagination, Button, Container, Checkbox } from 'semantic-ui-react';
import shuffle, { randomize } from '../utils/shuffle';
import { useEditTestMutation, useEditTestReorderQuestionsMutation, useGetFullTestQuery } from '../store/store';
import { useLocation, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import FlashcardEditor from './FlashcardEditor';


const FlashcardDeckEditor = ({ testId, editMode, setEditMode }) => {


  const {
    data: test, isFetching: isTestFetching, isError: isTestError, error: testError,
    isSuccess: isTestSuccess, refetch: testRefetch
  } = useGetFullTestQuery(testId);

  const [editQuestionOrder, {
    data: editQuestionOrderData, error: editQuestionOrderError,
    isLoading: editQuestionOrderIsLoading, isSuccess: editQuestionOrderIsSuccess,
    isError: editQuestionOrderIsError
  }] = useEditTestReorderQuestionsMutation();

  const [editTest, {
    data: editTestData, error: editTestError, isLoading: editTestIsLoading,
    isSuccess: editTestIsSuccess, isError: editTestIsError
  }] = useEditTestMutation();


  useEffect(() => {
    if (isTestSuccess) {
      setFlashcardsArray([...test?.questions]);
    }
  }, [test, isTestSuccess]);

  const [flashcardsArray, setFlashcardsArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const q = Number(new URLSearchParams(useLocation().search).get('q'));

  const toggleAutoPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const shuffleFlashcards = () => {
    // Implement shuffle logic here (e.g., using Fisher-Yates algorithm)
    // Set the shuffled flashcards to state
    const shuffledFlashcards = shuffle(flashcardsArray);
    setFlashcardsArray(shuffledFlashcards);
  };

  const markFavorite = (index) => {
    // Implement marking favorites logic (i.e., add a property to the flashcard)
    // Favorite state only maintained for the duration of the session
    // Update the flashcards array with 'isMarked' property
    const newFlashcardsArray = flashcardsArray.map((flashcard, idx) => {
      if (index === idx) {

        return {
          ...flashcard,
          isMarked: flashcard?.isMarked ? false : true,
        }
      } else {
        return flashcard;
      }

    })

    setFlashcardsArray(newFlashcardsArray);
  };

  const moveForward = (index) => {
    if (index + 1 < flashcardsArray.length) {
      setCurrentIndex((prev) => prev + 1);
    }
  }

  const moveBackward = (index) => {
    if (index > 0) {

      setCurrentIndex((prev) => {
        if (prev - 1 >= flashcardsArray.length) {
          return flashcardsArray.length - 1;
        } else {
          return prev - 1;
        }
      })
    }
  }

  const handlePaginationChange = (e, { activePage }) => {
    e.preventDefault();
    setCurrentIndex(activePage - 1);
    setSearchParams({ q: activePage });
  }

  const handleNextClickButton = () => {
    setCurrentIndex(prev => {
      if (prev < flashcardsArray.length) {
        setSearchParams({ q: prev + 2 })
        return prev + 1;
      } else {
        setSearchParams({ q: prev + 1 })
        return prev;
      }

    });
  }

  const handlePreviousClickButton = () => {
    setCurrentIndex(prev => {
      if (prev > 0) {
        setSearchParams({ q: prev })
        return prev - 1;
      } else {
        setSearchParams({ q: prev + 1 })
        return prev;
      }
    })

  }

  const handleAddFlashcard = () => {

    const body = {
      questionCount: flashcardsArray.length,
      testId,
      scope: 'flashcardAdd',
    }

    editTest(body)
      .unwrap()
      .then((fullfilled) => {
        Swal.fire({
          position: 'bottom',
          toast: true,
          icon: 'success',
          title: `Flashcard Added`,
          showConfirmButton: false,
          timer: 3000
        })
      })
      .then((fullfilled) => {
        setCurrentIndex((prev) => {
          setSearchParams({ q: prev + 2 })
          return (prev + 1);
        });

      })
      .catch((err) => console.log(err))

  }

  const handleOnDeleteFlashcard = (e, { currentIndex }) => {
    e.preventDefault();

    const body = {
      questionIndex: currentIndex,
      testId,
      scope: 'questionDelete',
    }

    editTest(body)
      .unwrap()
      .then((fullfilled) => {
        Swal.fire({
          position: 'bottom',
          toast: true,
          icon: 'success',
          title: `Flashcard Deleted`,
          showConfirmButton: false,
          timer: 3000
        })
      })
      .then((fullfilled) => {
        testRefetch();
      })
      .then((fullfilled) => {
        // reduce questionIndex
        setCurrentIndex((prev) => {
          setSearchParams({ q: Math.max(prev - 1, 0) + 1 })
          return Math.max(prev - 1, 0);
        })
      })
      .catch((err) => {
        Swal.fire({
          position: 'bottom',
          toast: true,
          icon: 'error',
          title: `Unable to Delete Flashcard`,
          showConfirmButton: false,
          timer: 3000
        })
        console.log(err)
      })
  }

  const handleOnEditFlashcard = (data) => {

    const body = {
      question: {
        // spread existing flashcard
        ...flashcardsArray[currentIndex],
        // overwrite with new forms data, id should not be overwriten
        ...data,
      },
      questionIndex: currentIndex,
      testId,
      scope: 'questionEdit',
    }

    // console.log('formBody:', data)

    editTest(body)
        .unwrap()
        .then((fullfilled) => {
            Swal.fire({
                position: 'bottom',
                toast: true,
                icon: 'success',
                title: `Update saved`,
                showConfirmButton: false,
                timer: 3000
            })
        })
        .catch((err) => console.log(err))
  }

  const Navigation = () => {
    // 1. No Questions i.e. questionCount = 0 and questionIndex = 0
    if (flashcardsArray.length === 0) {
      return (
        <Segment.Group horizontal raised={false} compact>
          <Segment basic textAlign='left'>
            <Pagination
              activePage={currentIndex + 1}
              onPageChange={handlePaginationChange}
              totalPages={flashcardsArray.length}
            />
          </Segment>

          <Segment basic textAlign='right'>
            <Button icon labelPosition='left' onClick={handlePreviousClickButton} disabled={currentIndex === 0}>
              <Icon name='left arrow' />
              Previous
            </Button>
            <Button icon labelPosition='right' color='black' onClick={handleAddFlashcard}>
              Add New Card
              <Icon name='add circle' />
            </Button>
          </Segment>
        </Segment.Group>
      )
      // 2. Existing Questions: else if (questionIndex < questionCount)
    } else if (currentIndex < flashcardsArray.length - 1) {
      return (

        <Segment.Group horizontal raised={false} compact>
          <Segment basic textAlign='left'>
            <Pagination
              activePage={currentIndex + 1}
              onPageChange={handlePaginationChange}
              totalPages={flashcardsArray.length}
            />
          </Segment>

          <Segment basic textAlign='right'>
            <Button icon color={'red'} labelPosition='left' onClick={(e) => handleOnDeleteFlashcard(e, { currentIndex })} style={{ paddingRight: '100px' }}>
              <Icon name='remove circle' />
              Delete
            </Button>
            <Button icon labelPosition='left' onClick={handlePreviousClickButton} disabled={currentIndex === 0}>
              <Icon name='left arrow' />
              Previous
            </Button>
            <Button icon labelPosition='right' onClick={handleNextClickButton} disabled={currentIndex >= flashcardsArray.length - 1}>
              Next
              <Icon name='right arrow' />
            </Button>
          </Segment>
        </Segment.Group>

      )
      // 3. else if (questionIndex = questionCount - 1)
    } else {
      return (
        <Segment.Group horizontal raised={false} compact>
          <Segment textAlign='left'>
            <Pagination
              activePage={currentIndex + 1}
              onPageChange={handlePaginationChange}
              totalPages={flashcardsArray.length}
            />

          </Segment>

          <Segment textAlign='right'>
            <Button icon color={'red'} labelPosition='left' onClick={(e) => handleOnDeleteFlashcard(e, { currentIndex })} style={{ paddingRight: '100px' }}>
              <Icon name='remove circle' />
              Delete
            </Button>
            <Button icon labelPosition='left' onClick={handlePreviousClickButton} disabled={currentIndex === 0}>
              <Icon name='left arrow' />
              Previous
            </Button>
            <Button icon labelPosition='right' color='black' onClick={handleAddFlashcard}>
              Add New Card
              <Icon name='add circle' />
            </Button>
          </Segment>
        </Segment.Group>
      );

    }
  }

  return (
    <div className="flex flex-col justify-center items-center container mx-auto w-[57rem] my-4">
      <div className="flashcard-content">

        <Container>
          <Navigation />
        </Container>


        {
          editMode
            ?
            <FlashcardEditor
              flashcard={{...flashcardsArray[currentIndex]}}
              index={currentIndex}
              onEdit={handleOnEditFlashcard}
              onDelete={handleOnDeleteFlashcard}
            />
            :
            <Flashcard
              {...flashcardsArray[currentIndex]}
              index={currentIndex}
              onMark={markFavorite}
              timerDuration={0}
              next={null}
            />
        }


      </div>
      <div className="flex justify-between w-full my-4">

        <div className="flex justify-start">
          <button onClick={toggleAutoPlay} className={'mx-2'}>
            {isPlaying ? <Icon name={'pause'} size={'large'} /> : <Icon name={'play'} size={'large'} />}
          </button>
          <button onClick={shuffleFlashcards} className={'mx-2'}>
            <Icon name={'shuffle'} size={'large'} />
          </button>
          <button onClick={() => markFavorite(currentIndex)} className={'mx-2'}>
            <Icon name={'star'} color={flashcardsArray?.[currentIndex]?.isMarked ? 'yellow' : 'black'} size={'large'} />
          </button>
        </div>

        <div className="flex justify-center">
          <button onClick={() => moveBackward(currentIndex)}>
            <Icon name={'chevron circle left'}
              disabled={currentIndex <= 0}
              size={'large'} />
          </button>

          <span className='mx-2 text-lg'>
            {currentIndex + 1} / {flashcardsArray.length}
          </span>

          <button onClick={() => moveForward(currentIndex)}>
            <Icon name={'chevron circle right'}
              disabled={currentIndex + 1 >= flashcardsArray.length}
              size={'large'} />
          </button>
        </div>

        <div className="flex justify-end">

          <button onClick={() => console.log('Not yet implemented')} className={'mx-2'}>
            <Icon disabled={true} name={'setting'} size={'large'} />
          </button>
          <button onClick={() => console.log('Not yet implemented')} className={'mx-2'}>
            <Icon disabled={true} name={'expand'} size={'large'} />
          </button>
        </div>


      </div>
    </div >
  );
};

export default FlashcardDeckEditor;
