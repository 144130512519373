import { Image } from "semantic-ui-react";


export const features = [
    {
        icon: <Image centered src='https://www.elegantthemes.com/images/icons/big/editor.svg' size="small" wrapped ui={true} />,
        text: "Full-Length Practice Tests",
        description:
            "Simulate the real exam experience and measure your progress.",
    },
    {
        icon: <Image centered src='https://www.elegantthemes.com/images/icons/big/options.svg' size="small" wrapped ui={true} />,
        text: "Instant Access",
        description:
            "Dive into practice tests, detailed explanations, and tips without delay.",
    },
    {
        icon: <Image centered src='https://www.elegantthemes.com/images/icons/big/responsive.svg' size="small" wrapped ui={true} />,
        text: "Rapid Progress Tracking",
        description:
            "Monitor your growth in real time. Revisit weak spots.",
    },
    {
        icon: <Image src='https://www.elegantthemes.com/images/icons/big/history.svg' size="small" wrapped ui={true} />,
        text: "Quick Wins",
        description:
            "Identify and tackle your weak spots head-on.",
    },

];