import React from 'react'
import {Container, Placeholder, Header, Icon, Button, Image, Divider} from 'semantic-ui-react'
import PlaceholderComponent from './PlaceholderComponent'
import {useGetFullTestQuery} from '../store/testsSlice'
import DOMPurify from "dompurify";
import VideoEmbed from './VideoEmbed'

// TODO implement
const Overview = ({testId}) => {
    // console.log('testId:', testId)
    const {
        data: test,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);

    const sanitizedData = (htmlData) => ({
        __html: DOMPurify.sanitize(htmlData)
    })

    // console.log('htmlData', DOMPurify.sanitize(test?.product_summary?.overview))

    return (
        <Container style={{marginTop: '2rem', marginBottom: '2rem'}} text>
            {isTestSuccess
                ? (
                    <Container text style={{marginTop: '2rem', marginBottom: '2rem'}}>

                        <Container>
                            <Header as={'h1'}>{test?.['product_summary']?.['title']}</Header>
                            <Header as={'h3'}>{test?.['product_summary']?.['subtitle']}</Header>
                        </Container>


                        {
                            test?.['product_summary']?.['video'] &&
                            <>
                                <Divider/>

                                <VideoEmbed
                                    url={test?.['product_summary']?.['video']}
                                    placeholder={test?.['product_summary']?.['video_placeholder']}
                                />
                            </>
                        }


                        {
                            test?.['product_summary']?.['image'] &&
                            <Container text textAlign={'center'}>
                                <Divider/>

                                <Image
                                    rounded
                                    fluid
                                    size={'large'}
                                    src={test?.['product_summary']?.['image']}
                                />
                            </Container>
                        }

                        <Divider/>

                        <Container
                            style={{marginTop: '2rem', marginBottom: '2rem'}}
                            text
                            dangerouslySetInnerHTML={sanitizedData(test?.product_summary?.overview)}
                        />
                    </Container>
                )
                : <PlaceholderComponent/>}

        </Container>
    )
}

export default Overview
