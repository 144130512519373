import React from 'react';
import {Breadcrumb, Button, Container, Icon, Image, Label, Rating} from "semantic-ui-react";
import VideoEmbed from "./VideoEmbed";
import PlacehoderLoader from "./PlaceholderLoader";
import PurchaseModal from "./PurchaseModal";
import {useNavigate, useParams} from "react-router-dom";
import {convertTimeStampToDate} from "../utils/convertTimeStampToDate";
import {useAuthenticator} from "@aws-amplify/ui-react";
import ReactGA from "react-ga4";

const TestProductPageHeaderCard = ({
                                       test,
                                       modal: {mountModal = null, setContent = null},
                                       isPurchaseDisabled
                                   }) => {

    const navigate = useNavigate();
    // const {testId} = useParams();

    // console.log('test', test)

    const handleOnPurchase = () => {

        ReactGA.event({
            category: "purchase",
            action: "click-to-purchase",
            label: test?.id, // optional
            nonInteraction: false, // optional, true/false
        });

        setContent(test);
        mountModal(true);
    }

    const handleOnLogin = () => {
        navigate(`/login?redirectTo=/tests/${test?.id}`)
    }

    const PurchaseButton = () => {
        return (
            <div className={'my-20 '}>
                <div className={'my-2 '}>
                    <div className={'mx-auto text-center'}>

                        <Button
                            className={'inline w-[36rem]'}
                            color={'green'}
                            size={'massive'}
                            onClick={isPurchaseDisabled ? handleOnLogin : handleOnPurchase}
                            // disabled={isPurchaseDisabled}
                        >
                            <Icon name='cart plus'/>
                            {`$${Number(test?.['product_card']?.['price']).toFixed(2)} `}
                            {isPurchaseDisabled ? 'Login to Purchase' : 'Purchase'}
                        </Button>
                        <Button
                            className={'inline text-center'}
                            color={'violet'}
                            inverted
                            // basic
                            size={'massive'}
                            icon={'heart'}
                            disabled
                        />
                        <p className={'text-xl text-white text-center py-2'}>30-Day Money Back Guarantee</p>

                    </div>
                </div>
            </div>
        )
            ;
    }

    const AccessTestButton = ({purchase}) => {
        return (
            <div className={'my-20 '}>
                <div className={'my-2 '}>
                    <div className={'mx-auto text-center'}>

                        <Button
                            className={'inline w-[36rem]'}
                            color={'purple'}
                            size={'massive'}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`content`);
                            }}
                        >
                            Already Purchased - Go To Test
                        </Button>
                        <p className={'text-xl text-white text-center py-2'}>
                            {purchase?.['purchaseDate'] &&
                                <>
                                    <Icon name={'info circle'}/>
                                    You purchased this test on
                                    <div className={'space'}/>
                                    {convertTimeStampToDate(purchase?.['purchaseDate'])}
                                </>
                            }
                        </p>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <Container
            fluid
            className={'bg-black py-3'}
        >
            <Container className={'my-3'}>
                <Breadcrumb
                    icon='right angle'
                    className={'font-extrabold text-white'}
                    style={{color: 'white'}}
                    sections={[
                        {
                            key: 'IQ',
                            content: 'IQ',
                            link: true,
                        },
                        {
                            key: test?.['product_card']?.['tags']?.['category'],
                            content: test?.['product_card']?.['tags']?.['category'],
                            link: true,
                        }
                    ]}
                />
                <div className={'mx-2 my-10'}>
                    {test?.['product_summary']?.['video'] &&
                        <VideoEmbed
                            url={test?.['product_summary']?.['video']}
                            placeholder={test?.['product_summary']?.['video_placeholder']}
                        />}
                </div>
                <div className={'m-2'}>
                    <h1
                        className={'text-4xl text-white font-extrabold my-3.5'}
                    >
                        {test?.['product_summary']?.['title']}
                    </h1>
                    <h3
                        className={'text-2xl text-white font-[1100] my-3.5'}
                    >
                        {test?.['product_summary']?.['subtitle']}
                    </h3>
                    <div className={'my-1'}>
                        {
                            test?.['product_card']?.['tags']?.['sale'] &&
                            <Label color='red' className={'mx-3'}>{test?.['product_card']?.['tags']?.['sale']}</Label>
                        }
                        <span
                            className={'text-orange-600 font-[1500], text-xl mx-3'}
                        >
                            {(Math.round((Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings'])) * 100) / 100).toFixed(1)}
                        </span>
                        <Rating
                            disabled
                            icon='star'
                            size={'huge'}
                            defaultRating={(Number(test?.['stats']?.['totalScore']) / Number(test?.['stats']?.['ratings']))}
                            maxRating={5}
                            className={'text-white mx-3'}
                        />
                        <span className={'text-white text-xl '}>
                            <a className={'text-purple-300'}>
                                ({Number(test?.['stats']?.['ratings'])} ratings)
                            </a>
                        </span>
                        {/*<span>'&#160;' '&#160;'</span>*/}
                        <span className={'text-white text-xl mx-3'}>
                            {Number(test?.['stats']?.['sales'])} students
                        </span>
                    </div>

                    <div
                        className={'text-xl text-white my-2'}
                    >
                        Created by
                        <span className={'space'}/>

                        <Image avatar src='https://react.semantic-ui.com/images/avatar/small/rachel.png'/>
                        <a className={'text-purple-300'}>
                            Quizby Team, IQ Testing Domain
                        </a>
                    </div>

                    <div
                        className={'text-xl text-white my-2 flex flex-row'}
                    >
                        <div className={'inline mx-3'}>

                        <span>
                            <Icon name={'calendar alternate outline'}/>
                            {test?.['product_card']?.['tags']?.['update']}
                        </span>
                        </div>
                        <div className={'inline mx-3'}>
                        <span>
                            <Icon name={'globe'}/>
                            {test?.['product_card']?.['tags']?.['language']}
                        </span>
                        </div>
                    </div>
                </div>

                {
                    test?.['purchase']?.['isPurchased']
                        ? <AccessTestButton purchase={test?.['purchase']}/>
                        : <PurchaseButton/>
                }

            </Container>
        </Container>
    )

}


export default TestProductPageHeaderCard;
