import React from 'react'
import {
    Button,
    Container,
    Icon,
    Image,
    Item,
    Label,
    List,
    Rating,
    Loader,
    Divider,
    Segment,
    Grid,
    Header
} from 'semantic-ui-react';
import {redirect, useNavigate, useLocation} from "react-router-dom";
import {useGetFullTestQuery} from '../store/testsSlice';
import TestCard from './TestCard';
import PlaceholderComponent from './PlaceholderComponent';

// TODO refactor into Card.jsx file

const defaultModal = {
    mountModal: false,
    setContent: null,
}

const Card = ({testId}) => {

    const {
        data: test,
        isLoading: isTestLoading,
        isFetching: isTestFetching,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    } = useGetFullTestQuery(testId);


    let content;
    if (isTestFetching) {
        content = <PlaceholderComponent/>;
    } else if (isTestError) {
        content = <Header as={'h1'}>{testError}</Header>;
    } else if (isTestSuccess) {

        content = <TestCard test={test} modal={{mountModal: null, setContent: null}}/>
    } else {
        //isUninitialized
        content = <PlaceholderComponent/>;
    }

    return (
        <Container>
            {content}
        </Container>
    )
}

export default Card
