import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import { Provider } from "react-redux";
import { store } from "./store/store";

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import Layout from './components/Layout';
import ErrorPage from './components/ErrorPage';
import Session from './components/Session';
import SessionResult, { loader as sessionResultLoader } from './components/SessionResult';
import LoginFormModal from './components/LoginFormModal';
import { Authenticator } from '@aws-amplify/ui-react';
import { EDIT_TEST_SECTIONS, EDIT_FLASHCARD_DECK_SECTIONS } from './constants/editTestSections';
import Author from './components/Author';
import PlacehoderLoader from "./components/PlaceholderLoader";
import Admin from "./components/Admin";
import PrivateWrapper from './components/PrivateWrapper';
import { ADMIN_EDIT_TEST_SECTIONS } from './constants/adminEditTestSections';
import AdminTestEditForm from './components/AdminTestEditForm';
import TestProductPage from "./components/TestProductPage";
import UserTestLayout from "./components/UserTestLayout";
import UserTestForm from "./components/UserTestForm";
import { USER_TEST_SECTIONS } from "./constants/userTestSections";
import { loader as RatingEditorLoader } from "./components/RatingEditor";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AuthenticatorAmplify from './components/AuthenticatorAmplify';
import FlashcardProductPage from "./components/FlashcardProductPage";
import ChatBot from './components/ChatBot';
import BeeTutor from './components/BeeTutor';
// import BeeBot from './components/BeeBot/app/BeeBot';
// import BeeBotWrapper from './components/BeeBot/app/BeeBotWrapper';

// lazy loading of heavy components
const TestEditForm = lazy(() => import('./components/TestEditForm'));
const BeeBotWrapper = lazy(() => import('./components/BeeBot/app/BeeBotWrapper'));
const BeeBot = lazy(() => import('./components/BeeBot/app/BeeBot'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            // {
            //     index: true,
            //     element: <App />,
            //     errorElement: <ErrorPage />,
            // },
            // default forward navigate to /beebot for now
            {
                index: true, // This will act as the default route
                element: <Navigate to="/beebot" />, // Redirect default route to /beebot
            },
            {
                path: 'home',
                element: <App />,
            }, {
                path: 'tests',
                element: <App />,
            },
            {
                path: 'tests/:testId/',
                element: <TestProductPage />
            },
            {
                path: 'flashcards/:flashcardId/',
                element: <FlashcardProductPage />
            },
            {
                path: 'tests/:testId/content',
                element:
                    // <PrivateWrapper>
                    <UserTestLayout />,
                //  </PrivateWrapper>,
                children: [
                    {
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <UserTestForm section={USER_TEST_SECTIONS.INSTRUCTIONS.value} />,
                            }, {
                                path: USER_TEST_SECTIONS.OVERVIEW.value,
                                element: <UserTestForm section={USER_TEST_SECTIONS.OVERVIEW.value} />,
                            }, {
                                path: USER_TEST_SECTIONS.INSTRUCTIONS.value,
                                element: <UserTestForm section={USER_TEST_SECTIONS.INSTRUCTIONS.value} />,
                            }, {
                                path: USER_TEST_SECTIONS.RESULTS.value,
                                element: <UserTestForm section={USER_TEST_SECTIONS.RESULTS.value} />,
                            }, {
                                path: USER_TEST_SECTIONS.RATING.value,
                                element: <UserTestForm section={USER_TEST_SECTIONS.RATING.value} />,
                                loader: RatingEditorLoader,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'login',
                // element: <LoginFormModal open={true} setOpen={}/>,
                element: <AuthenticatorAmplify />
            },
            {
                path: 'beebot',
                // element: <Layout excludeFooter={true} excludeNavbar={true} />,
                // element:
                //     <BeeBotWrapper>
                //         <BeeBot />
                //     </BeeBotWrapper>

                // lazy loading
                async lazy() {
                    // const { BeeBotWrapper } = await import('./components/BeeBot/app/BeeBotWrapper');
                    // const { BeeBot } = await import('./components/BeeBot/app/BeeBot');

                    return {
                        element:
                            <Suspense fallback={<PlacehoderLoader size={'medium'} />}>
                                <BeeBotWrapper>
                                    <BeeBot />
                                </BeeBotWrapper>
                            </Suspense>
                    };
                },
            },
            {
                path: 'admin',
                // loader: AdminLoader,
                element:
                    // <ProtectedRoute>
                    <PrivateWrapper admin={true}>
                        <Admin />
                    </PrivateWrapper>
                // </ProtectedRoute>,
                // async lazy() {
                //     const {Admin} = await import('./components/Admin');
                //     return {
                //         element:
                //             <Suspense fallback={<PlacehoderLoader size={'medium'}/>}>
                //                 <ProtectedRoute>
                //                     <Admin/>
                //                 </ProtectedRoute>
                //             </Suspense>
                //     };
                // },
            },
            {
                path: 'admin/:testId/',
                async lazy() {
                    const { AdminTestEditLayout } = await import('./components/AdminTestEditLayout');
                    return {
                        element:
                            <Suspense fallback={<PlacehoderLoader size={'medium'} />}>
                                <PrivateWrapper admin={true}>
                                    <AdminTestEditLayout />
                                </PrivateWrapper>
                            </Suspense>
                    };
                },
                children: [
                    {
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.OVERVIEW.value} />,
                            },

                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.OVERVIEW.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.OVERVIEW.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.CARD.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.CARD.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.INSTRUCTIONS.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.INSTRUCTIONS.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.SCORING.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.SCORING.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.QUESTIONS.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.QUESTIONS.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.IMAGES.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.IMAGES.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.APPROVE.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.APPROVE.value} />,
                            },
                            {
                                path: ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value,
                                element: <AdminTestEditForm section={ADMIN_EDIT_TEST_SECTIONS.PUBLISH.value} />,
                            },
                        ],
                    },
                ],

            },
            {
                path: 'edit',
                element:
                    <PrivateWrapper edit={true}>
                        <Author />
                    </PrivateWrapper>
                ,
            },
            {
                path: 'edit/:testId/',
                async lazy() {
                    const { TestEditLayout } = await import('./components/TestEditLayout');
                    return {
                        element:
                            <Suspense fallback={<PlacehoderLoader size={'medium'} />}>
                                <PrivateWrapper edit={true}>
                                    <TestEditLayout />
                                </PrivateWrapper>
                            </Suspense>
                    };
                },
                children: [
                    {
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.OVERVIEW.value} />,
                            },

                            {
                                path: EDIT_TEST_SECTIONS.OVERVIEW.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.OVERVIEW.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.CARD.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.CARD.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.INSTRUCTIONS.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.INSTRUCTIONS.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.SCORING.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.SCORING.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.QUESTIONS.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.QUESTIONS.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.QUESTIONS_ORDER.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.IMAGES.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.IMAGES.value} />,
                            },
                            {
                                path: EDIT_TEST_SECTIONS.SUBMIT.value,
                                element: <TestEditForm section={EDIT_TEST_SECTIONS.SUBMIT.value} />,
                            },
                        ],
                    },
                ],

            },
            {
                path: 'editcards/:testId/',
                async lazy() {
                    const { FlashcardDeckEditLayout } = await import('./components/FlashcardDeckEditLayout');
                    return {
                        element:
                            <Suspense fallback={<PlacehoderLoader size={'medium'} />}>
                                <PrivateWrapper edit={true}>
                                    <FlashcardDeckEditLayout />
                                </PrivateWrapper>
                            </Suspense>
                    };
                },
                children: [
                    {
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.FLASHCARDS.value} />,
                            },

                            {
                                path: EDIT_FLASHCARD_DECK_SECTIONS.OVERVIEW.value,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.OVERVIEW.value} />,
                            },
                            {
                                path: EDIT_FLASHCARD_DECK_SECTIONS.CARD.value,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.CARD.value} />,
                            },
                            {
                                path: EDIT_FLASHCARD_DECK_SECTIONS.FLASHCARDS.value,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.FLASHCARDS.value} />,
                            },
                            {
                                path: EDIT_FLASHCARD_DECK_SECTIONS.FLASHCARDS_ORDER.value,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.FLASHCARDS_ORDER.value} />,
                            },
                            {
                                path: EDIT_FLASHCARD_DECK_SECTIONS.IMAGES.value,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.IMAGES.value} />,
                            },
                            {
                                path: EDIT_FLASHCARD_DECK_SECTIONS.SUBMIT.value,
                                element: <TestEditForm section={EDIT_FLASHCARD_DECK_SECTIONS.SUBMIT.value} />,
                            },
                        ],
                    },
                ],

            },
            {
                path: '/sessions/:sessionId/',
                element: <Session />,
                children: [],
            },
            {
                path: '/sessions/:sessionId/results',
                element: <SessionResult />,
                loader: sessionResultLoader,
            },
        ],
    },
]);

const initialOptions = {
    'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,    // live env
    // 'client-id': 'Af2KieE8ClpdKFReDbMdd8td_j8lUoP1msMZQUakFcDSK1P6w67VricijPDYFhWG5eA02bj7Ka6MHT7m',     // sandbox env
    'enable-funding': 'paylater,venmo',
    'data-sdk-integration-source': 'integrationbuilder_ac',
};

// console.log(awsconfig)
Amplify.configure(awsconfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Authenticator.Provider>
            <PayPalScriptProvider options={initialOptions}>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </PayPalScriptProvider>
        </Authenticator.Provider>
    </React.StrictMode>
);

// serviceWorkerRegistration.register();
