import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sessions: {},
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setThreadId: (state, action) => {
      const { sessionId, threadId } = action.payload;
      state.sessions[sessionId] = {
        ...state.sessions[sessionId],
        threadId,
      };
    },
    addMessage: (state, action) => {
      const { sessionId, message, sender } = action.payload;
      state.sessions[sessionId] = {
        ...state.sessions[sessionId],
        messages: [
          ...(state.sessions[sessionId]?.messages || []),
          { text: message, sender },
        ],
      };
    },
  },
});

export const { setThreadId, addMessage } = chatSlice.actions;
export default chatSlice.reducer;
