import React, { useState, useEffect } from 'react';
import { useCompleteSessionMutation, useGetSessionQuery } from "../store/store";
import { useLoaderData, useNavigate } from "react-router-dom";
import Instructions from './Instructions';
import { Icon, Button, Divider, Container, Segment, Header, Table, Image, Label, Breadcrumb } from 'semantic-ui-react';
import Question from './Question';
import NormalDistChart from './NormalDistChart';
import ResultsSummaryTable_IQ from './ResultsSummaryTable_IQ';
import ResultsBreakdownTable from './ResultsBreakdownTable';
import ResultsSummaryTable_AWS from "./ResultsSummaryTable_AWS";
import ResultsBreakdownTableSimple from './ResultsBreakdownTableSimple';


export async function loader({ params }) {
    // console.log(params)
    return params;
}


const SessionResult = () => {
    // TODO Fix navigation or delete
    // const { sessionId, questionNumber: questionNumberParams } = useLoaderData();
    // console.log('questionNumberParams', questionNumberParams)

    const { sessionId } = useLoaderData();
    const {
        data: session,
        refetch: sessionRefetch,
        isSuccess: sessionIsSuccess,
        isError: sessionIsError
    } = useGetSessionQuery(sessionId);

    const [questionCount, setQuestionCount] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(-1);

    console.log('Session', session)

    const navigate = useNavigate();

    useEffect(() => {
        setQuestionCount(session?.questions?.length);
        // TODO Fix navigation or delete
        // const index = Number(questionNumberParams) && (Number(questionNumberParams) - 1) < questionCount ? Number(questionNumberParams - 1) : -1;
        // setQuestionIndex(index);
    }, [session]);

    const handleNextClickButton = () => {
        setQuestionIndex((prev) => Math.min(prev + 1, questionCount - 1));
    }

    const handlePreviousClickButton = () => {
        setQuestionIndex((prev) => Math.max(prev - 1, -1));
    }

    const handleCancelClickButton = () => {
        setQuestionIndex((prev) => prev + 1);
    }

    const handleCompleteClickButton = () => {
    }

    // const Navigation = () => {
    //
    //     if (questionIndex < 0) {
    //         return (
    //             <Segment basic floated='right'>
    //                 <Button icon labelPosition='left' onClick={handleCancelClickButton}>
    //                     <Icon name='cancel' />
    //                     Cancel
    //                 </Button>
    //                 <Button icon labelPosition='right' color='black' onClick={handleNextClickButton}>
    //                     Start
    //                     <Icon name='right arrow' />
    //                 </Button>
    //             </Segment>
    //         )
    //     } else if (questionIndex < questionCount - 1) {
    //         return (
    //             <Segment basic floated='right'>
    //                 <Button icon labelPosition='left' onClick={handlePreviousClickButton}>
    //                     <Icon name='left arrow' />
    //                     Previous
    //                 </Button>
    //                 <Button icon labelPosition='right' color='black' onClick={handleNextClickButton}>
    //                     Next
    //                     <Icon name='right arrow' />
    //                 </Button>
    //             </Segment>
    //         )
    //     } else {
    //         return (
    //             <Segment basic floated='right' >
    //                 <Button icon labelPosition='left' onClick={handlePreviousClickButton}>
    //                     <Icon name='left arrow' />
    //                     Previous
    //                 </Button>
    //                 <Button icon labelPosition='right' color='black' onClick={handleCompleteClickButton}>
    //                     Complete
    //                     <Icon name='flag checkered' />
    //                 </Button>
    //             </Segment >
    //         )
    //     }
    // }

    const SummaryTable = () => {
        if (session?.config?.category) {
            const { category } = session.config;
            switch (category) {
                case 'aws':
                    return session?.results?.summary
                        ? <ResultsSummaryTable_AWS
                            session={session}
                            icon={'file alternate outline'}
                            title={'Summary'} />
                        : null;
                case 'iq_point_score':
                    return session?.results?.summary
                        ? <ResultsSummaryTable_IQ session={session} />
                        : null;
                default:
                    break;
            }
        }
    }


    const BreakdownTableByDifficulty = () => {
        if (session?.config?.category && session?.results?.difficulty) {
            const { category } = session.config;
            switch (category) {
                case 'aws':
                    return session?.results?.summary
                        ? <ResultsBreakdownTableSimple
                            title={'Results Breakdown by Difficulty'}
                            icon={'magnify'}
                            rows={[...Object.entries(session?.results?.difficulty)
                                .sort(([k1, v1], [k2, v2]) => v2?.['score_available'] - v1?.['score_available'])
                            ]}
                            footer={Object.entries(session?.results?.summary).filter(([key, value]) => key === 'test')}
                        />

                        : null;
                case 'iq_point_score':
                    return session?.results?.summary
                        ? <ResultsBreakdownTable
                            title={'Results Breakdown by Difficulty'}
                            icon={'magnify'}
                            rows={[...Object.entries(session?.results?.difficulty)
                                .sort(([k1, v1], [k2, v2]) => v2?.['score_available'] - v1?.['score_available'])
                            ]}
                            footer={Object.entries(session?.results?.summary).filter(([key, value]) => key === 'test')}
                        />
                        : null;
                default:
                    break;
            }
        }
    }

    const BreakdownTableByCategory = () => {
        if (session?.config?.category && session?.results?.labels) {
            const { category } = session.config;
            switch (category) {
                case 'aws':
                    return session?.results?.summary
                        ? <ResultsBreakdownTableSimple
                            title={'Results Breakdown by Category'}
                            icon={'magnify'}
                            rows={[...Object.entries(session?.results?.labels)
                                .sort(([k1, v1], [k2, v2]) => v2?.['score_available'] - v1?.['score_available'])
                            ]}
                            footer={Object.entries(session?.results?.summary).filter(([key, value]) => key === 'test')}
                        />

                        : null;
                case 'iq_point_score':
                    return session?.results?.summary
                        ? <ResultsBreakdownTable
                            title={'Results Breakdown by Category'}
                            icon={'magnify'}
                            rows={[...Object.entries(session?.results?.labels)
                                .sort(([k1, v1], [k2, v2]) => v2?.['score_available'] - v1?.['score_available'])
                            ]}
                            footer={Object.entries(session?.results?.summary).filter(([key, value]) => key === 'test')}
                        />
                        : null;
                default:
                    break;
            }
        }
    }

    const sections = [
        { key: 'Home', content: 'Home', link: true, onClick: () => navigate('/') },
        { key: 'Test', content: 'Test', link: true, onClick: () => navigate(`/tests/${session?.testId}/content`) },
        { key: 'Session', content: 'Session', link: true, onClick: () => navigate(`/sessions/${session?.id}`) },
        { key: 'Result', content: 'Result', active: true },
    ]


    return (
        <>
            {sessionIsSuccess
                && (
                    <Container style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                        <Breadcrumb icon='right angle' sections={sections} />
                        <Header as='h1' textAlign='left'>{session?.product_card?.header}</Header>


                        <Container fluid>

                            <SummaryTable />

                            <BreakdownTableByDifficulty />
                            <BreakdownTableByCategory />

                            {/* {session?.results?.difficulty
                                && <ResultsBreakdownTable
                                    title={'Results Breakdown by Difficulty'}
                                    icon={'magnify'}
                                    rows={[...Object.entries(session?.results?.difficulty)
                                        .sort(([k1, v1], [k2, v2]) => v2?.['score_available'] - v1?.['score_available'])
                                    ]}
                                    footer={Object.entries(session?.results?.summary).filter(([key, value]) => key == 'test')}
                                />
                            }


                            {session?.results?.labels
                                && <ResultsBreakdownTable
                                    title={'Results Breakdown by Category'}
                                    icon={'magnify'}
                                    rows={[...Object.entries(session?.results?.labels)
                                        .sort(([k1, v1], [k2, v2]) => v2?.['score_available'] - v1?.['score_available'])
                                    ]}
                                    footer={Object.entries(session?.results?.summary).filter(([key, value]) => key == 'test')}
                                />
                            } */}

                            {session?.results?.summary?.results
                                && (session?.config?.category === 'iq_point_score')
                                && < NormalDistChart {...session?.results?.summary?.results} />}

                        </Container>

                    </Container>
                )
            }
        </>


    )
}

export default SessionResult
