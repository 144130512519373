import React, {useEffect, useState} from 'react';
import {useGetTestQuery} from "../store/store";
import {useParams} from "react-router-dom";
import TestProductPageHeaderCard from "./TestProductPageHeaderCard";
import {Container, Divider} from "semantic-ui-react";
import TestProductPageIncluded from "./TestProductPageIncluded";
import TestProductPageDescription from "./TestProductPageDescription";
import PlacehoderLoader from "./PlaceholderLoader";
import ReactGA from "react-ga4";
import {useAuthenticator} from "@aws-amplify/ui-react";
import PurchaseModal from "./PurchaseModal";

const TestProductPage = (props) => {

    const {testId} = useParams();

    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    const [isModalMounted, setIsModalMounted] = useState(false);
    const [modalContent, setModalContent] = useState({});

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview", page: window.location.pathname + window.location.search,
            title: "ProductPage"
        });

    }, []);

    const {
        data: test,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
        isSuccess: isTestSuccess,
        refetch: testRefetch
    }
        = useGetTestQuery(testId);

    // console.log('test', test)

    // TODO: Add author page, and Review page
    return (

        isTestSuccess
            ? (
                <Container fluid className={'pb-48'}>
                    {/* Purchase Model to execute transaction, managed by isModalMounted */}
                    {isModalMounted && <PurchaseModal
                        open={isModalMounted}
                        mountModal={setIsModalMounted}
                        test={modalContent}
                        setContent={setModalContent}
                    />}
                    <TestProductPageHeaderCard
                        test={test}
                        modal={{
                            mountModal: setIsModalMounted,
                            setContent: setModalContent
                        }}
                        isPurchaseDisabled={authStatus !== 'authenticated'}
                    />
                    <TestProductPageIncluded test={test}/>
                    <TestProductPageDescription test={test}/>
                </Container>
            )
            : (
                <PlacehoderLoader size={'medium'}/>
            )
    );
}


export default TestProductPage;
