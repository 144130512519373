import React, { useState, useEffect, createRef, useRef } from 'react';
import { useEditSessionMutation, useGetSessionQuery } from "../store/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, Button, Divider, Container, Segment, Header, Sticky, Breadcrumb } from 'semantic-ui-react';
import Question from './Question';
import Swal from 'sweetalert2';
import InstructionsViewSession from './InstructionsViewSession';
import QuestionViewer from './QuestionViewer';
import Timer from './Timer';
import SessionNavBar from './SessionNavBar';
import SessionBreadcrumbsNav from './SessionBradcrumbsNav';
import SessionProgressTracker from './SessionProgressTracker';
import ChatBot from './ChatBot';
import { useAuthenticator } from '@aws-amplify/ui-react';
import aiIcon from '../images/google-gemini-icon.webp';


const SessionViewer = ({ session, editSession, timer }) => {

    const [questionCount, setQuestionCount] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(-1);
    const [showProgress, setShowProgress] = useState(false);

    const { authStatus } = useAuthenticator(context => [context.authStatus]);


    const sessionId = session?.id;
    const navigate = useNavigate();

    useEffect(() => {

        if (session) {
            setQuestionCount(session?.questions?.length);
        }

        // check of Session still in Started state and setQuestionIndex to ResumeIndex
        // if (session?.['session_status'] === 'Started' && session?.['resume_question_index']) {
        //     setQuestionIndex(Number(session?.['resume_question_index']))
        // }

    }, [session]);


    const handleNextClickButton = () => {
        setQuestionIndex((prev) => Math.min(prev + 1, questionCount - 1));
    }

    const handlePreviousClickButton = () => {
        setQuestionIndex((prev) => Math.max(prev - 1, -1));
    }

    const handleStartClickButton = () => {
        handleNextClickButton();
        editSession({
            action: 'Started',
            sessionId: sessionId,
        })
    }

    const handleCancelClickButton = () => {
        editSession({
            action: 'Cancelled',
            sessionId: sessionId,
        }).unwrap()
            .then(fulfilled => {


                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Test Cancelled`,
                    showConfirmButton: false,
                    timer: 3000
                })

                // navigate(`/sessions/${sessionId}/results`);

            })
            .catch(rejected => {
                console.error(rejected)


                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Error Cancelling Test, Please Try Again`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    const handleCompleteClickButton = () => {
        // setQuestionIndex((prev) => prev + 1);

        if (session?.['session_status'] !== 'Started') {
            return;
        }

        editSession({
            action: 'Completed',
            sessionId: sessionId,
        })
            .unwrap()
            .then(fulfilled => {


                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    title: `Test Completed`,
                    showConfirmButton: false,
                    timer: 3000
                })

                navigate(`/sessions/${sessionId}/results`);

            })
            .catch(rejected => {
                console.error(rejected)


                Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'error',
                    title: `Error Completing Test, Please Try Again`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })


    }

    const handleShowProgressClickButton = () => {
        setShowProgress((prev) => !prev);
    }

    // For sticky navbar at the top
    const contextRef = createRef();

    return (
        <div ref={contextRef}>

            <Container>

                <Sticky context={contextRef}>
                    <SessionNavBar
                        session={session}
                        questionIndex={questionIndex}
                        questionCount={questionCount}
                        handlePrevious={handlePreviousClickButton}
                        handleNext={handleNextClickButton}
                        handleStart={handleStartClickButton}
                        handleCancel={handleCancelClickButton}
                        handleComplete={handleCompleteClickButton}
                        handleShowProgress={handleShowProgressClickButton}
                        timer={timer}
                    />
                    <SessionBreadcrumbsNav session={session} />
                </Sticky>
            </Container>

            {
                showProgress &&
                <Container className={'pt-3'}>
                    <SessionProgressTracker session={session} questionIndex={questionIndex} />
                </Container>
            }

            <Container>
                <Container
                    className={'py-6 px-6'}
                >

                    <Header as='h1' textAlign='left'>{session?.config?.label}</Header>

                    {questionIndex < 0
                        ? <InstructionsViewSession session={session} />
                        : (<>
                            <Divider horizontal>
                                <Header as='h4'>
                                    <Segment.Inline>
                                        <Icon name='check circle' color='black' />
                                        Question {questionIndex + 1} / {questionCount} ({Math.max(100, ((questionIndex + 1) / (questionCount) * 100).toFixed(0))}%)
                                    </Segment.Inline>
                                </Header>
                            </Divider>
                            <Question question={session.questions[questionIndex]} session={session}
                                questionIndex={questionIndex}
                            />
                        </>)
                    }

                    {
                        authStatus &&
                        questionIndex >= 0 &&
                        <Divider horizontal>
                            <Header as='h4'>
                                <Segment.Inline className='flex flex-row pr-6 py-2'>
                                    <img src={aiIcon} alt="AI Icon" style={{ width: 24, height: 24, marginRight: 6 }} />
                                    Ask AI Tutor
                                </Segment.Inline>
                            </Header>
                        </Divider>
                    }

                    {
                        authStatus &&
                        questionIndex >= 0 &&
                        < ChatBot
                            sessionId={sessionId}
                            category={session?.config?.category}
                            question={session.questions[questionIndex]}
                        />
                    }

                </Container>
            </Container>
        </div>
    )
}


export default SessionViewer