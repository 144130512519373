import React from 'react'
import {Container, Placeholder, Header, Icon, Button} from 'semantic-ui-react'
import PlaceholderComponent from './PlaceholderComponent'
import {useGetFullTestQuery} from '../store/testsSlice'
import DOMPurify from "dompurify";
import VideoEmbed from './VideoEmbed'

// TODO implement
const TestProductPageDescription = ({test}) => {


    const sanitizedData = (htmlData) => ({
        __html: DOMPurify.sanitize(htmlData)
    })

    return (

        <Container className={'my-10'} text>

            {test?.product_summary?.overview &&

                <Container text style={{marginTop: '2rem', marginBottom: '2rem'}}>
                    <div className={'m-2'}>
                        <h1
                            className={'text-4xl font-extrabold my-3.5'}
                        >
                            Description
                        </h1>
                    </div>
                    <Container
                        style={{marginTop: '2rem', marginBottom: '2rem'}}
                        dangerouslySetInnerHTML={sanitizedData(test?.product_summary?.overview)}
                    />
                </Container>
            }

        </Container>
    )
}

export default TestProductPageDescription
