import {Controller} from 'react-hook-form';
import {Dropdown, Form, Input} from 'semantic-ui-react';


const HookFormControlledDatalist = ({
                                        options,
                                        label,
                                        name,
                                        control,
                                        placeholder = '',
                                        disabled = false,
                                        type = 'text',
                                        required = false,
                                    }) => {
    return (

        <Form.Field>
            <label htmlFor={name}>{label}</label>

            <Controller
                shouldUnregister={true}
                control={control}
                name={name}
                rules={{
                    required: {
                        value: required,
                        message: `Missing ${name}`
                    }
                }}
                render={({
                             field: {onChange, onBlur, value, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                         }) => (
                    <>
                        <Dropdown
                            multiple
                            value={value}
                            options={options}
                            selection
                            onChange={(e, data) => {
                                e.preventDefault();
                                // console.log('data', data.value)
                                onChange(data.value);
                            }}
                        />
                        {/*<Form.Input*/}
                        {/*    id={name}*/}
                        {/*    value={value}*/}
                        {/*    onChange={(e, {value}) => onChange(type === 'number' ? Number(value) : value)} // send value to hook form*/}
                        {/*    onBlur={onBlur} // notify when input is touched*/}
                        {/*    inputRef={ref} // wire up the input ref*/}
                        {/*    placeholder={placeholder || label}*/}
                        {/*    defaultValue={''}*/}
                        {/*    type={type}*/}
                        {/*    error={error ? {*/}
                        {/*        content: error?.message,*/}
                        {/*        pointing: 'above',*/}
                        {/*    } : false}*/}
                        {/*    disabled={disabled}*/}
                        {/*    list={'data'}*/}
                        {/*/>*/}
                        {/*<datalist id={'data'}>*/}
                        {/*    {options.map((option, index) => {*/}
                        {/*        return (*/}
                        {/*            <option key={index} value={option}/>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</datalist>*/}
                    </>
                )}
            />
        </Form.Field>
    );
};

export default HookFormControlledDatalist;